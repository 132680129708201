import { useState, useEffect } from 'react'

type MobileDetectProps = {
  screenSize?: number
}

export const useMobileDetect = ({ screenSize = 768 }: MobileDetectProps = {}): boolean => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < screenSize)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < screenSize)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [screenSize])

  return isMobile
}
