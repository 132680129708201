import { useState, useEffect } from 'react';
import SuccessCheck from '../../../../assets/images/success-check.svg';
import Modal from '../../atoms/modals/Modal';

export type SuccessModalProps = {
  openSuccessModal: boolean;
  setOpenSuccessModal: React.Dispatch<React.SetStateAction<boolean>>;
  solutionName: string;
  isClaim?: boolean;
  redirectURL: string;
  solutionClaimed?: boolean;
  requestAQuote?: boolean;
};

export function SuccessModal({
  openSuccessModal,
  setOpenSuccessModal,
  solutionName,
  isClaim = true,
  redirectURL,
  solutionClaimed = true,
  requestAQuote = false,
}: SuccessModalProps) {
  const [seconds, setSeconds] = useState(3);

  useEffect(() => {
    if (openSuccessModal) {
      const interval = setInterval(() => {
        setSeconds((seconds) => Math.max(seconds - 1, 0));
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [openSuccessModal]);

  useEffect(() => {
    if (seconds === 0) {
      setOpenSuccessModal(false);
      window.open(redirectURL, '_blank');
    }
  }, [seconds, redirectURL]);

  return (
    <Modal
      isOpen={openSuccessModal}
      onClose={() => setOpenSuccessModal(false)}
      closeStyle="top-10 right-10"
      className="mx-4 w-full rounded-xl bg-white p-10 md:mt-0 md:w-[600px]"
    >
      <div className="flex flex-col items-center">
        <img src={SuccessCheck} alt="success" className="pt-10 pb-4" />
        <h1 className="mb-4 py-4 text-2xl font-bold">Success!</h1>
        <p className="mb-8 text-center text-sm">
          {isClaim && !requestAQuote
            ? `Your claim for ${solutionName} has been submitted for verification`
            : `We’ve sent your information on to ${solutionName} and they’ll be in touch shortly`}
          .
        </p>
        <p className="text-medium-gray ">
          Directing you to {solutionName} in {seconds}...
        </p>
      </div>
    </Modal>
  );
}
