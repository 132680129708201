import React, { FunctionComponent, ReactNode } from 'react'

import clsx from 'clsx'

const PillWithIcon = ({
  className = '',
  Icon,
  TextOrNode,
  link,
}: {
  className: string
  Icon: FunctionComponent
  TextOrNode?: ReactNode | string
  link?: React.AnchorHTMLAttributes<HTMLAnchorElement>['href']
}) => {
  return (
    <div
      className={clsx(
        'flex max-w-max items-center gap-2 rounded-full bg-[#A3AFF5] p-2',
        className
      )}
    >
      <a href={link || '#'} target='_blank' rel='noopener noreferrer'>
        <Icon />
      </a>
      {TextOrNode && (
        <p className='truncate text-xs font-[700] leading-5 text-dark'>
          {TextOrNode}
        </p>
      )}
    </div>
  )
}

export default PillWithIcon
