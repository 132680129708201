import Button from '../../atoms/buttons/Button'
import useCurrentUserId from '@/hooks/useCurrentUserId'
import { event } from '@fullstory/browser'

export function SolutionCTA() {
  const currentUserId = useCurrentUserId()
  const trackFullStory = () => {
    event('Add Your Solution Button', {
      buttonLabel: 'Add Your Solution',
      userId: currentUserId,
    })
  }

  return (
    <div className='px-4 md:px-10 lg:px-20'>
      <div className='top-0 z-10 mx-auto mt-10 mb-9 flex w-full max-w-[1120px] flex-col justify-center rounded-[20px] bg-teal-tint-2 p-5 text-center font-medium md:flex-row md:items-center md:justify-between md:py-3 md:px-6'>
        <p className='mb-2 text-sm text-dark-gray md:mb-0'>
          Add your company to our marketplace to reach our community of talent
          acquisition professionals, and start generating leads.
        </p>
        <a
          href='https://forms.gle/kqQ1kFbi6NoJiWZy8'
          target='_blank'
          rel='noreferrer'
        >
          <Button
            variant='primary'
            size='md'
            onClick={() => trackFullStory()}
            className='mt-4 w-full md:mt-0 md:w-auto'
            trackBtnClick={true}
          >
            Add Your Solution
          </Button>
        </a>
      </div>
    </div>
  )
}
