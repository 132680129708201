import { ErrorMessage } from '@hookform/error-message'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import Button from '@/components/atoms/buttons/Button'
import { useToken } from '@/components/atoms/forms/Form'
import Modal from '@/components/atoms/modals/Modal'
import SuccessMessage from '@/components/atoms/Toast/SuccessMessage'
import { postRequest } from '@/utils/axiosRequests'
import { defaultToastOptions } from '@/utils/commonToast'
import { emailRegex } from '@/utils/constants'

function CreateReferralModal({ onClose }: { onClose: () => void }) {
  const createReferralEndpoint = '/users/send_create_referral'

  const { register, handleSubmit, formState: { errors, isValid }, reset } = useForm()
  const token = useToken()
  
  const onSubmit = async (data: any) => {
    try {
      const payload = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phoneNo: data.phone,
        message: data.message
      }
      
      const response = await postRequest(createReferralEndpoint, payload, token)
      if (response.status === 200) {
        toast(<SuccessMessage message = 'Your referral has been submitted.' />, defaultToastOptions('teal-tint-2'))
        reset()
      }
    } catch (error) {
      toast(
      <div>
        <span className='font-bold'>Error! </span> {'Something Went Wrong! Try Again.'}
      </div>,
      defaultToastOptions('red-200')
      )
    }
    onClose()
  }

  const textFieldClass = 'h-[50px] w-full rounded-md border border-gray-300 p-2'

  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      className='mx-4 max-h-[600px] w-full overflow-auto rounded-xl bg-white p-10 sm:max-h-[85%] md:w-[564px]'
    >
      <div className='flex justify-between'>
        <p className='mb-2 text-5xl font-medium text-outer-space'>Refer a Vendor, Earn Your Spotlight</p>
      </div>
      <p className='mb-6 text-sm text-outer-space'>
        Know a vendor who wants in on more leads? Pass along their details, and we’ll handle the rest. When they sign up, you’ll earn a featured spotlight on our social channels—hello, extra visibility 🤩.
      </p>

      <form onSubmit={handleSubmit(onSubmit)} className='mb-6'>
        <div>
          <p className='pb-3 text-lg font-medium text-outer-space'>Referral details</p>
          <div className='grid grid-cols-2 gap-4'>
            <div>
              <p className='absolute -mt-1.5 ml-3 flex w-[65px] justify-center bg-white text-xs text-blue-smoke'>First Name</p>
              <input
                type='text'
                {...register('firstName', { required: 'First Name is required' })}
                className={textFieldClass}
              />
              <ErrorMessage
                errors={errors}
                name='firstName'
                render={({ message }) => <span className='text-sm font-medium text-red-500'>{message}</span>}
              />
            </div>
            <div>
              <p className='absolute -mt-1.5 ml-3 flex w-[65px] justify-center bg-white text-xs text-blue-smoke'>Last Name</p>
              <input
                type='text'
                {...register('lastName', { required: 'Last Name is required' })}
                className={textFieldClass}
              />
              <ErrorMessage
                errors={errors}
                name='lastName'
                render={({ message }) => <span className='text-sm text-red-500'>{message}</span>}
              />
            </div>
          </div>

          <div className='mt-4'>
            <p className='absolute -mt-1.5 ml-3 flex w-[80px] justify-center bg-white text-xs text-blue-smoke'>Email Address</p>
            <input
              type='email'
              {...register('email', {
                required: 'Email is required',
                pattern: {
                  value: emailRegex,
                  message: 'Email is not valid',
                },
              })}
              className={textFieldClass}
            />
            <ErrorMessage
              errors={errors}
              name='email'
              render={({ message }) => <span className='text-sm text-red-500'>{message}</span>}
            />
          </div>

          <div className='mt-4'>
            <input
              type='text'
              {...register('phone')}
              placeholder='Phone no. (optional)'
              className={`${textFieldClass} placeholder:text-blue-smoke`}
            />
          </div>
        </div>

        <div className='mb-6 mt-5'>
          <p className='mb-2 text-[17px] font-medium text-gray-800'>Your message (optional)</p>
          <textarea
            {...register('message')}
            placeholder='Type here'
            className='h-24 w-full rounded-md border border-gray-300 p-2 placeholder:text-outer-space-2'
          />
        </div>

        <div className='flex justify-start'>
          <Button
            variant={isValid ? 'gulfBlue' : 'gray'}
            className={'relative flex h-[44px] w-[246px] items-center justify-center !rounded-[12px] text-md'}
            type='submit'
          >
            Submit Referral
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default CreateReferralModal
