import { ToastOptions } from 'react-toastify'
export const defaultToastOptions = (bgColor: string): ToastOptions => {
    return {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      className: `bg-${bgColor} text-md text-black p-4 md:p-6 md:rounded-xl md:w-[400px] md:right-[80px]`,
      bodyClassName: 'p-0 min-w-[324px]',
    }
  }
