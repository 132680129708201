import { useState } from 'react'
import { MdOutlineVerified as VerifiedIcon } from 'react-icons/md'

export const ClaimStatus = ({ isClaimed, handleSubmitClaim }) => {
  const [toggleClaimInfo, setToggleClaimInfo] = useState(false)

  return (
    <div className={'flex gap-[2px]'}>
      <button
        className='flex relative text-left'
        onClick={() => setToggleClaimInfo(!toggleClaimInfo)}
      >
        <div className='flex flex-row items-center gap-1'>
          {isClaimed ? (
            <>
              <VerifiedIcon size={16} />
              <p
                className={`text-[9px] sm:text-sm text-dark-gray ${!isClaimed && 'cursor-pointer'}`}
              >
                Claimed
              </p>
            </>
          ) : (
            <p
            className={`text-[9px] sm:text-sm text-dark-gray ${!isClaimed && 'cursor-pointer'}`}
          >
            Unclaimed
          </p>
          )}
        </div>
      </button>
    </div>
  )
}
