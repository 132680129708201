export const solutionOptions = {
  solutionTypes: [
    {
      id: 1,
      name: 'Solution Suite',
      value: 'solution_suite',
      unavailable: false,
    },
    {
      id: 2,
      name: 'Point Solution',
      value: 'point_solution',
      unavailable: false,
    },
  ],
  industries: [
    {
      id: 1,
      name: 'Accommodation',
      value: 'Accommodation',
      unavailable: false,
      checked: false,
    },
    {
      id: 2,
      name: 'Accommodation and Food Services',
      value: 'Accommodation and Food Services',
      unavailable: false,
      checked: false,
    },
    {
      id: 3,
      name: 'Administrative and Support Services',
      value: 'Administrative and Support Services',
      unavailable: false,
      checked: false,
    },
    {
      id: 4,
      name: 'Administrative and Support and Waste Management and Remediation Services',
      value:
        'Administrative and Support and Waste Management and Remediation Services',
      unavailable: false,
      checked: false,
    },
    {
      id: 5,
      name: 'Agriculture, Forestry, Fishing and Hunting',
      value: 'Agriculture, Forestry, Fishing and Hunting',
      unavailable: false,
      checked: false,
    },
    {
      id: 6,
      name: 'Air Transportation',
      value: 'Air Transportation',
      unavailable: false,
      checked: false,
    },
    {
      id: 7,
      name: 'Ambulatory Health Care Services',
      value: 'Ambulatory Health Care Services',
      unavailable: false,
      checked: false,
    },
    {
      id: 8,
      name: 'Amusement, Gambling, and Recreation Industries',
      value: 'Amusement, Gambling, and Recreation Industries',
      unavailable: false,
      checked: false,
    },
    {
      id: 9,
      name: 'Animal Production',
      value: 'Animal Production',
      unavailable: false,
      checked: false,
    },
    {
      id: 10,
      name: 'Apparel Manufacturing',
      value: 'Apparel Manufacturing',
      unavailable: false,
      checked: false,
    },
    {
      id: 11,
      name: 'Arts, Entertainment, and Recreation',
      value: 'Arts, Entertainment, and Recreation',
      unavailable: false,
      checked: false,
    },
    {
      id: 12,
      name: 'Beverage and Tobacco Product Manufacturing',
      value: 'Beverage and Tobacco Product Manufacturing',
      unavailable: false,
      checked: false,
    },
    {
      id: 13,
      name: 'Broadcasting (except Internet)',
      value: 'Broadcasting (except Internet)',
      unavailable: false,
      checked: false,
    },
    {
      id: 14,
      name: 'Building Material and Garden Equipment and Supplies Dealers',
      value: 'Building Material and Garden Equipment and Supplies Dealers',
      unavailable: false,
      checked: false,
    },
    {
      id: 15,
      name: 'Chemical Manufacturing',
      value: 'Chemical Manufacturing',
      unavailable: false,
      checked: false,
    },
    {
      id: 16,
      name: 'Clothing and Clothing Accessories Stores',
      value: 'Clothing and Clothing Accessories Stores',
      unavailable: false,
      checked: false,
    },
    {
      id: 17,
      name: 'Computer and Electronic Product Manufacturing',
      value: 'Computer and Electronic Product Manufacturing',
      unavailable: false,
      checked: false,
    },
    {
      id: 18,
      name: 'Construction',
      value: 'Construction',
      unavailable: false,
      checked: false,
    },
    {
      id: 19,
      name: 'Construction of Buildings',
      value: 'Construction of Buildings',
      unavailable: false,
      checked: false,
    },
    {
      id: 20,
      name: 'Couriers and Messengers',
      value: 'Couriers and Messengers',
      unavailable: false,
      checked: false,
    },
    {
      id: 21,
      name: 'Credit Intermediation and Related Activities',
      value: 'Credit Intermediation and Related Activities',
      unavailable: false,
      checked: false,
    },
    {
      id: 22,
      name: 'Crop Production',
      value: 'Crop Production',
      unavailable: false,
      checked: false,
    },
    {
      id: 23,
      name: 'Data Processing, Hosting, and Related Services',
      value: 'Data Processing, Hosting, and Related Services',
      unavailable: false,
      checked: false,
    },
    {
      id: 24,
      name: 'Education and Health Services',
      value: 'Education and Health Services',
      unavailable: false,
      checked: false,
    },
    {
      id: 25,
      name: 'Educational Services',
      value: 'Educational Services',
      unavailable: false,
      checked: false,
    },
    {
      id: 26,
      name: 'Electrical Equipment, Appliance, and Component Manufacturing',
      value: 'Electrical Equipment, Appliance, and Component Manufacturing',
      unavailable: false,
      checked: false,
    },
    {
      id: 27,
      name: 'Electronics and Appliance Stores',
      value: 'Electronics and Appliance Stores',
      unavailable: false,
      checked: false,
    },
    {
      id: 28,
      name: 'Fabricated Metal Product Manufacturing',
      value: 'Fabricated Metal Product Manufacturing',
      unavailable: false,
      checked: false,
    },
    {
      id: 29,
      name: 'Finance and Insurance',
      value: 'Finance and Insurance',
      unavailable: false,
      checked: false,
    },
    {
      id: 30,
      name: 'Financial Activities',
      value: 'Financial Activities',
      unavailable: false,
      checked: false,
    },
    {
      id: 31,
      name: 'Fishing, Hunting and Trapping',
      value: 'Fishing, Hunting and Trapping',
      unavailable: false,
      checked: false,
    },
    {
      id: 32,
      name: 'Food Manufacturing',
      value: 'Food Manufacturing',
      unavailable: false,
      checked: false,
    },
    {
      id: 33,
      name: 'Food Services and Drinking Places',
      value: 'Food Services and Drinking Places',
      unavailable: false,
      checked: false,
    },
    {
      id: 34,
      name: 'Food and Beverage Stores',
      value: 'Food and Beverage Stores',
      unavailable: false,
      checked: false,
    },
    {
      id: 35,
      name: 'Forestry and Logging',
      value: 'Forestry and Logging',
      unavailable: false,
      checked: false,
    },
    {
      id: 36,
      name: 'Funds, Trusts, and Other Financial Vehicles',
      value: 'Funds, Trusts, and Other Financial Vehicles',
      unavailable: false,
      checked: false,
    },
    {
      id: 37,
      name: 'Furniture and Home Furnishings Stores',
      value: 'Furniture and Home Furnishings Stores',
      unavailable: false,
      checked: false,
    },
    {
      id: 38,
      name: 'Furniture and Related Product Manufacturing',
      value: 'Furniture and Related Product Manufacturing',
      unavailable: false,
      checked: false,
    },
    {
      id: 39,
      name: 'Gasoline Stations',
      value: 'Gasoline Stations',
      unavailable: false,
      checked: false,
    },
    {
      id: 40,
      name: 'General Merchandise Stores',
      value: 'General Merchandise Stores',
      unavailable: false,
      checked: false,
    },
    {
      id: 41,
      name: 'Goods-Producing Industries',
      value: 'Goods-Producing Industries',
      unavailable: false,
      checked: false,
    },
    {
      id: 42,
      name: 'Health Care and Social Assistance',
      value: 'Health Care and Social Assistance',
      unavailable: false,
      checked: false,
    },
    {
      id: 43,
      name: 'Health and Personal Care Stores',
      value: 'Health and Personal Care Stores',
      unavailable: false,
      checked: false,
    },
    {
      id: 44,
      name: 'Heavy and Civil Engineering Construction',
      value: 'Heavy and Civil Engineering Construction',
      unavailable: false,
      checked: false,
    },
    {
      id: 45,
      name: 'Hospitals',
      value: 'Hospitals',
      unavailable: false,
      checked: false,
    },
    {
      id: 46,
      name: 'Information',
      value: 'Information',
      unavailable: false,
      checked: false,
    },
    {
      id: 47,
      name: 'Insurance Carriers and Related Activities',
      value: 'Insurance Carriers and Related Activities',
      unavailable: false,
      checked: false,
    },
    {
      id: 48,
      name: 'Internet Publishing and Broadcasting',
      value: 'Internet Publishing and Broadcasting',
      unavailable: false,
      checked: false,
    },
    {
      id: 49,
      name: 'Leather and Allied Product Manufacturing',
      value: 'Leather and Allied Product Manufacturing',
      unavailable: false,
      checked: false,
    },
    {
      id: 50,
      name: 'Leisure and Hospitality',
      value: 'Leisure and Hospitality',
      unavailable: false,
      checked: false,
    },
    {
      id: 51,
      name: 'Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)',
      value:
        'Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)',
      unavailable: false,
      checked: false,
    },
    {
      id: 52,
      name: 'Machinery Manufacturing',
      value: 'Machinery Manufacturing',
      unavailable: false,
      checked: false,
    },
    {
      id: 53,
      name: 'Management of Companies and Enterprises',
      value: 'Management of Companies and Enterprises',
      unavailable: false,
      checked: false,
    },
    {
      id: 54,
      name: 'Manufacturing',
      value: 'Manufacturing',
      unavailable: false,
      checked: false,
    },
    {
      id: 55,
      name: 'Merchant Wholesalers, Durable Goods',
      value: 'Merchant Wholesalers, Durable Goods',
      unavailable: false,
      checked: false,
    },
    {
      id: 56,
      name: 'Merchant Wholesalers, Nondurable Goods',
      value: 'Merchant Wholesalers, Nondurable Goods',
      unavailable: false,
      checked: false,
    },
    {
      id: 57,
      name: 'Mining (except Oil and Gas)',
      value: 'Mining (except Oil and Gas)',
      unavailable: false,
      checked: false,
    },
    {
      id: 58,
      name: 'Mining, Quarrying, and Oil and Gas Extraction',
      value: 'Mining, Quarrying, and Oil and Gas Extraction',
      unavailable: false,
      checked: false,
    },
    {
      id: 59,
      name: 'Miscellaneous Manufacturing',
      value: 'Miscellaneous Manufacturing',
      unavailable: false,
      checked: false,
    },
    {
      id: 60,
      name: 'Miscellaneous Store Retailers',
      value: 'Miscellaneous Store Retailers',
      unavailable: false,
      checked: false,
    },
    {
      id: 61,
      name: 'Monetary Authorities - Central Bank',
      value: 'Monetary Authorities - Central Bank',
      unavailable: false,
      checked: false,
    },
    {
      id: 62,
      name: 'Motion Picture and Sound Recording Industries',
      value: 'Motion Picture and Sound Recording Industries',
      unavailable: false,
      checked: false,
    },
    {
      id: 63,
      name: 'Motor Vehicle and Parts Dealers',
      value: 'Motor Vehicle and Parts Dealers',
      unavailable: false,
      checked: false,
    },
    {
      id: 64,
      name: 'Museums, Historical Sites, and Similar Institutions',
      value: 'Museums, Historical Sites, and Similar Institutions',
      unavailable: false,
      checked: false,
    },
    {
      id: 65,
      name: 'Natural Resources and Mining',
      value: 'Natural Resources and Mining',
      unavailable: false,
      checked: false,
    },
    {
      id: 66,
      name: 'Nonmetallic Mineral Product Manufacturing',
      value: 'Nonmetallic Mineral Product Manufacturing',
      unavailable: false,
      checked: false,
    },
    {
      id: 67,
      name: 'Nonstore Retailers',
      value: 'Nonstore Retailers',
      unavailable: false,
      checked: false,
    },
    {
      id: 68,
      name: 'Nursing and Residential Care Facilities',
      value: 'Nursing and Residential Care Facilities',
      unavailable: false,
      checked: false,
    },
    {
      id: 69,
      name: 'Oil and Gas Extraction',
      value: 'Oil and Gas Extraction',
      unavailable: false,
      checked: false,
    },
    {
      id: 70,
      name: 'Other Information Services',
      value: 'Other Information Services',
      unavailable: false,
      checked: false,
    },
    {
      id: 71,
      name: 'Other Services (except Public Administration)',
      value: 'Other Services (except Public Administration)',
      unavailable: false,
      checked: false,
    },
    {
      id: 72,
      name: 'Paper Manufacturing',
      value: 'Paper Manufacturing',
      unavailable: false,
      checked: false,
    },
    {
      id: 73,
      name: 'Performing Arts, Spectator Sports, and Related Industries',
      value: 'Performing Arts, Spectator Sports, and Related Industries',
      unavailable: false,
      checked: false,
    },
    {
      id: 74,
      name: 'Personal and Laundry Services',
      value: 'Personal and Laundry Services',
      unavailable: false,
      checked: false,
    },
    {
      id: 75,
      name: 'Petroleum and Coal Products Manufacturing',
      value: 'Petroleum and Coal Products Manufacturing',
      unavailable: false,
      checked: false,
    },
    {
      id: 76,
      name: 'Pipeline Transportation',
      value: 'Pipeline Transportation',
      unavailable: false,
      checked: false,
    },
    {
      id: 77,
      name: 'Plastics and Rubber Products Manufacturing',
      value: 'Plastics and Rubber Products Manufacturing',
      unavailable: false,
      checked: false,
    },
    {
      id: 78,
      name: 'Postal Service',
      value: 'Postal Service',
      unavailable: false,
      checked: false,
    },
    {
      id: 79,
      name: 'Primary Metal Manufacturing',
      value: 'Primary Metal Manufacturing',
      unavailable: false,
      checked: false,
    },
    {
      id: 80,
      name: 'Printing and Related Support Activities',
      value: 'Printing and Related Support Activities',
      unavailable: false,
      checked: false,
    },
    {
      id: 81,
      name: 'Private Households',
      value: 'Private Households',
      unavailable: false,
      checked: false,
    },
    {
      id: 82,
      name: 'Professional and Business Services',
      value: 'Professional and Business Services',
      unavailable: false,
      checked: false,
    },
    {
      id: 83,
      name: 'Professional, Scientific, and Technical Services',
      value: 'Professional, Scientific, and Technical Services',
      unavailable: false,
      checked: false,
    },
    {
      id: 84,
      name: 'Publishing Industries (except Internet)',
      value: 'Publishing Industries (except Internet)',
      unavailable: false,
      checked: false,
    },
    {
      id: 85,
      name: 'Rail Transportation',
      value: 'Rail Transportation',
      unavailable: false,
      checked: false,
    },
    {
      id: 86,
      name: 'Real Estate',
      value: 'Real Estate',
      unavailable: false,
      checked: false,
    },
    {
      id: 87,
      name: 'Real Estate and Rental and Leasing',
      value: 'Real Estate and Rental and Leasing',
      unavailable: false,
      checked: false,
    },
    {
      id: 88,
      name: 'Religious, Grantmaking, Civic, Professional, and Similar Organizations',
      value:
        'Religious, Grantmaking, Civic, Professional, and Similar Organizations',
      unavailable: false,
      checked: false,
    },
    {
      id: 89,
      name: 'Rental and Leasing Services',
      value: 'Rental and Leasing Services',
      unavailable: false,
      checked: false,
    },
    {
      id: 90,
      name: 'Repair and Maintenance',
      value: 'Repair and Maintenance',
      unavailable: false,
      checked: false,
    },
    {
      id: 91,
      name: 'Retail Trade',
      value: 'Retail Trade',
      unavailable: false,
      checked: false,
    },
    {
      id: 92,
      name: 'Scenic and Sightseeing Transportation',
      value: 'Scenic and Sightseeing Transportation',
      unavailable: false,
      checked: false,
    },
    {
      id: 93,
      name: 'Securities, Commodity Contracts, and Other Financial Investments and Related Activities',
      value:
        'Securities, Commodity Contracts, and Other Financial Investments and Related Activities',
      unavailable: false,
      checked: false,
    },
    {
      id: 94,
      name: 'Service-Providing Industries',
      value: 'Service-Providing Industries',
      unavailable: false,
      checked: false,
    },
    {
      id: 95,
      name: 'Social Assistance',
      value: 'Social Assistance',
      unavailable: false,
      checked: false,
    },
    {
      id: 96,
      name: 'Specialty Trade Contractors',
      value: 'Specialty Trade Contractors',
      unavailable: false,
      checked: false,
    },
    {
      id: 97,
      name: 'Sporting Goods, Hobby, Book, and Music Stores',
      value: 'Sporting Goods, Hobby, Book, and Music Stores',
      unavailable: false,
      checked: false,
    },
    {
      id: 98,
      name: 'Support Activities for Agriculture and Forestry',
      value: 'Support Activities for Agriculture and Forestry',
      unavailable: false,
      checked: false,
    },
    {
      id: 99,
      name: 'Support Activities for Mining',
      value: 'Support Activities for Mining',
      unavailable: false,
      checked: false,
    },
    {
      id: 100,
      name: 'Support Activities for Transportation',
      value: 'Support Activities for Transportation',
      unavailable: false,
      checked: false,
    },
    {
      id: 101,
      name: 'Telecommunications',
      value: 'Telecommunications',
      unavailable: false,
      checked: false,
    },
    {
      id: 102,
      name: 'Textile Mills',
      value: 'Textile Mills',
      unavailable: false,
      checked: false,
    },
    {
      id: 103,
      name: 'Textile Product Mills',
      value: 'Textile Product Mills',
      unavailable: false,
      checked: false,
    },
    {
      id: 104,
      name: 'Trade, Transportation, and Utilities',
      value: 'Trade, Transportation, and Utilities',
      unavailable: false,
      checked: false,
    },
    {
      id: 105,
      name: 'Transit and Ground Passenger Transportation',
      value: 'Transit and Ground Passenger Transportation',
      unavailable: false,
      checked: false,
    },
    {
      id: 106,
      name: 'Transportation Equipment Manufacturing',
      value: 'Transportation Equipment Manufacturing',
      unavailable: false,
      checked: false,
    },
    {
      id: 107,
      name: 'Transportation and Warehousing',
      value: 'Transportation and Warehousing',
      unavailable: false,
      checked: false,
    },
    {
      id: 108,
      name: 'Truck Transportation',
      value: 'Truck Transportation',
      unavailable: false,
      checked: false,
    },
    {
      id: 109,
      name: 'Utilities',
      value: 'Utilities',
      unavailable: false,
      checked: false,
    },
    {
      id: 110,
      name: 'Warehousing and Storage',
      value: 'Warehousing and Storage',
      unavailable: false,
      checked: false,
    },
    {
      id: 111,
      name: 'Waste Management and Remediation Services',
      value: 'Waste Management and Remediation Services',
      unavailable: false,
      checked: false,
    },
    {
      id: 112,
      name: 'Water Transportation',
      value: 'Water Transportation',
      unavailable: false,
      checked: false,
    },
    {
      id: 113,
      name: 'Wholesale Electronic Markets and Agents and Brokers',
      value: 'Wholesale Electronic Markets and Agents and Brokers',
      unavailable: false,
      checked: false,
    },
    {
      id: 114,
      name: 'Wholesale Trade',
      value: 'Wholesale Trade',
      unavailable: false,
      checked: false,
    },
    {
      id: 115,
      name: 'Wood Product Manufacturing',
      value: 'Wood Product Manufacturing',
      unavailable: false,
      checked: false,
    },
  ],

  implementations: [
    { id: 1, name: 'In-house', value: 'In-house', unavailable: false },
    {
      id: 2,
      name: 'Third party',
      value: 'Third party',
      unavailable: false,
    },
    {
      id: 3,
      name: 'In-house and third party',
      value: 'In-house and third party',
      unavailable: false,
    },
  ],

  companySizes: [
    {
      id: 1,
      name: 'self-employed',
      value: 'self_employed',
      unavailable: false,
      checked: false,
    },
    { id: 2, name: '1-10 employees', value: 'ten', unavailable: false },
    { id: 3, name: '11-50 employees', value: 'fifty', unavailable: false },
    {
      id: 4,
      name: '51-200 employees',
      value: 'two_hundred',
      unavailable: false,
      checked: false,
    },
    {
      id: 5,
      name: '201-500 employees',
      value: 'five_hundred',
      unavailable: false,
      checked: false,
    },
    {
      id: 6,
      name: '501-1,000 employees',
      value: 'one_thousand',
      unavailable: false,
      checked: false,
    },
    {
      id: 7,
      name: '1,001-5,000 employees',
      value: 'five_thousand',
      unavailable: false,
      checked: false,
    },
    {
      id: 8,
      name: '5,001-10,000 employees',
      value: 'ten_thousand',
      unavailable: false,
      checked: false,
    },
    {
      id: 9,
      name: '10,001+ employees',
      value: 'max',
      unavailable: false,
      checked: false,
    },
  ],
}

export const sortOptions = [
  'Featured',
  'Alphabetical (A - Z)',
  'Alphabetical (Z - A)',
  'Rating (High - Low)',
  'Rating (Low - High)',
]

export const sortOptionsMap = {
  Featured: 'is_featured',
  'Highest Rated': 'rating desc',
  'Most Reviews': 'review desc',
  'Alphabetical': 'name asc',
}

export const sortOptionsReverseMap = {
  is_featured: 'Featured',
  'rating desc': 'Highest Rated',
  'review desc': 'Most Review',
  'name asc': 'Alphabetical',
}

export const sortOptionsAbbreviations = {
  is_featured: 'Featured',
  'rating desc': 'Rating',
  'review desc': 'Review',
  'name asc': 'Alphabet',
}