import { useState, useEffect } from "react";

type DebounceProps = {
  value: string;
  delay: number;
};

export const useDebounce = (value, delay = 500): DebounceProps => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => clearTimeout(timeout);
  }, [value, delay]);

  return debouncedValue;
};
