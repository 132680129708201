import HybridLink from "@/components/atoms/widgets/HybridLink"
import { minifyWithoutBreakingWord } from "@/utils/minifyString"

type LearnMoreSectionProps = {
  links: [],
  categoryName: string
}

const LearnMoreSection = ({links, categoryName}: LearnMoreSectionProps) => {
  return (
    <div className="mx-auto my-8">
      <p className="text-3xl md:text-[38px] text-center font-extrabold mb-8">Learn More About {categoryName}</p>
      <div className="flex flex-col md:flex-row items-center md:items-start gap-6">
        {links?.map((link) => {
          return (
            (link?.title && link?.image_url) &&
            <HybridLink 
              className="flex p-6 shadow-card flex-col gap-4 w-[370px] sm:w-[300px]" 
              href={link.url} 
              key={link.url}
            >
              <img
                src={link?.image_url}
                alt={link?.title}
                className="w-[370px] sm:w-[300px] h-[238px] sm:h-[180px]"
                loading="lazy"
              />
              <div className="">
                <p className="lg:text-md font-bold h-[80px] overflow-clip">
                  {minifyWithoutBreakingWord(link?.title, 45, 35)} →
                </p>
              </div>
            </HybridLink>
          );
        })}
      </div>
    </div>
  )
}

export default LearnMoreSection
