import { Disclosure } from '@headlessui/react'
import clsx from 'clsx'

import { ReactComponent as StarBlue } from '../../../../assets/images/Star Blue.svg'
import { ReactComponent as StarEmpty } from '../../../../assets/images/star-empty.svg'
import { ReactComponent as StarGold } from '../../../../assets/images/star-gold.svg'
import { FaChevronDown } from "react-icons/fa"

function FilterDisclosureContainer({
  className,
  ...props
}: {
  className?: string
  children: React.ReactNode
}) {
  return (
    <Disclosure
      as='div'
      className='rounded-[4px] border bg-dark-gray'
      {...props}
    />
  )
}

function FilterDisclosureButton({
  className,
  children,
  active = false,
  isModal = false,
  ...props
}: {
  className?: string
  children: React.ReactNode
  active: boolean
  isModal: boolean
}) {
  return (
    <Disclosure.Button
      as={isModal ? 'a' : 'button'}
      className={clsx(
        'relative block w-full rounded-xl px-2 py-1 lg:px-2 lg:py-[2px] text-left',
        className
      )}
      {...props}
    >
      {active && (
        <span className='absolute top-[-3px] -right-1 flex size-3 rounded-full bg-dark-blue' />
      )}
      <div className='flex flex-row justify-between'>
        {children}
        <FaChevronDown className='ui-open:rotate-180 ui-not-open:rotate-0 text-[#747474] w-[16px] self-center' />
      </div>
    </Disclosure.Button>
  )
}

function FilterDisclosurePanel({
  className,
  ...props
}: {
  className?: string
  children: React.ReactNode
}) {
  return (
    <Disclosure.Panel className={clsx('mt-2 p-2 px-4 absolute bg-white lg:border-[1px] lg:rounded-md lg:border-gray-300', className)} {...props}>
      {props.children}
    </Disclosure.Panel>
  )
}

function FilterCheckbox({
  value,
  children,
  onChange,
  name,
  checked,
  textClassName = '',
}: {
  value: string | number
  children: React.ReactNode
  onChange: (event: any) => void
  checked: boolean
  name: string
  textClassName?: string
}) {
  return (
    <label className={clsx('flex snap-start items-center gap-3')}>
      <input
        type='checkbox'
        className='form-checkbox size-[14px] cursor-pointer rounded-md border-medium-gray text-dark-blue focus:outline-none focus:outline-0 focus:ring-0 focus:ring-white md:size-[18px]'
        value={value}
        name={name}
        onChange={onChange}
        checked={checked}
      />
      <div
        className={clsx(
          'leading-6 md:text-sm md:leading-[21px]',
          textClassName
        )}
      >
        {children}
      </div>
    </label>
  )
}

type FilterAndSortProps = {
  filter: {
    price?: number[]
    sort?: string
    claimed?: boolean
    rating_gteq?: number
    selectedCategories?: schema.Category[]
    setSelectedCategories?: (filter: {}) => void
  }
  setFilter: (filter: {}) => void
  onClose: () => void
  categories: schema.Category[]
  selectedCategories: schema.Category[]
  handleSelectedCategory: (category: schema.Category) => void
  isModal: boolean | false
  hideCategoryFilter?: boolean
}

export function FilterSubhead({
  className,
  children,
  ...props
}: {
  className?: string
  children: React.ReactNode
}) {
  return (
    <p
      className={clsx('mb-3 text-sm font-medium text-dark-gray', className)}
      {...props}
    >
      {children}
    </p>
  )
}

export function FilterStarDisplay({
  count,
  hideEmptyStars = false,
  hideUp = false,
  starBlue = false,
}: {
  count: number
  hideEmptyStars?: boolean
  hideUp?: boolean
  starBlue?: boolean
}) {
  return (
    <div className='flex items-center'>
      {Array.from({ length: 5 }).map((_, index) =>
        index <= count ? (
          starBlue ? (
            <StarBlue key={index} />
          ) : (
            <StarGold key={index} />
          )
        ) : hideEmptyStars ? null : (
          <StarEmpty key={index} className='text-white' />
        )
      )}
      {!hideUp && <div className='ml-1'>& Up</div>}
    </div>
  )
}

const RATINGS = Array.from({ length: 4 })
  .map((_, index) => index)
  .reverse()

const FilterSidebar = ({
  filter,
  setFilter,
  onClose,
  categories,
  handleSelectedCategory,
  selectedCategories,
  isModal = false,
  hideCategoryFilter = false,
}: FilterAndSortProps) => {
  function handleChange({ target: { name, checked, value } }) {
    const newFilterValue = {
      ...filter,
      [name]: checked ? value : null,
    }
    setFilter(newFilterValue)
  }

  const getActive = (selectedCategories) => {
    return selectedCategories.length > 0
  }

  return (
    <div className='z-10 flex size-full flex-col justify-between rounded-3xl bg-white sm:block sm:h-auto'>
      <div className='bg-white'>
          <div className='flex flex-col gap-2 text-[14px] text-[#747474]'>
            <FilterDisclosureContainer>
              <FilterDisclosureButton
                active={Boolean(filter?.rating_gteq)}
                isModal={isModal}
              >
                Rating
              </FilterDisclosureButton>
              <FilterDisclosurePanel className='grid gap-5 md:gap-3'>
                {RATINGS.map((index) => (
                  <FilterCheckbox
                    key={index}
                    checked={Number(filter.rating_gteq) === index + 1}
                    onChange={handleChange}
                    value={index + 1}
                    name='rating_gteq'
                  >
                    <FilterStarDisplay count={index} />
                  </FilterCheckbox>
                ))}
              </FilterDisclosurePanel>
            </FilterDisclosureContainer>
            {/* Price filter */}
            {!hideCategoryFilter && (
              <FilterDisclosureContainer>
                <FilterDisclosureButton
                  active={getActive(selectedCategories)}
                  isModal={isModal}
                >
                  Categories ({categories.length})
                </FilterDisclosureButton>
                <FilterDisclosurePanel className='relative grid max-h-80 snap-y snap-mandatory gap-6 overflow-y-auto md:gap-3'>
                  {categories.map((category) => (
                    <FilterCheckbox
                      key={category.id}
                      name='categories'
                      onChange={() => {
                        handleSelectedCategory(category)
                      }}
                      value={category.id}
                      textClassName='md:text-xs'
                      checked={Boolean(
                        selectedCategories.find(
                          (selectedCategory) =>
                            selectedCategory.id === category.id
                        )
                      )}
                    >
                      {category.name}
                    </FilterCheckbox>
                  ))}
                  <div className='sticky bottom-[-17px] h-[100px] w-full snap-start snap-always bg-gradient-3 md:h-[50px]' />
                </FilterDisclosurePanel>
              </FilterDisclosureContainer>
            )}
          </div>
      </div>
    </div>
    
  )
}

export default FilterSidebar
