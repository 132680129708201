import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface ErrorCardProps {
  link: string
  image: any
  title: string
  description: string
}

const ErrorCard: React.FC<ErrorCardProps> = ({ link, image, title, description }) => {
  return (
    <a href={link}>
      <div className='flex h-[84px] w-[325px] max-w-[388px] flex-row items-center justify-start gap-4 rounded-lg bg-white px-6 shadow-lg lg:h-[109px] lg:w-[252px] lg:max-w-[252px] lg:justify-center lg:p-4 xxs:w-[380px]'>
        <div>
          <FontAwesomeIcon
            color='#2679A5'
            icon={image}
            size='2xl'
          />
        </div>
        <div>
          <p className='text-[14.8px] font-bold'>{title}</p>
          <p className='text-[10.8px] font-semibold text-[#747474]'>
            {description}
          </p>
        </div>
      </div>
    </a>
  )
}

export default ErrorCard
