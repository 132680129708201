type CharacterCounterProps = {
  currentLength: number
  maxLength: number
  label?: string
  className?: string
}

const CharacterCounter = ({
  currentLength,
  maxLength,
  label,
  className = '',
}: CharacterCounterProps) => {
  const remaining = maxLength - currentLength
  const isLimitReached = remaining <= 0

  return (
    <div className={`text-xs ${className}`}>
      {label && <span className='font-sm mr-2'>{label}:</span>}
      <span className={isLimitReached ? 'text-red-500' : 'text-gray-700'}>
        {currentLength}/{maxLength} characters
      </span>
    </div>
  )
}

export default CharacterCounter
