import { useState } from 'react'

import { MdKeyboardArrowRight } from 'react-icons/md'

import Button from '@/components/atoms/buttons/Button'
import { isValidURL } from '@/utils/helperFunctions'
import minifyString from '@/utils/minifyString'

type BuyerGuideProps = {
  category: schema.Category
  mediaDetails: {
    media_url: string
    media_type: string
    file_name: string
  }
}

const BuyerGuide = ({ category, mediaDetails }: BuyerGuideProps) => {
  const [showMore, setShowMore] = useState(category.guide_description?.length > 360)
  const trimmedDescription = category.guide_description?.trim()
  const shouldMinify = trimmedDescription?.length > 360 && showMore
  const displayDescription = shouldMinify
    ? minifyString(trimmedDescription, 360, 360, true)
    : trimmedDescription

  const articleUrl = isValidURL(category.article_url)
    ? new URL(category.article_url)?.hostname
    : null

  return (
    <div className='m-6'>
      {category.guide_description && (
        <div className='flex flex-col justify-between lg:flex-row lg:gap-10'>
          {mediaDetails && (
            <div
              className={
                'flex w-full flex-col items-center justify-center lg:w-1/2 lg:min-w-[330px]'
              }
            >
              {mediaDetails.media_type?.includes('image') ? (
                <img
                  src={mediaDetails.media_url}
                  alt={mediaDetails.file_name}
                  className='rounded-2xl'
                />
              ) : (
                <video
                  src={mediaDetails.media_url}
                  controls
                  autoPlay
                  muted
                  className='rounded-2xl'
                />
              )}
            </div>
          )}
          <div
            className={`${mediaDetails && 'lg:w-1/2'} flex w-full flex-col justify-center gap-6 sm:py-0 md:gap-3`}
          >
            <div className='flex flex-col gap-3 lg:pr-5'>
              <div className='flex items-center justify-between'>
                <h4 className='lg-mt-0 mt-5 text-[28px] whitespace-normal font-extrabold text-black lg:text-[38px]'>
                  {`${category.name} Buyer’s Guide`}
                </h4>
              </div>
              <div className='font-normal text-black text-[16px]'>
                {displayDescription}
                {trimmedDescription?.length > 360 && (
                  <button
                    className={'ml-2 text-teal'}
                    onClick={() => setShowMore(!showMore)}
                  >
                    {showMore ? 'Show more' : 'Show less'}
                  </button>
                )}
              </div>
              {articleUrl && (
                <div className='flex lg:justify-start justify-center items-center flex gap-2 mt-2 lg:mt-4'>
                  <Button
                    variant='secondary'
                    className='flex flex-row justify-center items-center py-3 w-[171px] h-[34px] !rounded-lg'
                  >
                    <a
                      href={category.article_url}
                      className='flex flex-row items-center justify-center gap-1 self-center'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <p className='font-bold text-[12.78px]'>Read More</p>
                      <MdKeyboardArrowRight size={20}/>
                    </a>
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default BuyerGuide
