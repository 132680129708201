import { useEffect, useState } from "react";

export default function useComparedSolutions() {

  const localStoredSolutions = localStorage.getItem('solutionsToCompare')

  const [solutionsToCompare, setSolutionsToCompare] = useState(() => {
    if (!(localStoredSolutions === 'null')){
      return JSON.parse(localStoredSolutions)
    }
    else {
      return []
    }
  })

  useEffect(() => {
    if (solutionsToCompare?.length > 0){
      localStorage.setItem('solutionsToCompare', JSON.stringify(solutionsToCompare))
    }
    else {
      localStorage.setItem('solutionsToCompare', null)
    }
  }, [solutionsToCompare])

  return [solutionsToCompare, setSolutionsToCompare]
}
