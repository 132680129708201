export const isEmailValid = (email: string): boolean => {
  const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
  
  return emailRegex.test(email);
};

export const isWorkEmail = (email: string): boolean => {
  const spamRegex = /\b[A-Za-z0-9._%+-]+@(?!gmail\.com|yahoo\.com|ymail\.com|aol\.com|msn\.com|outlook\.com|protonmail\.com)[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/
  return (spamRegex.test(email));
}
