import React, { useEffect, useState } from 'react'

import { useDropzone } from 'react-dropzone'
import { MdUploadFile, MdClose } from 'react-icons/md'
import { toast } from 'react-toastify'

import Button from '@/components/atoms/buttons/Button'
import { useToken } from '@/components/atoms/forms/Form'
import Modal from '@/components/atoms/modals/Modal'
import ErrorMessage from '@/components/atoms/Toast/ErrorMessage'
import SuccessMessage from '@/components/atoms/Toast/SuccessMessage'
import { postRequest } from '@/utils/axiosRequests'
import { defaultToastOptions } from '@/utils/commonToast'
import { documentValidation } from '@/utils/fileValidations'

function PressReleaseModal({ onClose }: { onClose: () => void }) {
  const pressReleaseEndpoint = '/users/send_press_release'

  const [message, setMessage] = useState<string>('')
  const [file, setFile] = useState<File | null>(null)
  const [isFormValid, setIsFormValid] = useState<boolean>()
  const [error, setError] = useState<{ message: string, file: string }>({ message: '', file: '' })

  const token = useToken()

  useEffect(() => {
    checkIfFormValid()
  }, [message, file])

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      setFile(acceptedFiles[0])
    }
  }

  const isFileValid = (file) => {
    if (!file) return true

    const errors = documentValidation(file, 3)
    const hasErrors = errors.length > 0

    if (hasErrors) {
      setIsFormValid(false)
      setError((prevState) => ({ ...prevState, file: errors[0] }))
    }

    return !hasErrors
  }

  const removeFile = () => {
    setFile(null)
    setError((prevState) => ({ ...prevState, file: '' }))
  }

  const checkIfFormValid = () => {
    if (message.trim().length > 0 && !file) {
      setIsFormValid(true)
    }
    else if (message.trim().length > 0 && file && isFileValid(file)) {
      setIsFormValid(true)
    }
    else {
      setIsFormValid(false)
    }
  }

  const handleSubmit = async () => {
    setError({ message: '', file: '' })
    if (message.trim().length > 0) {
      const formData = new FormData()
      formData.append('message', message)
      if (file) {
        const isValid = isFileValid(file)
        if (!isValid) {
          return
        }
        formData.append('file', file)
      }
      try {
        const response = await postRequest(pressReleaseEndpoint, formData, token)
        if (response.status === 200) {
          toast(
            <SuccessMessage message={'Press Release has been submitted successfully.'} />,
            defaultToastOptions('teal-tint-2')
          )
        }
      } catch {
        toast(
          <ErrorMessage />,
          defaultToastOptions('red-200')
        )
      }
      onClose()
    }
    else {
      setError((prevState) => ({ ...prevState, message: 'Message is required' }))
    }
  }

  const FileInput = () => {
    const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      accept: { 'application/pdf': ['.pdf'], 'application/msword': ['.doc', '.docx'] },
      maxFiles: 1
    })

    return (
      <>
        <p className='mb-3 text-[17px] font-medium text-outer-space'>Attach File (optional)</p>
        <div
          {...getRootProps()}
          className='mb-4 flex h-[153px] w-full cursor-pointer flex-col items-center justify-center gap-2 rounded-md border border-dashed border-gray-200 p-4 text-center'
        >
          <input {...getInputProps()} />
          <div className='flex size-10 items-center justify-center rounded-[50%] bg-vivid-cyan'>
            <MdUploadFile className='size-6 text-bright-turquoise' />
          </div>
          <p>
            <span className='text-bright-turquoise underline'>Click to Upload</span>
            <span> or drag and drop</span>
          </p>
          <p className='text-[15px] text-gray-400'>PDF or DOC (max. 3MB)</p>
        </div>

        {file && (
          <div className='mb-5 ml-3 flex items-center justify-between'>
            <div className='flex'>
              <div className='flex size-10 items-center justify-center rounded-[50%] bg-vivid-cyan'>
                <MdUploadFile className='size-6 text-bright-turquoise' />
              </div>
              <div className='ml-3 flex flex-col'>
                <span className='text-sm text-outer-space'>{file.name}</span>
                <span className='text-xs text-gray-400'>
                  {`${(file.size / 1024).toFixed(2)} KB`}
                </span>
              </div>
            </div>
            <MdClose className='ml-2 size-5 cursor-pointer' onClick={removeFile} />
          </div>
        )}
        <p className='text-sm leading-[60px] text-red-600'>{error.file}</p>
      </>

    )
  }

  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      className='mx-4 max-h-[600px] w-full overflow-auto rounded-xl bg-white p-10 sm:max-h-[787px] md:w-[564px]'
    >
      <div>
        <p className='mb-2 text-5xl font-medium text-outer-space'>Got News? Let’s Amplify It!</p>
        <p className='mb-10 text-sm text-outer-space'>
          Do you have a press release or news story worth sharing with our network of talent professionals? Drop it here for our editorial team to review. If it’s a good fit, we’ll give it the spotlight it deserves in our newsletters or social media—let’s get your story out there!
        </p>
        <div className='mb-4'>
          <p className='mb-3 text-[17px] font-medium text-outer-space'>Your update</p>
          <textarea
            value={message}
            onChange={(e) => { setMessage(e.target.value) }}
            placeholder='Type here'
            className='h-24 w-full rounded-md border-gray-300 p-2 font-light placeholder:text-outer-space-2'
          />
          <p className='leading-[60px] text-red-600'>{error.message}</p>
        </div>
        <FileInput />
        <Button
          variant={isFormValid ? 'gulfBlue' : 'gray'}
          className={'relative flex h-[44px] w-[246px] items-center justify-center !rounded-[12px] text-md'}
          type='submit'
          onClick={() => handleSubmit()}
        >
          Submit
        </Button>
      </div>
    </Modal>
  )
}

export default PressReleaseModal