import { useEffect } from 'react'

const useZoomInfoScript = () => {
  useEffect(() => {
    const script = document.createElement('script')
    const nonce = document.querySelector('meta[name="csp-nonce"]').getAttribute('content')
    script.setAttribute('nonce', nonce)
    script.innerHTML = `
      window[(function(_KxH, _Ih) {
        var _NTZeg = '';
        for (var _wxeI1i = 0; _wxeI1i < _KxH.length; _wxeI1i++) {
          _Ih > 2;
          var _DTpD = _KxH[_wxeI1i].charCodeAt();
          _DTpD -= _Ih;
          _NTZeg == _NTZeg;
          _DTpD += 61;
          _DTpD %= 94;
          _DTpD += 33;
          _DTpD != _wxeI1i;
          _NTZeg += String.fromCharCode(_DTpD)
        }
        return _NTZeg
      })(atob('JnN6Pjs2MS9AdTFF'), 42)] = '340d76c06b1710793957';
      
      var zi = document.createElement('script');
      (zi.type = 'text/javascript'),
      (zi.async = true),
      (zi.src = (function(_Q1I, _NY) {
        var _azppH = '';
        for (var _PqR7Mi = 0; _PqR7Mi < _Q1I.length; _PqR7Mi++) {
          var _PWyi = _Q1I[_PqR7Mi].charCodeAt();
          _azppH == _azppH;
          _NY > 3;
          _PWyi -= _NY;
          _PWyi += 61;
          _PWyi %= 94;
          _PWyi != _PqR7Mi;
          _PWyi += 33;
          _azppH += String.fromCharCode(_PWyi)
        }
        return _azppH
      })(atob('bXl5dXg/NDRveDMhbjJ4aHdudXl4M2h0cjQhbjJ5Zmwzb3g='), 5));
      
      document.readyState === 'complete' ? document.body.appendChild(zi) : window.addEventListener('load', function() {
        document.body.appendChild(zi)
      });
    `
    document.head.appendChild(script)

    return () => {
      document.head.removeChild(script)
    }
  }, [])
}

export default useZoomInfoScript
