import React from 'react'

export default function ArticleCard({ arrow, image, authorImage, authorName, title, category, date, duration }) {

  return (
    <div className={`mb-2 py-3 content rounded-2xl shadow-md min-h-[331px] max-w-[234px] sm:!min-w-[200px] sm:!min-h-[278px] md:!min-w-[230px] md:!min-h-[319px] lg:!min-w-[304px] lg:!min-h-[421px] mx-[10px] sm:mx-[6px] md:mx-[10px] lg:mx-[15px]`}>
      <div className={'rounded-t-2xl min-h-[220px] sm:!h-[160px] md:!h-[180px] lg:!h-[276px] bg-cover bg-center'} style={{ backgroundImage: `url(${image})` }}>
        <div className='flex items-center justify-end px-2 sm:px-4 py-1'>
          <img src={arrow} alt={`Arrow Icon`} className='ml-2 mt-1 p-0 hidden sm:block' />
        </div>
      </div>
      <div className='flex flex-col justify-evenly px-3 sm:p-5 sm:gap-0 h-[130px] sm:!h-[118px] md:!h-[139px] lg:!h-[145px]'>
        <div className='text-[10px] sm:text-[0.625rem] text-gray-500 font-semibold leading-normal'>
          <p>{category}</p>
        </div>
        <p className='h-[40px] sm:!h-[80px] md:![100px] lg:![104px] overflow-hidden text-black text-[14px] md:text-[12px] lg:text-[14px] font-bold leading-normal'>{title}</p>
        <div>
        <hr className='w-[134px] sm:w-auto mb-1 sm:mb-2 ' />
        <p className='text-[10px] text-black sm:text-[0.875rem] font-semibold leading-normal'>{date} - {duration === "< 1" ? 'Less than 1 minute' : duration + ' minutes'}</p>
        </div>
      </div>
    </div>
  )
}
