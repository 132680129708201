const minifyString = (str: string, maxlength: number, limit: number, dots: boolean = true) => {
  if (!str) return

  if (str.length > limit) return dots? str.slice(0, maxlength) + '...' : str.slice(0, maxlength)

  return str
}

export const minifyWithoutBreakingWord = (str: string, requiredLength: number, maxLength: number): string => {
  if (!str) return ''

  if (str.length <= maxLength) return str

  const arrayOfWords = str.split(' ')
  let resultantString = ''

  for (const word of arrayOfWords) {
    if ((resultantString + word).length > requiredLength) break
    resultantString += word + ' '
  }

  return resultantString.trim()
}

export default minifyString

