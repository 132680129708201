import { useEffect, useRef, useState } from 'react'

export const useInView = ( threshold = 0.1 ) => {
  const [inView, setInView] = useState(false)

  const elementRef = useRef<HTMLElement | null>(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach ((entry) => {
          if(entry.isIntersecting)
          {
            setInView(true)
          }
        })
      },
      { threshold }
    )

    if(elementRef.current)
    {
      observer.observe(elementRef.current)
    }

    return (() => {
      if(elementRef.current)
      {
        observer.unobserve(elementRef.current)
      }
    })
  }, [threshold])

  return [inView, elementRef]
}