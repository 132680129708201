import React, { useState } from 'react'
import TestimonialCard from '@components/molecules/Testimonials/TestimonialCard'
import PlusIcon from 'app/assets/images/plus.svg'
import TestimonialFormModal from '@components/molecules/modals/TestimonialFormModal'

type TestimonialProps = {
  testimonials: schema.Testimonials[]
  editTestimonials: boolean
  solution_slug: string
  setTestimonials: React.Dispatch<React.SetStateAction<schema.Testimonials[]>>
  testimonialsCount: number
}

const Testimonials: React.FC<TestimonialProps> = ({
  testimonials,
  editTestimonials,
  solution_slug,
  setTestimonials,
  testimonialsCount
}) => {
  const [openTestimonialsModal, setOpenTestimonialsModal] = useState(false)
  const [testimonialData, setTestimonialData] = useState<schema.Testimonials>({
    author: '',
    company_name: '',
    company_size: 0,
    description: '',
    rating: 0
  })
  const [addNewTestimonial, setAddNewTestimonial] = useState(false)
  return (
    <>
      <div className='flex md:flex-row flex-col gap-4'>
        {editTestimonials && testimonialsCount < 20 && (
          <div className={` ${testimonials.length == 0 ? 'md:w-[100%] h-[80px]' : 'md:w-[130px]' } w-[100%] h-[100px] md:h-[475px] lg:h-[380px] xl:h-[336px] rounded-xl border-spacing-y-4 border-[1px] sm:mt-0 mt-5`}>
            <div className='flex flex-col items-center justify-center h-full'>
              <div className='size-[40px] bg-gray-200 rounded-full flex items-center justify-center hover:cursor-pointer'>
                <img
                  src={PlusIcon}
                  onClick={() => {
                    setOpenTestimonialsModal(!openTestimonialsModal)
                    setAddNewTestimonial(true)
                  }}
                />
              </div>
            </div>
          </div>
        )}
        {testimonials?.map((testimonial, index) => (
          <TestimonialCard
            key={index}
            editTestimonials={editTestimonials}
            openTestimonialsModal={openTestimonialsModal}
            setOpenTestimonialsModal={setOpenTestimonialsModal}
            testimonialData={testimonial}
            setTestimonialData={setTestimonialData}
            setAddNewTestimonial={setAddNewTestimonial}
          />
        ))}
      </div>
      <TestimonialFormModal
        openTestimonialsModal={openTestimonialsModal}
        setOpenTestimonialsModal={setOpenTestimonialsModal}
        formData={testimonialData}
        setFormData={setTestimonialData}
        solution_slug={solution_slug}
        setTestimonials={setTestimonials}
        addNewTestimonial={addNewTestimonial}
      />
    </>
  )
}
export default Testimonials
