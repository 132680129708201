import { usePage } from '@inertiajs/react';
import { SharedPageProps } from './Users/SignIn';

type HomePageProps = {
  success: boolean;
};

export default function HomePage({ success }: HomePageProps) {
  const { props, ...pageProps } = usePage<SharedPageProps>();
  // eslint-disable-next-line no-console
  console.log({ success, pageProps });

  const { flash } = props;

  return (
    <div>
      {flash?.notice && (
        <div
          className="relative rounded border border-green-400 bg-green-100 px-4 py-3 text-green-700"
          role="alert"
        >
          {flash.notice}
        </div>
      )}
      <div style={{ marginTop: 30 }} className="text-blue-500">
        Home Page
      </div>
    </div>
  );
}
