import { useState, useEffect, useRef } from 'react'

import { Link } from '@inertiajs/react'
import clsx from 'clsx'

import { ReactComponent as CloseIcon } from '../../../../assets/images/close-icon-black.svg'
import { FilterStarDisplay } from '../../atoms/filters/FilterSidebar'

export function ActiveFilters({
  selectedCategories,
  setFilter,
  handleSelectedCategory,
  rating_gteq,
}: {
  selectedCategories: schema.Category[];
  setFilter: (key: string, value: string) => void;
  handleSelectedCategory: (category: schema.Category) => void;
  rating_gteq: number;
}) {
  const [showGradient, setShowGradient] = useState(false)
  const scrollableContainerRef = useRef(null)

  function removeFilter(key: string) {
    setFilter((prev) => ({ ...prev, [key]: undefined }))
  }

  const scrolledToEnd = (element) => {
    return (
      Math.abs(element.offsetWidth + element.scrollLeft - element.scrollWidth) <
      15
    )
  }

  const onScrollHandler = (e) => {
    setShowGradient(!scrolledToEnd(e.target))
  }

  useEffect(() => {
    const fitsWithinContainer = scrolledToEnd(scrollableContainerRef.current)
    setShowGradient(!fitsWithinContainer)
  }, [selectedCategories])

  return (
    <>
      <div
        className='flex max-w-full snap-x gap-4 overflow-x-auto scrollbar-none md:max-w-sm lg:max-w-lg xl:max-w-xl'
        onScroll={onScrollHandler}
        ref={scrollableContainerRef}
      >
        {rating_gteq && (
          <div className='flex gap-1 whitespace-nowrap rounded-full border-none bg-yellow/[.08] py-[6px] px-3 text-xs font-semibold text-dark'>
            <div>
              <FilterStarDisplay hideEmptyStars count={rating_gteq - 1} />
            </div>
            <button onClick={() => removeFilter('rating_gteq')}>
              <CloseIcon width={16} height={16} />
            </button>
          </div>
        )}
        {selectedCategories?.map((category, index) => (
          <div
            className={clsx(
              'flex items-center gap-2 whitespace-nowrap rounded-full border-none bg-blue-tint-2 py-[6px] px-3 text-xs font-semibold text-dark-blue',
              selectedCategories.length == index + 1
                ? 'snap-end'
                : 'snap-center'
            )}
            key={category.id}
          >
            <Link href={`/categories/${category.slug}`} className='snap-center'>
              <div>{category.name}</div>{' '}
            </Link>

            <button onClick={() => handleSelectedCategory(category)}>
              <CloseIcon width={16} height={16} />
            </button>
          </div>
        ))}
        {showGradient && (
          <div className='absolute right-[0px] block h-[32px] w-[40px] rotate-[270deg] bg-gradient-2 md:hidden' />
        )}
      </div>
      {showGradient && (
        <div
          className={clsx(
            'absolute h-[32px] w-[40px] rotate-[270deg] bg-gradient-2 md:right-[200px] lg:right-[200px]'
          )}
        />
      )}
    </>
  )
}
