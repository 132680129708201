import clsx from 'clsx';
import { SolutionCard } from '../../molecules/solution/SolutionCard';

export function SolutionsSection({ ...props }) {
  const { solutions } = props;

  return (
    <div className="flex justify-center py-6 px-4 md:px-10 lg:px-20 xl:mx-[2.188rem]">
      <div
        className={clsx(
          'flex w-full max-w-[1120px] flex-col justify-center gap-8 text-center font-medium'
        )}
      >
        <h4 className="flex justify-center text-md font-bold uppercase tracking-[1px] text-medium-gray">
          Featured Solutions
        </h4>
        <div className="flex h-auto overflow-auto pb-4">
          {solutions?.map((solution) => (
            <div
              key={solution.id}
              className="p-[20px 16px] m-2 w-[365.33px] min-w-[350px]"
            >
              {
                <SolutionCard
                  solution={solution}
                  hideEditButton={true}
                  className="h-full pb-2"
                  hideTags={true}
                  entryPoint="Category Show Page"
                  superFeaturedSection={true}
                />
              }
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
