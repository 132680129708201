import { Popover } from '@headlessui/react'

import { useMobileDetect } from '../../utils/detectMobile'

import { ArticlesSlider } from '@/components/organisms/article/ArticlesSlider'

import { GoChevronRight } from 'react-icons/go'

import { wordpressBaseURL } from '@/utils/constants'

import {
  AboutUsBetaPartners,
  AboutUsCenterDiv,
  MeetMyTeam,
} from '@/components/organisms/AboutUs'

import useCurrentUser from '@/hooks/useCurrentUser'
import Navbar from '@/components/organisms/layouts/Navbar'
import AboutUsHeroSection from '@/components/organisms/heroSection/AboutUsHeroSection'
import OurServicesSection from '@/components/organisms/ourServices/OurServicesSection'
import FooterSection from '@/components/organisms/footerSection/FooterSection'
import MissionAccountSection from '@/components/organisms/missionAccountSection/MissionAccountSection'
import Meta from '@/components/atoms/widgets/Meta'
import PageTitle from '@/components/atoms/widgets/PageTitle'
import HybridLink from '@/components/atoms/widgets/HybridLink'
import HorizontalMarginsWrapper from '@components/atoms/wrappers/HorizontalMarginsWrapper'
import Bg from '../../../assets/images/greyblend.svg'

type AboutUsProps = {
  team_members: schema.TeamMember[]
  pressReleases: schema.Articles[]
}

const outerMargins = 'pt-[4.875rem] md:pt-[7.625rem]'

const AboutUs = ({ team_members, pressReleases } : AboutUsProps) => {
  const isMobile = useMobileDetect()
  const currentUser = useCurrentUser()

  const pressReleasesUrl = `${wordpressBaseURL}topics/press-release/`

  const PressReleaseHeaderSection = () => {
    return (
      <div className='flex flex-col gap-4 lg:gap-6 mx-9 sm:mx-0 mb-8 pt-[7.3rem] lg:pt-[6rem]'>
        <p className='text-[38px] font-bold'>Press Releases</p>
        <div className='text-[18px] sm:text-[0.7rem] md:text-lg font-semibold w-full flex justify-between flex-wrap gap-4 lg:gap-auto'>
          <p>The latest news and announcements from the Talivity team and partners.</p>
          <HybridLink href={pressReleasesUrl} className='flex text-talivity-teal items-center xl:gap-2'>
            <p>View All Press Releases</p>
            <GoChevronRight className='font-extrabold size-5' />
          </HybridLink>
        </div>
      </div>
    )
  }

  return (
    <>
      <PageTitle title='About the Talivity Marketplace | Explore Top TA Tech Tools' />
      <Meta
        title='About the Talivity Marketplace | Explore Top TA Tech Tools'
        description='Learn about Talivity Marketplace, your go-to hub for the top talent acquisition tech solutions, formerly RecruitmentMarketing.com.'
      />
      <div
        className={`flex w-full bg-fade-gray-gradient flex-col ${isMobile ? '' : 'bg-contain'} py-16 bg-no-repeat 3xl:bg-cover`}
        style={{
          backgroundImage: `url(${Bg}), linear-gradient(160.11deg, #FFFFFF 38.99%, #EAECEE 86.72%)`,
          backgroundPosition: `${isMobile ? 'left 263px' : 'center 180px'}`
        }}
      >
        <Popover className='flex flex-col z-50'>
          {({ open }) => <Navbar open={open} />}
        </Popover>
        <AboutUsHeroSection />
      </div>
      <AboutUsBetaPartners />
      <HorizontalMarginsWrapper>
        <OurServicesSection />
      </HorizontalMarginsWrapper>
      <MissionAccountSection />
      { ( currentUser?.newsletter_subscribed ) ? null : (
          <div>
            <AboutUsCenterDiv />
          </div>
        )
      }
      <HorizontalMarginsWrapper>
        <MeetMyTeam teamMembers={team_members}/>
      </HorizontalMarginsWrapper>
      <HorizontalMarginsWrapper classes='!max-w-[1260px]'>
        <PressReleaseHeaderSection />
        <ArticlesSlider
          articles={pressReleases}
          dotsClasses='!left-[-40%] sm:!left-[-49%] xl:!left-[-48%]'
          nextArrowClasses='!left-[86%] sm:!left-[97%] xl:!left-[97%]'
          prevArrowClasses='!left-[80%] sm:!left-[92%] xl:!left-[94%]'
        />
      </HorizontalMarginsWrapper>
      <div className={`${outerMargins}`}>
        <FooterSection />
      </div>
    </>
  )
}

export default AboutUs
