import { Link } from '@inertiajs/react'

import {SolutionStarRating} from '../solution/SolutionStarRating'
import minifyString from '@/utils/minifyString'

type TickerCardProps = {
  solution: schema.Solution
}

const TickerCard = ({ solution }: TickerCardProps) => {
  return (
    <Link href={`/solutions/${solution.slug}`}>
    <div className='my-4 mx-2 flex h-[70px] md:h-[72px] lg:h-[85px] w-[200px] md:w-[240px] flex-row items-center rounded-xl bg-white px-4 py-8 md:py-10 lg:py-12 shadow-card'>
      <div className='w-[40%] md:w-[30%]'>
        <img src={solution.logo_url} alt={solution.name} loading='lazy' />
      </div>
      <div className='mx-4 flex w-[60%] md:w-[70%] flex-col gap-[3px] md:gap-1.5'>
        <h3 className='text-[12.56px] font-bold lg:text-[15px]'>{minifyString(solution.name, 15, 19, true)}</h3>
        <div className='flex flex-row items-center'>
        <SolutionStarRating rating={solution.rating} ratingCount={solution.ratings_count} showDetails={false} />
        <p className='pl-2 text-[8px] font-semibold text-dark-blue lg:text-[10.8px]'>{solution.rating}</p>
        </div>
        <div className='text-[8px] font-semibold text-gray-500 lg:text-[10.8px]'>
          ({solution.ratings_count || 0} ratings)
        </div>
      </div>
    </div>
    </Link>
  )
}

export default TickerCard
