import React from 'react'

import { Popover } from '@headlessui/react'
import clsx from 'clsx'

import HybridLink from '@/components/atoms/widgets/HybridLink'
import {useTracking} from 'react-tracking'
import convertToSlug from '@/utils/convertToSlug'

const NavLinks = ({ currentUser, navLinks }) => {
  const tracking = useTracking()
  return (
    <>
      {navLinks.map((link, index) =>
        link.name === 'My Profile' && !currentUser ? null : (
          <Popover className='relative' key={index}>
            {({ open }) => (
              <div>
                <Popover.Button
                  className='flex w-full items-center gap-2 whitespace-nowrap focus:outline-none focus:outline-0 focus:ring-0'
                  onClick={() => {
                    if (link.external && link?.url) {
                      window.open(link.url, '_self')
                    }
                    tracking.trackEvent({
                      event_name: convertToSlug(link.name),
                      page_url: window.location.pathname,
                      event_loggable_type: "RmcEvents::RmcEvent",
                      additional_attributes: { event_type: 'click_event' }
                    })
                  }}
                >
                  <HybridLink
                    isExternal={link.external}
                    key={index}
                    href={link.url}
                    className={clsx(
                      'flex w-full cursor-pointer items-center justify-end text-sm leading-[27px] text-black sm:pr-1'
                    )}
                  >
                    {link.name}
                  </HybridLink>
                </Popover.Button>
              </div>
            )}
          </Popover>
        )
      )}
    </>
  )
}

export default NavLinks
