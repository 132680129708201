import Fuse from 'fuse.js';
import { Dispatch, SetStateAction } from 'react';

type handleSearchProps = {
  event: React.ChangeEvent<HTMLInputElement>;
  allSolutions: schema.Solution[];
  allCategories: schema.Category[];
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  setSolutionSearchResults?: Dispatch<SetStateAction<schema.Solution[]>>;
  setCategorySearchResults?: Dispatch<SetStateAction<schema.Category[]>>;
};

export default function HandleSearch({
  event,
  allSolutions,
  allCategories,
  searchTerm,
  setSearchTerm,
  setSolutionSearchResults,
  setCategorySearchResults,
}: handleSearchProps) {
  const options = {
    keys: ['name'],
    includeScore: true,
    threshold: 0.3,
    shouldSort: true,
    findAllMatches: true,
    minMatchCharLength: 1,
    tokenize: true,
    matchAllTokens: true,
  };

  const solutions = new Fuse(allSolutions, options);
  const categories = new Fuse(allCategories, options);

  const matchCategories = (query, data) => {
    const categoryMatches = [
      { name: 'Background Checks' },
      { name: 'Backgrounds & References' },
    ];

    const searchKeywordsRegex =
      /(pre-employment|screening|pre|employment|scr)/gi;

    if (searchKeywordsRegex.test(query.toLowerCase())) {
      return data.filter((item) => {
        return categoryMatches.some((category) => category.name === item.name);
      });
    } else {
      // Return the original data if the condition is not met
      return [];
    }
  };

  const matchFirstLetters = (query, data) => {
    return data.filter((item) => {
      const firstLetters = item.name
        .split(' ')
        .map((word) => word[0])
        .join('');
      return firstLetters.toLowerCase() === query.toLowerCase();
    });
  };

  const filterStartsWithQuery = (query, results) => {
    return results.filter((result) => {
      return result.name
        .toLowerCase()
        .split(' ')
        .some((word) => {
          return (
            word.startsWith(query.toLowerCase()) ||
            query.toLowerCase().startsWith(word)
          );
        });
    });
  };

  const newSearchTerm = event.target.value;
  setSearchTerm(newSearchTerm);

  const solutionResults = [];
  const categoryResults = [];

  [newSearchTerm].forEach((query) => {
    const firstLetterMatchesSolution = matchFirstLetters(query, allSolutions);
    const firstLetterMatchesCategory = matchFirstLetters(query, allCategories);
    const SpecificScenarioCategory = matchCategories(query, allCategories);

    if (firstLetterMatchesSolution.length > 0) {
      solutionResults.push(...firstLetterMatchesSolution);
    } else {
      const fuseResults = solutions.search(query).map((result) => result.item);
      const output = filterStartsWithQuery(query, fuseResults);
      if (output.length === 0) {
        const results = solutions.search(query);
        const output = results.map((result) => result.item);
        solutionResults.push(...output);
      } else {
        solutionResults.push(...output);
      }
    }

    if (firstLetterMatchesCategory.length > 0) {
      categoryResults.push(...firstLetterMatchesCategory);
    } else {
      const fuseResults = categories.search(query).map((result) => result.item);
      const output = filterStartsWithQuery(query, fuseResults);
      if (output.length === 0) {
        const results = categories.search(query);
        const output = results.map((result) => result.item);
        categoryResults.push(...output);
      } else {
        categoryResults.push(...output);
      }
    }
    if (SpecificScenarioCategory.length > 0) {
      categoryResults.push(...SpecificScenarioCategory);
    }
  });

  setSolutionSearchResults(solutionResults);
  if (setCategorySearchResults) setCategorySearchResults(categoryResults);
}
