import useCurrentUser from '@/hooks/useCurrentUser';

const getAnonymousUserId = () => {
  if (typeof window === 'undefined') return;
  let userId = localStorage.getItem('anonymousUserId');

  if (!userId) {
    userId = Math.random().toString(36).substring(7);
    localStorage.setItem('anonymousUserId', userId);
  }
};

export default function useCurrentUserId() {
  const currentUser = useCurrentUser();

  const username = currentUser ? currentUser.first_name : getAnonymousUserId();
  return username;
}
