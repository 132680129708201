import { useState, useEffect } from 'react'

import { Select, MenuItem } from '@mui/material'
import { FaChevronDown } from 'react-icons/fa6'

import { getRequest } from '@/utils/axiosRequests'
import { useMobileDetect } from '@/utils/detectMobile'

export default function SolutionCategoryRankCard({ solution }) {
  const isDesktop = useMobileDetect({ screenSize: 1025 })
  const [selectedCategory, setSelectedCategory] = useState(solution.categories_names.sort()[0])
  const [totalSolutions, setTotalSolutions] = useState(0)
  const [rankPosition, setRankPosition] = useState(0)

  const sortedCategories = [...solution.categories_names].sort()

  const fetchSolutionRank = async (solutionId, category) => {
    if (solutionId && category) {
      try {
        const response = await getRequest(`/categories/solution_rank?solution_id=${solutionId}&category=${category}`)
        setTotalSolutions(response.data.total_count)
        setRankPosition(response.data.rank_position)
      } catch (error) {
        console.error('Error fetching solution rank:', error)
      }
    }
  }

  const handleCategorySelect = (category) => {
    setSelectedCategory(category)
    fetchSolutionRank(solution.id, category)
  }

  useEffect(() => {
    fetchSolutionRank(solution.id, selectedCategory)
  }, [selectedCategory, solution.id])

  return (
    <div className={`mb-10 flex h-[100px] flex-col gap-2 rounded-lg bg-titan-white pl-5 pt-3 font-semibold text-blue-zodiac ${isDesktop ? 'w-full' : 'w-1/2'}`}>
      <p className='text-sm'>This solution is ranked</p>
      <div className='flex items-baseline'>
        <p className='text-4xl'>{rankPosition}</p>
        <div className='flex items-baseline gap-1 pb-1 text-md'>
          <span className='whitespace-nowrap'>
          /{totalSolutions} in
          </span>
          {sortedCategories.length > 1 ? (
            <Select
              value={selectedCategory}
              onChange={(e) => handleCategorySelect(e.target.value)}
              IconComponent={FaChevronDown}
              variant='standard'
              disableUnderline
              className='w-[170px] sm:w-[200px] md:w-full'
              sx={{
                fontFamily: 'Manrope',
                fontWeight: 600,
                color: '#0a165c',
                '& .MuiSelect-select': {
                  padding: 0,
                },
                '& .MuiSelect-icon': {
                  color: '#0a165c',
                }
              }}
            >
              {sortedCategories.map((category, index) => (
                <MenuItem key={index} value={category}>
                  {category}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <span className='mt-4 font-semibold'>{selectedCategory}</span>
          )}
        </div>
      </div>
    </div>
  )
}
