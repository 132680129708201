import React, { useState } from 'react'
import PricingEditForm from '@/components/molecules/solution/SolutionPricingEdit'
import { PricingSection } from '@/components/molecules/pricing/PricingSection'
import Button from '@/components/atoms/buttons/Button'
import { MdOutlineEdit as EditIcon } from 'react-icons/md'

type PricingDetailsProps = {
  solution: schema.Solution
  currentUser: schema.User
  isClaimed: boolean
  isSolutionOwner: boolean
  isRmcAdmin: boolean
  solutionOwner: schema.User
  handleRequestAQuote: () => void
}

const PricingDetails = ({
  solution,
  currentUser,
  isClaimed,
  isSolutionOwner,
  isRmcAdmin,
  solutionOwner,
  handleRequestAQuote,
}: PricingDetailsProps) => {
  const [showPriceEditingForm, setShowPriceEditingForm] = useState(false)

  return (
    <div id='pricing'>
      {showPriceEditingForm ? (
        <PricingEditForm solution={solution} current_user={currentUser} />
      ) : (
        <div className='mt-10 flex flex-col justify-between'>
          <div className='flex justify-between'>
            <h3 className='text-lg font-bold text-black md:text-xl'>
              Pricing
            </h3>
            {((isClaimed && isSolutionOwner) || isRmcAdmin) && (
              <Button className='flex items-center justify-center w-9 h-9 !rounded-full bg-gray-100'>
                <EditIcon
                  onClick={() => setShowPriceEditingForm(true)}
                  size='20'
                />
              </Button>
            )}
          </div>
          <PricingSection
            solution={solution}
            currentUser={currentUser}
            isClaimed={isClaimed}
            solutionOwner={solutionOwner}
            handleContactVendor={handleRequestAQuote}
          />
        </div>
      )}
    </div>
  )
}

export default PricingDetails
