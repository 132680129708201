import clsx from 'clsx'

import Next_Arrow from '../../../../assets/images/Next_Arrow.png'
import Prev_Arrow from '../../../../assets/images/Prev_Arrow.png'

export const NextArrow = (props) => {
  const { customClasses, onClick, makeBlur,  blurAction } = props
  const classNames = clsx('hover:cursor-pointer', customClasses)
  return (
    <div className={`${classNames}`} onClick={() => {
      if (makeBlur) blurAction()
      onClick()
    }}>
      <img src={Next_Arrow} alt='arrow icon' className='sm:w-[40%] w-5' />
    </div>
  )
}

export const PrevArrow = (props) => {
  const { customClasses, onClick, makeBlur, blurAction } = props
  const classNames = clsx('hover:cursor-pointer', customClasses)
  return (
    <div className={`${classNames}`} onClick={()=>{
      if (makeBlur) blurAction()
      onClick()
    }}>
      <img src={Prev_Arrow} alt='arrow icon' className='sm:w-[40%] w-5' />
    </div>
  )
}

export const CustomDots = (props) => {
  const { customClasses, className, onClick, dots } = props
  const classes = clsx(className, customClasses)
  return (
    <div className={`${classes}`}>
      <ul style={{ listStyle: 'none', marginTop: 0, padding: 0, margin: 0, listStyleType: 'none' }}>
        {dots.map((dot, index) => (
          <li key={index} style={{ marginLeft: "-5px", marginRight: "-5px", padding: 0 }}>{dot}</li>
        ))}
      </ul>
    </div>
  )
}
