import { useEffect, useState } from 'react'
import editPencilBlackIcon from 'app/assets/images/edit-pencil.svg'
import Button from '@/components/atoms/buttons/Button'
import EmptySection from '@/components/atoms/widgets/EmptySection'
import Testimonials from '@/components/molecules/Testimonials/Testimonials'
import Pagination from '@mui/material/Pagination'
import { useMobileDetect } from '@/utils/detectMobile'
import { MAX_TESTIMONIALS_LIMIT } from '@/utils/constants'

type TestimonialsSectionProps = {
  isSolutionOwner: boolean
  solution: schema.Solution
  current_user: schema.User
  testimonials: schema.Testimonials[]
  isRmcAdmin: boolean
}

const TestimonialsSection: React.FC<TestimonialsSectionProps> = ({
  isSolutionOwner,
  solution,
  current_user,
  testimonials,
  isRmcAdmin,
}) => {
  const [TestimonialsData, setTestimonials] = useState(testimonials)
  const [editTestimonials, setEditTestimonials] = useState(isSolutionOwner || isRmcAdmin ? true : false)
  const [currentPage, setCurrentPage] = useState(1)
  const isMobile = useMobileDetect()
  const itemsPerPage = isMobile ? 1 : 2
  const allowEdit = isSolutionOwner || isRmcAdmin

  const handleEditClick = () => {
    setEditTestimonials(true)
  }

  const showEmptySection =
    (TestimonialsData.length === 0 && current_user && solution.paid_solution) && (isSolutionOwner || isRmcAdmin)

  const handlePageChange = (_: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page)
  }

  useEffect(() => {
    if ((currentPage - 1) * itemsPerPage >= TestimonialsData.length) {
      setCurrentPage(1)
    }
  }, [TestimonialsData, currentPage, itemsPerPage])

  const paginatedTestimonials = TestimonialsData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  )

  if ((!isSolutionOwner && !isRmcAdmin && testimonials.length == 0)) 
    {
    return null
  }

  return (
    <div className='flex flex-col mt-[2rem] sm:mt-[6rem]'>
      <div className='flex items-center justify-between'>
        <div>
          <h1 className='text-lg font-bold sm:text-xl'>
            Customer Testimonials
          </h1>
        </div>
        {allowEdit && (
          <div>
            {!editTestimonials ? (
              <div className='rounded-full bg-white p-2'>
                <img
                  src={editPencilBlackIcon}
                  alt='pencil icon'
                  onClick={handleEditClick}
                  className='w-[35px] stroke-white'
                  style={{ cursor: 'pointer' }}
                  loading='lazy'
                />
              </div>
            ) : (
              <div className='ml-auto flex gap-2'>
                <Button
                  type='button'
                  size='md'
                  variant='gray'
                  className='flex h-12 w-20 items-center justify-center rounded-[12px] px-4 py-3'
                  onClick={() => setEditTestimonials(false)}
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
      <div className='text-sm font-bold'>
        <p>
          {editTestimonials
            ? MAX_TESTIMONIALS_LIMIT - TestimonialsData.length === 0
              ? <span className='text-danger-red'> {`You have reached the limit of ${MAX_TESTIMONIALS_LIMIT}, cannot add more testimonials`}</span>
              : `You can add ${MAX_TESTIMONIALS_LIMIT - TestimonialsData.length} more testimonial${MAX_TESTIMONIALS_LIMIT - TestimonialsData.length === 1 ? '' : 's'}`
            : TestimonialsData.length === 0 ? ''
            : `${TestimonialsData.length} testimonial${TestimonialsData.length === 1 ? '' : 's'}`}
        </p>
      </div>

      {showEmptySection && !editTestimonials ? (
        <div className='mt-4'>
          <EmptySection
            heading='No Testimonials Yet'
            paragraph='Click the pencil icon to add testimonials on your profile.'
          />
        </div>
      ) : (
        <div className='flex flex-col gap-4 mt-4'>
          <Testimonials
            testimonials={paginatedTestimonials}
            editTestimonials={editTestimonials}
            solution_slug={solution.slug}
            setTestimonials={setTestimonials}
            testimonialsCount={TestimonialsData.length}
          />
          {TestimonialsData.length > 0 && (
            <Pagination
              size='large'
              count={Math.ceil(TestimonialsData.length / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default TestimonialsSection
