import React, { FC, InputHTMLAttributes, useState } from 'react'

import { ReactComponent as PlusIcon } from '../../../../assets/images/plus.svg'
import { ReactComponent as SearchIcon } from '../../../../assets/images/search-icon.svg'
import { ReactComponent as TrashIcon } from '../../../../assets/images/trash.svg'
import visibleIcon from '../../../../assets/images/visible-icon.svg'
import visibleRedIcon from '../../../../assets/images/visible-red-icon.svg'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  errors?: string
  isPassword?: boolean
  className?: string
  label?: string
  labelHtmlFor?: string
  containerClass?: string
  inputFieldClasses?: string
  trashIconClasses?: string
  searchIconClasses?: string
  search?: boolean
  upload?: boolean
  trash?: boolean
  onTrashClick?: () => void
  onUploadClick?: () => void
  labelClass?: string
}

const Input: FC<InputProps> = ({
  errors = false,
  isPassword = false,
  className = '',
  label = '',
  containerClass = '',
  inputFieldClasses = '',
  trashIconClasses = '',
  searchIconClasses = '',
  labelHtmlFor = '',
  search = false,
  children,
  upload = false,
  trash = false,
  onTrashClick,
  onUploadClick,
  labelClass = '',
  ...rest
}) => {
  const [visible, setVisible] = useState(false)

  const inputType =
    rest.type === 'password' ? (visible ? 'text' : 'password') : rest.type

  const inputClass = `border mb-[-9px] rounded-xl bg-white py-4 px-5 outline-none w-full disabled:bg-light-gray disabled:border-medium-gray disabled:text-medium focus:outline-none focus:ring-0 placeholder-medium-gray ${
    !errors
      ? 'focus:border-dark-gray text-black'
      : 'focus:border-danger-red text-danger-red'
  } ${className} ${
    errors
      ? 'border-danger-red text-danger-red'
      : rest.value
      ? 'border-dark-gray text-black'
      : 'border-medium-gray'
  } ${search && 'pl-[52px]'}`

  return (
    <div className={containerClass}>
      {label && (
        <label
          htmlFor={labelHtmlFor}
          className={`mb-3 block text-lg ${errors ? 'text-danger-red' : 'text-black'} ${labelClass}`}
        >
          {label}
        </label>
      )}
      <div className={`relative inline-block w-full ${inputFieldClasses}`}>
        <input
          {...rest}
          type={inputType}
          className={inputType === 'radio' ? 'size-[24px]' : inputClass}
        />
        {rest.type === 'password' && (
          <img
            src={errors ? visibleRedIcon : visibleIcon}
            alt='visible icon'
            className='absolute right-[17.5px] bottom-[17.5px] lg:bottom-[12px] size-6 cursor-pointer'
            onClick={() => setVisible(!visible)}
          />
        )}
        {search && (
          <SearchIcon className={`absolute left-[17.5px] bottom-[17.5px] mb-[-9px] size-6 cursor-pointer${searchIconClasses}`} />
        )}
        {upload && (
          <PlusIcon className='absolute right-[17.5px] bottom-[17.5px] mb-[-9px] size-6 cursor-pointer' onClick={onUploadClick}/>
        )}
        {trash && (
          <TrashIcon className={`absolute right-[17.5px] bottom-[17.5px] mb-[-9px] size-6 cursor-pointer${trashIconClasses}`} onClick={onTrashClick}/>
        )}
      </div>
      {children}
    </div>
  )
}

export default Input
