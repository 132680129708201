import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import Modal from '@components/atoms/modals/Modal'
import Button from '@/components/atoms/buttons/Button'
import Rating from '@mui/material/Rating'
import { useEffect } from 'react'
import Select from 'react-select'
import { CompanySizeOptions } from '@/utils/constants'
import { putRequestWithoutFormData } from '@/utils/axiosRequests'
import { useToken } from '@/components/atoms/forms/Form'
import { toast } from 'react-toastify'
import SuccessMessage from '@/components/atoms/Toast/SuccessMessage'
import { defaultToastOptions } from '@/utils/commonToast'
import ErrorMessage from '@/components/atoms/Toast/ErrorMessage'
import { MUIBlueStarRating } from '@/utils/constants/styles'
import CharacterCounter from '@/components/atoms/widgets/CharacterCounter'

type TestimonialFormModalProps = {
  openTestimonialsModal: boolean
  setOpenTestimonialsModal: React.Dispatch<React.SetStateAction<boolean>>
  formData: schema.Testimonials
  setFormData: React.Dispatch<React.SetStateAction<schema.Testimonials>>
  solution_slug: string
  setTestimonials: any
  addNewTestimonial: boolean
}

const TestimonialFormModal = ({
  openTestimonialsModal,
  setOpenTestimonialsModal,
  formData,
  setFormData,
  solution_slug,
  setTestimonials,
  addNewTestimonial,
}: TestimonialFormModalProps) => {
  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm<schema.Testimonials>({
    defaultValues: { ...formData, rating: 0 }
  })
  const token = useToken()
  const authorValue: string = watch('author') || ''
  const companyNameValue: string = watch('company_name') || ''
  const descriptionValue: string = watch('description') || ''  

  useEffect(() => {
    reset(formData)
  }, [formData, reset])

  const resetFormData = () => {
    const initialData = {
      author: '',
      company_name: '',
      company_size: 0,
      description: '',
      rating: 0,
    }
    setFormData(initialData)
    reset(initialData)
  }

  const onSubmit: SubmitHandler<schema.Testimonials> = async (data) => {
    const url = `/solutions/${solution_slug}`
    const testimonials = {
      solution: {
        testimonials_attributes: [data],
      },
    }

    try {
      const response = await putRequestWithoutFormData(
        url,
        testimonials,
        token
      )
      if (response) {
        setTestimonials(response.data.testimonials)
      } else {
        console.error('Failed to submit testimonial')
      }
    } catch (error) {
      console.error('Error submitting testimonial:', error)
    }
    resetFormData()
    setOpenTestimonialsModal(false)
  }

  const handleClose = () => {
    setOpenTestimonialsModal(false)
    resetFormData()
  }

  const handleDelete = async () => {
    const testimonials = {
      solution: {
        testimonials_attributes: [
          {
            id: formData.id,
            author: formData.author,
            company_name: formData.company_name,
            company_size: formData.company_size,
            rating: formData.rating,
            _destroy: true,
          },
        ],
      },
    }

    if (formData?.id) {
      const url = `/solutions/${solution_slug}`
      try {
        const response = await putRequestWithoutFormData(
          url,
          testimonials,
          token
        )
        resetFormData()
        if (response) {
          toast(
            <SuccessMessage message={'Testimonial deleted successfully!'} />,
            defaultToastOptions('teal-tint-2')
          )
          setTestimonials(response.data.testimonials)
        }
      } catch (error) {
        toast(
          <ErrorMessage message={'Something went wrong! Please try again.'} />,
          defaultToastOptions('red-200')
        )
      }
    }
  }

  return (
    <Modal
      isOpen={openTestimonialsModal}
      onClose={handleClose}
      closeStyle='top-5 right-4'
      className='size-auto gap-5 rounded-xl bg-white px-[38px] py-8 w-[400px] md:w-[560px]'
    >
      <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-8 md:gap-12'>
        <h1 className='text-left text-3xl font-medium sm:text-5xl'>
          {addNewTestimonial ? 'Add a new testimonial' : 'Edit testimonial'}
        </h1>
        <div className='flex flex-col gap-2 md:gap-5'>
          <div className='flex flex-col items-left justify-between'>
            <div className='flex flex-col gap-1'>
              <div className='flex flex-col md:flex-row md:justify-between items-left md:items-center gap-2 md:gap-10'>
                <label className='text-sm font-medium text-gray-700'>
                  Author
                </label>
                <input
                  {...register('author', {
                    required: 'Author is required',
                    maxLength: {
                      value: 100,
                      message:
                        'Author Name cannot be longer than 100 characters',
                    },
                  })}
                  className='border border-silver-gray rounded-md p-3 w-100 md:w-[300px]'
                  maxLength={100}
                />
              </div>
              <CharacterCounter
                currentLength={authorValue.length}
                maxLength={100}
                className='ml-auto'
              />
            </div>
            {errors.author && (
              <span className='text-red-500 text-sm mt-2'>
                {errors.author.message}
              </span>
            )}
          </div>

          <div className='flex flex-col items-left justify-between'>
            <div className='flex flex-col gap-1'>
              <div className='flex flex-col md:flex-row items-left md:justify-between md:items-center md:gap-10 gap-2'>
                <label className='text-sm font-medium text-gray-700'>
                  Company Name
                </label>
                <input
                  {...register('company_name', {
                    required: 'Company name is required',
                    maxLength: {
                      value: 100,
                      message:
                        'Company name cannot be longer than 100 characters',
                    },
                  })}
                  className='border border-silver-gray rounded-md p-3 w-100 md:w-[300px]'
                  maxLength={100}
                />
              </div>
              <CharacterCounter
                currentLength={companyNameValue.length}
                maxLength={100}
                className='ml-auto'
              />
            </div>
            {errors.company_name && (
              <span className='text-red-500 text-sm mt-2'>
                {errors.company_name.message}
              </span>
            )}
          </div>

          <div className='flex flex-col items-left justify-between'>
            <div className='flex flex-col md:flex-row md:justify-between items-left md:items-center gap-2 md-gap-10'>
              <label className='text-sm font-medium text-gray-700'>
                Company Size (Optional)
              </label>
              <Controller
                name='company_size'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    <Select
                      options={CompanySizeOptions}
                      value={CompanySizeOptions.find((c) => c.value === value)}
                      onChange={(val) => onChange(val.value)}
                      isSearchable={false}
                      placeholder='Please select'
                      className='w-100 md:w-[300px] !cursor-pointer'
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          border: '1px solid #0000003B',
                          borderRadius: '0.375rem',
                          padding: '0.5rem',
                          boxShadow: 'none',
                          '&:hover': {
                            borderColor: '#4B5563',
                          },
                        }),
                        indicatorSeparator: () => null,
                      }}
                    />
                  </>
                )}
              />
            </div>
            {errors.company_size && (
              <span className='text-red-500 text-sm mt-2'>
                {errors.company_size.message}
              </span>
            )}
          </div>
        </div>

        <div className='flex flex-col gap-6'>
          <Controller
            name='rating'
            control={control}
            rules={{
              validate: (value) => value > 0 || 'Rating is required', 
            }}
            render={({ field }) => (
              <div className='flex flex-col w-[300px]'>
                <div className='flex justify-between'>
                  <label className='font-medium text-gray-700'>
                    Testimonial
                  </label>
                  <Rating
                    {...field}
                    onChange={(_, value) => field.onChange(value)}
                    value={field.value || 0}
                    precision={1}
                    className='pl-5 md:p-0'
                    sx={MUIBlueStarRating}
                  />
                </div>
                {errors.rating && (
                  <span className='text-red-500'>{errors.rating.message}</span>
                )}
              </div>
            )}
          />

          <div className='flex flex-col gap-1'>
            <textarea
              {...register('description', {
                required: 'Description is required',
                maxLength: {
                  value: 500,
                  message: 'Description cannot be longer than 500 characters.',
                },
              })}
              className='border rounded-md p-3 w-full'
              placeholder='Description'
              rows={5}
              maxLength={500}
            />
            <CharacterCounter
              currentLength={descriptionValue.length}
              maxLength={500}
              className='ml-auto'
            />
            {errors.description && (
              <span className='text-red-500 text-sm'>
                {errors.description.message}
              </span>
            )}
          </div>
        </div>

        <div className='flex justify-center sm:justify-between'>
          <div className='flex justify-center sm:justify-left gap-3'>
            <Button type='submit' variant='teal' size='md' className='w-20'>
              Save
            </Button>
            <Button
              variant='gray'
              size='md'
              className='w-20'
              onClick={() => handleClose()}
            >
              Cancel
            </Button>
          </div>
          <div>
            {!addNewTestimonial && (
              <Button variant='white' size='md' onClick={handleDelete}>
                <span className='text-danger-red text-lg'>Delete</span>
              </Button>
            )}
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default TestimonialFormModal
