import Cliquify from 'app/assets/images/betaPartners/beta-Cliquify-logo.webp'
import Cronofy from 'app/assets/images/betaPartners/beta-Cronofy.webp'
import Dalia from 'app/assets/images/betaPartners/beta-Dalia.webp'
import Fairygodbosss from 'app/assets/images/betaPartners/beta-Fairygodboss.webp'
import inlpoi from 'app/assets/images/betaPartners/beta-inploi.webp'
import Jamyr from 'app/assets/images/betaPartners/beta-Jamyr.webp'
import Jobsync from 'app/assets/images/betaPartners/beta-JobSync.webp'
import Nordcard from 'app/assets/images/betaPartners/beta-Nordcards.webp'
import Onwardsearch from 'app/assets/images/betaPartners/beta-Onward-Search.webp'
import Powertofly from 'app/assets/images/betaPartners/beta-Power-to-fly.webp'
import Recruitics from 'app/assets/images/betaPartners/beta-Recruitics.webp'
import Survale from 'app/assets/images/betaPartners/beta-Survale.webp'
import Vibo from 'app/assets/images/betaPartners/beta-Vibo.webp'
import Vprople from 'app/assets/images/betaPartners/beta-vprople.webp'
import Marquee from 'react-fast-marquee'

const images = [
  Cliquify,
  Cronofy,
  Dalia,
  Fairygodbosss,
  inlpoi,
  Jamyr,
  Jobsync,
  Nordcard,
  Onwardsearch,
  Powertofly,
  Recruitics,
  Survale,
  Vibo,
  Vprople,
]

const BetaPartners = () => {
  return (
    <Marquee pauseOnHover={false} autoFill={true}>
      {images.map((img, index) => (
          <img
            key={index}
            src={img} 
            alt='beta partners logo' 
            className='w-[150px] px-2 sm:w-[230px] md:px-4 lg:px-6' 
            />
      ))}
    </Marquee>
  )
}

export default BetaPartners
