import React from 'react'

import { Link } from '@inertiajs/react'

type HybridLinkProps = {
  href: string
  isExternal?: boolean
  children: React.ReactNode
  className?: string
}

export default function HybridLink({
  href,
  isExternal = true,
  children,
  className = '',
}: HybridLinkProps) {
  if (isExternal) {
    return (
      <a href={href} className={className} target='_blank' rel='noopener noreferrer nofollow'>
        {children}
      </a>
    )
  }

  return (
    <Link href={href} className={className}>
      {children}
    </Link>
  )
}
