import React from 'react'

const Terms = () => {
  return (
    <div>
      <h1 className='mb-6 text-5xl font-bold'>Terms of Use</h1>
      <div className='terms-content'>
        <p>Updated version: 4.5.2023</p>
        <p>
          The Community Website Terms of Use (“
          <span className='underline'>Terms of Use</span>”) are defining the
          conditions under which you may access and use the Community Website
          and all the functionalities provided (hereinafter designated together
          the “<span className='underline'>Service</span>”).
        </p>
        <p>
          Please review these Terms of Service carefully prior to using the
          Service. If you do not agree with these Terms of Use, please do not
          use the Service.
        </p>
        <h2 className='terms-section-header'>
          1.<span> Legal notice</span>
        </h2>
        <p>The Service is provided by RecruitmentMarketing.com:</p>
        <p>
          RecruitmentMarketing.com, Registered office: 230 East Avenue, Norwalk,
          CT, 06855 Publication Director: [XXX]
        </p>
        <p>Registered in the Norwalk Trade and Companies Register</p>
        <p>E-mail: privacy@recruitmentmarketing.com</p>
        <p>Capital: [XXX] €</p>
        <p>
          Registered in the [City] Trade and Companies Register under the
          number: [XXX]
        </p>
        <p>Publication Director: [XXX]</p>
        <p className='underline'>Hosting</p>
        <p className='underline'>Google Cloud Platform</p>
        <p>Company located at Gordon House, 4 Barrow St, Dublin, Ireland</p>
        <p>
          Registered in the Dublin Trade and Companies Register under the
          number: 752925594
        </p>
        <p>Phone number: +353 1 436 1000</p>
        <h2 className='terms-section-header'>
          2.<span> Definitions</span>
        </h2>
        <p>
          <span className='font-bold'>Community Website: </span>the online
          platform named Recruitment Marketing Community, edited by Hivebrite
          and provided by Recruitmentmarketing.com, allowing User to benefit
          from the Service.
        </p>
        <p>
          <span className='font-bold'>Data Protection Law: </span>means [Option
          1 for customers submitted to EU data protection regulations: the
          Regulation (EU) 2016/679 of the European Parliament and of the Council
          of 27 April 2016 on the protection of natural persons with regard to
          the processing of personal data and on the free movement of such data
          (General Data Protection Regulation) and &lt;insert national data
          protection law&gt; / Option 2: for customers not submitted to EU data
          protection regulation: XXX]
        </p>

        <p>
          <span className='font-bold'>User Account: </span>means a dedicated
          online account opened by any visitor and providing an access to the
          Service.
        </p>

        <p>
          <span className='font-bold'>Identifiers: </span>means login and
          password or social login the User used to create the User Account.
        </p>

        <p>
          <span className='font-bold'>Party or Parties: </span>means the User
          and Recruitmentmarketing.com designated either individually or
          collectively.
        </p>

        <p>
          <span className='font-bold'>Service: </span>means the Community
          Website and all the functionalities provided thereto and described in
          the Terms and Conditions.
        </p>

        <p>
          <span className='font-bold'>Service Content: </span>any data, text,
          files, information, usernames, images, graphics, photos, profiles,
          audio and video clips, sounds, works of authorship, applications,
          links, and other content or materials made available during the
          Service.
        </p>

        <p>
          <span className='font-bold'>User or Users: </span>means any person
          authorized by Recruitmentmarketing.com to access and use of the
          Service after creation of a User Account.
        </p>

        <p>
          <span className='font-bold'>User Content: </span>any content created,
          posted, or shared by the User, on or through the Service including any
          information, data, photos and other content relating to the User.
        </p>
        <h2 className='terms-section-header'>3. Term</h2>
        <p>
          The Terms of Service will come into effect on first access to the
          Service for the entire duration of use.{' '}
        </p>
        <h2 className='terms-section-header'>4. Description of the Service</h2>
        <p>
          The Service enables Users to connect with each other, build
          communities, or also do networking. The Service comprises a suite of
          online services provided by Recruitmentmarketing.com, allowing the
          User to:
        </p>
        <ul className='terms-list-container'>
          <li>
            <span className='font-bold'>Connect</span> with people in the
            recruitment marketing or talent acquisition industry, groups,
            businesses the User is interested in:
          </li>
          <ul>
            <li className='bullet-2'>
              <span className='font-bold'>Live feed:</span> allows Users to have
              access to a newsfeed aggregating contents posted via the
              application. They can comment on the posts.
            </li>
            <li className='bullet-2'>
              <span className='font-bold'>Recommend:</span> allows Users to
              suggest someone to be invited by the admin.
            </li>
            <li className='bullet-2'>
              <span className='font-bold'>Geolocation:</span> allows Users to be
              located via their device in order to know who is near them.
            </li>
          </ul>
          <li>
            Communicate with others by sharing content such as status, photos,
            videos, sending private messages, as well as creating events,
            groups, or projects.
          </li>
          <ul>
            <li className='bullet-2'>
              <span className='font-bold'>Directory:</span> provide with the
              contact details of other Users.
            </li>
            <li className='bullet-2'>
              <span className='font-bold'>Private messages:</span> allow Users
              to discuss by private messages.
            </li>
            <li className='bullet-2'>
              <span className='font-bold'>Projects:</span> allows Users to
              create a project.
            </li>
            <li className='bullet-2'>
              <span className='font-bold'>Groups:</span> Users can be part of
              groups with all the features of the application.
            </li>
            <li className='bullet-2'>
              <span className='font-bold'>Event organization:</span> allows
              Users to create events.
            </li>
          </ul>
          <li>
            Ensure the safety and integrity of the Community Website with a
            reporting tool allowing the Users to signal contents and other
            Users.
          </li>
          <li>
            Participate in <span className='font-bold'>fundraising events</span>{' '}
            organized by Recruitmentmarketing.com;
          </li>
          <li>
            Share job offers or apply to job offers published in the Community
            Website;
          </li>
          <li>
            Stay up to date with news about your industry and activities in the
            Community Website:
          </li>
          <ul>
            <li className='bullet-2'>
              <span className='font-bold'>Email campaign:</span> the User can
              receive emails campaigns from Recruitmentmarketing.com
            </li>
            <li className='bullet-2'>
              <span className='font-bold'>Notification:</span> Users that have
              agreed can receive notifications sent by Recruitmentmarketing.com.
            </li>
            <li className='bullet-2'>
              <span className='font-bold'>Media center:</span> Users can upload
              files that are stored in the Community Website.
            </li>
          </ul>
        </ul>
        <p className='mt-4'>
          These Terms of Service govern the use of each component of the
          Service.
        </p>
        <h2 className='terms-section-header'>5. Access to the Service</h2>
        <p>
          Access to the Service shall be subject to the creation of a User
          Account.
        </p>
        <p>
          The Service is available on the website
          community.recruitmentmarketing.com
        </p>
        <div className='ml-4'>
          <h2 className='terms-section-subheader'>
            5.1. Requirements for the access to the Service
          </h2>
          <p>The User must ensure to has (the “User's System”):</p>
          <ul className='terms-list-container'>
            <li>
              The ability and means required to access the Services and to use
              it;
            </li>
            <li>
              The appropriate hardware (computer, smartphone...) and operating
              software and shall carry out the responsibility of the maintenance
              and the security;{' '}
            </li>
            <li>
              An Internet access from any access provider of the User's choice;
            </li>
            <li>An adapted configuration of the browser.</li>
          </ul>
          <p className='mt-4'>
            Users shall bear all the costs for accessing the Service.
          </p>
          <h2 className='terms-section-subheader'>
            5.2. Creation of the User Account
          </h2>
          <p>
            This shall require the entering of personal identification data
            through the online registration form accessible at the following
            address:
          </p>
          <a
            target='_blank'
            rel='noreferrer'
            className='terms-link whitespace-pre-wrap break-words'
            href='https://marketplace.recruitmentmarketing.com/users/sign_up'
          >
            https://marketplace.recruitmentmarketing.com/users/sign_up
          </a>
          <p className='mt-4'>
            The User can create a User Account by connecting through their SSO
            account.
          </p>
          <p>
            The User agrees to provide only accurate and complete information
            when creating or updating their Account information.
          </p>
          <h2 className='terms-section-subheader'>5.3. Access modalities</h2>
          <p>
            In order to benefit from the Services, the User must log-in and with
            the SSO account (email and password) the User used to create the
            User Account. Identifiers are personal and confidential. Users are
            responsible for keeping it secret and not disclose it to any third
            party in any form and for any reason whatsoever.
          </p>
          <p>
            The User's login can only be changed at the request of the User or
            at the initiative of Recruitmentmarketing.com. The User's password
            is modifiable by the User through the User's Account. The User is
            solely and entirely responsible for the use of the User's
            Identifiers and undertakes to do everything possible to keep it
            secret and not to disclose them to anyone, in any form whatsoever
            and for any reason whatsoever. If the User forgets their password,
            the User can generate a new one by clicking on the link “Reset my
            password”.
          </p>
          <p>
            The User is solely responsible for the activity that occurs on its
            User Account and will be responsible to keep its Identifiers secure
            and for the use of his Identifiers by third-parties or for any
            actions or declarations made through his User Account, whether
            fraudulent or not, and guarantees Recruitmentmarketing.com against
            any request in this respect.
          </p>
          <p>
            If the User has reason to believe that a person is fraudulently
            using identification elements or their User Account, they must
            immediately inform Recruitmentmarketing.com by e-mail at the
            following address community@recruitmentmarketing.com.
          </p>
          <p>
            The User is solely responsible for the improper access, damage,
            disruption, loss or theft and adverse consequences caused by its
            breach of the confidentiality of the Identifiers. In case of loss,
            misappropriation or unauthorized use of its password, the User shall
            immediately notify Recruitmentmarketing.com.
          </p>
          <p>
            Users may not use another's account without permission. A User
            Account may not be shared, transferred, or sold to other parties,
            nor are they to be used by or made available to any person or entity
            except the registered user.
          </p>
        </div>
        <h2 className='terms-section-header'>6. Cost of the Service</h2>
        <p>
          The Service is available for free of charge (excluding any connection
          costs, whose price depends on your electronic communication service
          provider).
        </p>
        <h2 className='terms-section-header'>7. Intellectual Property</h2>
        <div className='ml-4'>
          <h2 className='terms-section-subheader'>7.1. Company's ownership</h2>
          <p>
            The Service and the Service Content belong and shall remain the
            property of Recruitmentmarketing.com or its licensors as
            appropriate, and is protected by copyright, patent when applicable,
            trade secret and other intellectual property laws.
          </p>
          <p>
            The User shall not acquire any of such rights title and interest
            (including, without limitation, all intellectual property rights
            such as copies, modifications, extensions and derivative works
            thereof) save as otherwise expressly set out in this Article 7.2 and
            the User shall take all necessary measures to this effect. All
            rights not expressly granted to Users are reserved and retained.
          </p>
          <p>
            Any reproduction, representation, distribution, modification,
            adaptation or translation of these various Service Content, in whole
            or in part, in any form and current or future medium is strictly
            prohibited, except within the limit of the Terms of Service or with
            prior express authorization of Recruitmentmarketing.com. Any use of
            these Service Content not expressly authorized by
            Recruitmentmarketing.com shall constitute a violation of the Terms
            of Use and a violation of intellectual property rights.
          </p>
          <p>
            Except as specifically permitted by these Terms of Use, User may not
            use or register any name, logo, brand or insignia of
            Recruitmentmarketing.com or any of its schools or subdivisions for
            any purpose except with Recruitmentmarketing.com prior written
            approval and in accordance with any restrictions required by
            Recruitmentmarketing.com. These are the property of
            Recruitmentmarketing.com or are subject to rights to use, duplicate
            or represent/communicate to the public that have been granted to
            such.
          </p>
          <p>
            Recruitmentmarketing.com is the producer of databases on the
            Community Website and Recruitmentmarketing.comis the owner of any
            databases made available. The User shall not extract or reuse a
            substantial qualitative or quantitative portion of the databases,
            including for private purpose.
          </p>
          <h2 className='terms-section-subheader'>7.2. User's right of use</h2>
          <p>
            Recruitmentmarketing.com grants the User a limited, non-exclusive,
            non-transferable, and revocable license to use the Service for
            User's personal, non-commercial purposes. The User may only use the
            Community Website on devices that User owns or controls. User may
            use the Service and the Service Content only in the manner and for
            the purposes specified in these Terms of Use. Except as specifically
            permitted by these Terms of Use, User may not modify, transmit,
            produce, distribute, display, sell, license, broadcast, or otherwise
            make available in any form or by any means any part of the Service
            or the Service Content without the express written permission of
            Recruitmentmarketing.com.
          </p>
          <p>
            In connection with the use of the Services and Service Content or
            User's Content, the User is not authorized to, or cannot allow a
            third party to:
          </p>
          <ul className='terms-list-container'>
            <li>
              violate any applicable laws, rules or regulations, notably cause
              damage or injury to any person or property, infringes any rights
              of third parties including but not limited to any intellectual
              property rights and privacy rights or perform any other illegal,
              harmful or fraudulent activities;
            </li>
            <li>
              probe, scan, or test the vulnerability of any system or network;
            </li>
            <li>
              remove, circumvent, disable, damage or otherwise interfere with
              any security measures of any network, computer or communications
              system, software application, or network or computing device;
            </li>
            <li>
              attempts to connect without permission and/or damage, in any way
              whatsoever, any users, hosts or networks;
            </li>
            <li>
              disturb, hamper, interfere or interrupt the access to or operation
              of the Service and Service Content including by transmitting any
              worms, viruses, spyware, malware or any other code of a
              destructive or disruptive nature.
            </li>
          </ul>
        </div>
        <h2 className='terms-section-header'>8. User's undertakings</h2>
        <p>
          The User represents and warrants that she or he retains ownership of
          all of his intellectual property rights in the User Content or that
          this User Content is subject to a license granted to the User by a
          third party holding these rights, under conditions allowing the User
          to freely use and/or transfer these elements for the purpose of using
          the Service, under the conditions described in the Terms and
          Conditions. Recruitmentmarketing.com does not claim ownership of the
          User Content.{' '}
        </p>
        <p>
          The User holds Recruitmentmarketing.com harmless from all claims or
          actions brought by third parties on the grounds of an infringement of
          their rights, including, without limitation, publicity rights,
          copyrights, trademark and/or other intellectual property rights or
          privacy rights in the User Content.
        </p>
        <p>
          By using the Service, the User represents and warrants that she or he
          has the legal right and capacity to enter into these Terms of Use in
          the User's jurisdiction.
        </p>
        <p>
          Persons aged 13 years old or less are not authorized to access the
          Service.
        </p>
        <h2 className='terms-section-header'>9. User's rules of conduct </h2>
        <p>
          Generally, the User is committed to use the Services in accordance
          with Code of Conduct made available to the User here:{' '}
        </p>
        <p>
          <a
            target='_blank'
            rel='noreferrer'
            className='terms-link whitespace-pre-wrap break-words'
            href='https://community.recruitmentmarketing.com/page/registration-requirements-14e771c4-4cfd-452e-9e20-a6d8b668570c'
          >
            https://community.recruitmentmarketing.com/page/registration-requirements-14e771c4-4cfd-452e-9e20-a6d8b668570c
          </a>
        </p>
        <p>
          User may not post content which includes, but is not limited to: (i)
          sexually explicit materials; (ii) obscene, misleading, libelous,
          slanderous, violent, hateful and/or unlawful content or profanity;
          (iii) content that infringes upon the rights of any third party,
          including copyright, trademark, privacy, publicity or other personal
          or proprietary right, or that is deceptive or fraudulent; (iv) content
          that promotes the use or sale of illegal or regulated substances,
          tobacco products, ammunition and/or firearms; and (v) gambling,
          including without limitation, any online casino, sports books, bingo
          or poker (“Objectionable Content”)via the Service.{' '}
        </p>
        <p>
          User may not defame, stalk, bully, abuse, harass, threaten,
          impersonate or intimidate people or entities and must not post private
          or confidential information via the Service, including, without
          limitation, the User or any other person's credit card information,
          social security or alternate national identity numbers, non-public
          phone numbers or non-public email addresses.{' '}
        </p>
        <p>
          User must not create accounts with the Service through unauthorized
          means, including but not limited to, by using an automated device,
          script, bot, spider, crawler or scraper.{' '}
        </p>
        <p>
          User must not attempt to restrict another User from using or enjoying
          the Service and must not encourage or facilitate violations of these
          Terms of Service.{' '}
        </p>
        <p>
          User may only make non-commercial uses of the Service and Service
          Content. Use of Service or the Service Content for any commercial,
          public or political purpose is strictly prohibited. Prohibited
          activities include, but are not limited to: (1) activities that are
          illegal or fraudulent; (2) use that inaccurately implies endorsement,
          approval, or sponsorship by Recruitmentmarketing.com(or any individual
          officer or employee of Recruitmentmarketing.com); (3) use that can be
          confused with official communications of Recruitmentmarketing.com or
          its officers or employees; and (4) print or electronic mass mailings
          (sometimes known as "spamming"), solicitations for commercial
          services.
        </p>
        <h2 className='terms-section-header'>10. Reporting and moderation</h2>
        <p>
          In order to ensure the Community Website provides the best experience
          possible for everyone, if a User sees any inappropriate content, she
          or he must use the “Report as offensive” feature found under each
          post.
        </p>
        <p>
          Recruitmentmarketing.com has no general obligation to monitor User
          Content nor to investigate whether facts or circumstances are
          revealing illicit activities. Recruitmentmarketing.com has set up a
          reporting tool allowing any User to notify illegal, abusive and
          harmful content.
        </p>
        <p>
          The knowledge of such content is deemed effective when the hosting
          provider is notified of following:
        </p>
        <ul className='terms-list-container'>
          <li>The notification date;</li>
          <li>The notifying User identity;</li>
          <li>
            The description of the facts in contention (possibly with screen
            shots of the content);
          </li>
          <li>As well as the reasons for which the content must be removed.</li>
        </ul>
        <p className='mt-4'>
          If a User Content is reported, Recruitmentmarketing.com reserves the
          right to withdraw the reported content and/or suspend or terminate the
          User Account of the User having originally published the reported
          content in the conditions set forth in Article 11.2.{' '}
        </p>
        <h2 className='terms-section-header'>
          11. Termination, suspension, or revocation of access
        </h2>
        <div className='ml-4'>
          <h2 className='terms-section-subheader'>
            11.1 Deletion of the User Account by the User
          </h2>
          <p>
            At any time, the User may delete their User Account without notice
            through the Community Website by deleting their Profile or by
            contacting Recruitmentmarketing.com at the address or email address
            mentioned in Article 15.
          </p>
          <p>
            Deletion is effective immediately and all the data collected upon
            the subscription as well as the content published by the User on the
            Community Website is deleted after a period of twelve (12) months.{' '}
          </p>
          <h2 className='terms-section-subheader'>
            11.2 Suspension or termination of the User Account by
            Recruitmentmarketing.com in case of breach of the Terms of Use
          </h2>
          <p>
            Recruitmentmarketing.com retains the right to suspend User's access
            to the Service to make all investigations necessary following a
            report made pursuant to Article 10 or in case of presumed breach of
            Terms of Use which may be remediated. The User whose User Account is
            suspended will be informed by Recruitmentmarketing.com of such a
            decision by email. The User has the possibility send an email to
            Recruitmentmarketing.com to obtain additional information on the
            reasons for the suspension as well as to provide explanations and/or
            any element in order to demonstrate that the breach has been
            remediated. If the violation of Terms of Use is not remedied within
            one (1) month from suspension notification,
            Recruitmentmarketing.comreserves the right to terminate the
            applicable User's Account and right to use the Service, in addition
            to any other rights or remedies available.
          </p>
          <h2 className='terms-section-subheader'>
            11.3 Deletion of inactive User Account
          </h2>
          <p>
            Recruitmentmarketing.com will delete inactive accounts after a
            continuous period of two (2) years the User has not used the
            Community Website. The User will be informed by email of the
            deletion of his or her account subject to prior notice of two
            (2)weeks allowing the User to object such deletion.
          </p>
        </div>
        <h2 className='terms-section-header'>12. Privacy; Confidentiality</h2>
        <p>
          By using the Service, User's personal data, in the meaning of Data
          Protection Law, may be collected and processed by
          Recruitmentmarketing.com, in compliance with the
          Recruitmentmarketing.comPrivacy Policy and the Cookie Policy,
          (collectively, “<span className='underline'>Privacy Policies</span>”).{' '}
        </p>
        <p>
          Recruitmentmarketing.com will treat User Content as confidential
          information in accordance in accordance with these Terms of Use and
          the Privacy Policies.{' '}
        </p>
        <h2 className='terms-section-header'>
          13. Limitation of Warranty and Liability; Indemnification.
        </h2>
        <p>
          Recruitmentmarketing.com is not responsible for the User's device
          compatibility nor if the User is unable to connect due to any
          technical problem related to the Internet network or a failure in the
          User's computer facilities and equipment.{' '}
        </p>
        <p>
          Recruitmentmarketing.com shall not be responsible for User's System
          nor any problems arising from or related to User's System or caused by
          the Internet. User is responsible for the security of the User System
          and the security of its access to and connection with the Services.
        </p>
        <p>
          Insofar as it has not been reported in the conditions of Article 10,
          any document, information, data, or elements available via the
          Service, including User Content (“Information”) is provided without
          any guarantees, notably that the Information is comprehensive or
          accurate.{' '}
        </p>
        <p>
          The User is solely responsible for the use of the Information and
          bears all the risks arising from your reliance on this Information.
          The Information is provided on the condition that the User can
          determine its interest for a specific purpose before use. Under no
          circumstances Recruitmentmarketing.com accepts liability for injury
          arising from reliance on the said Information, its use or use of a
          product to which it refers.
        </p>
        <p>
          The User is solely responsible for all User Content posted, and
          assumes all risks relating to its use by others and others' reliance
          on its accuracy. The User acknowledges that, following the publication
          of the any content, its information will become accessible to all the
          Users on the Community Website and that as such, same information will
          be published, modified, translated, reproduced in any form and
          accessible, saved and reproduced by other Users and
          Recruitmentmarketing.com. Recruitmentmarketing.com will have no
          responsibility or liability in connection with the User Content.
        </p>
        <p>
          Recruitmentmarketing.com expressly excludes any liability for events
          of any kind that may occur during any meeting organized between Users
          within the use of the Services.
        </p>
        <h2 className='terms-section-header'>14. Hypertext links</h2>
        <p>
          The Community Website may provide links to websites operated by third
          parties or other internet sources/networks, as well as
          data/information coming from third-party servers.
          Recruitmentmarketing.com does not endorse or accept responsibility for
          the content or the use of third-party websites and cannot be held
          liable for any damage following or in relation with use or the fact of
          having trusted the content/data/results available on such third-party
          servers, external sources or internet sites.{' '}
        </p>
        <p>
          If a User uses third-party sites or content from third-party servers,
          User does so solely at User's own risk. Links do not imply that the
          Company is affiliated or associated with any linked site.{' '}
        </p>
        <h2 className='terms-section-header'>15. Contact</h2>
        <p>
          For any questions regarding the Terms of Use, especially use of the
          Service or any claim, please email us at
          community@recruitmentmarketing.com or post it at the following
          address:{' '}
        </p>
        <p>
          The User can use these contact details for any technical support
          request.
        </p>
        <h2 className='terms-section-header'>16. Miscellaneous </h2>
        <p>
          <span className='underline'>Governing Law and jurisdiction.</span>{' '}
          These Terms of Use, will be governed by the laws of Connecticut,
          U.S.A. without regard to its conflicts of laws principles.
        </p>
        <p>
          The User is informed, as consumer, of the possibility to use a
          conventional mediation procedure or any other alternative dispute
          resolution method. In particular, the User may refer the matter to the
          Consumer Ombudsman.
        </p>
        <p>
          The User can submit a dispute to the Online Dispute Resolution
          platform proposed by the European Commission, which may be accessed at
          the following address: http://ec.europa.eu/consumers/odr/.
        </p>
        <p>
          <span className='underline'>Change of Terms of Use.</span> These Terms
          of Use are subject to change from time to time. Any changes will be
          posted to this page. Use of this Service or any Service Content after
          entering into force the new version of the Terms of Use will
          constitute the agreement to the modified Terms of Use. If the User
          disagrees with such modification of the Terms of Use, the User may
          terminate the Service and delete its User Account under the conditions
          set forth in Article 11.
        </p>
        <p>
          <span className='underline'>Change of the Service.</span>{' '}
          Recruitmentmarketing.com may further develop or modify the Service or
          the Service Content only to improve its quality. The User will be
          informed of such development/modification as soon as it is
          implemented.
        </p>
        <p>
          <span className='underline'>Severability; Entire Agreement.</span>{' '}
          Except in the case of invalidity of an essential clause, if any
          provision of these Terms of Use is held to be invalid or
          unenforceable, that provision, to the extent unenforceable, shall be
          struck, and shall not affect the validity or enforceability of the
          remaining provisions. These Terms of Use, including the Privacy
          Policies, set forth the entire understanding and agreement between the
          User and Recruitmentmarketing.com with respect to the subject matter
          hereof.
        </p>
        <p>
          <span className='underline'>Convention of proof.</span> Electronic
          documents exchanged by electronic means between the User and the
          Recruitmentmarketing.com shall be regarded as writings having the same
          probative force as writings on paper in the meaning of applicable law.
          They may constitute faithful and durable copies in accordance with
          applicable law, so that the User is invited to keep them.
        </p>
        <p>
          <span className='underline'>Force majeure.</span> In no case
          Recruitmentmarketing.com may be held liable for any breach of its
          contractual obligations resulting from a force majeure event as
          defined in applicable law and related jurisprudence.
        </p>
      </div>
    </div>
  )
}

export default Terms
