const Checkbox = ({
  isChecked,
  setIsChecked,
  promptMessage,
}) => {
  return (
    <div className='flex w-full items-center gap-2'>
      <input
        type='checkbox'
        checked={isChecked}
        onChange={(e) => {
          setIsChecked(e.target.checked)
        }}
      />
      <p className='text-xs text-gray-400'>{promptMessage}</p>
    </div>
  )
}

export default Checkbox
