import HybridLink from '@/components/atoms/widgets/HybridLink';
import React from 'react';
import { Helmet } from 'react-helmet';
import window from 'global'

const Breadcrumbs = ({ breadcrumbs, solution }) => {
  const baseUrl = window.location.origin

  const breadcrumbItems = breadcrumbs.map((breadcrumb, index) => ({
    '@type': 'ListItem',
    position: index + 1,
    name: breadcrumb.name,
    item: `${baseUrl}${breadcrumb.url}`,
    id: `${baseUrl}${breadcrumb.url}`
  }));

  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumbItems,
  };

  return (
    <>
      <Helmet>
        <script type='application/ld+json'>
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <div
        style={{ backgroundColor: 'rgba(217, 217, 217, 0.5)'} }
        className='py-[2px] px-4 rounded-full sm:w-min sm:max-w-auto max-w-min overflow-x-scroll whitespace-nowrap scrollbar-none'
      >
        {
          breadcrumbs.map((breadcrumb, index) => {
            return (
            <HybridLink
              className='text-[13px]'
              href={breadcrumb.url}
              isExternal={false}
              key={index}
            >
              {breadcrumb.name} &gt;{' '}
            </HybridLink>
            )
          })
        }
        <span className='text-[13px] text-black opacity-[50%]'>
          {' '}
          {solution.name}{' '}
        </span>
      </div>
    </>
  );
};

export default Breadcrumbs;
