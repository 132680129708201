import React, { useEffect } from 'react'
import { Rating } from '@mui/material'
import editPencilBlackIcon from 'app/assets/images/edit-pencil.svg'
import { MUIBlueStarRating } from '@/utils/constants/styles'

type TestimonialCardlProps = {
  editTestimonials: boolean
  openTestimonialsModal: boolean
  setOpenTestimonialsModal: React.Dispatch<React.SetStateAction<boolean>>
  testimonialData: schema.Testimonials
  setTestimonialData: React.Dispatch<React.SetStateAction<schema.Testimonials>>
  setAddNewTestimonial: React.Dispatch<React.SetStateAction<boolean>>
}

const TestimonialCard: React.FC<TestimonialCardlProps> = ({
  editTestimonials,
  openTestimonialsModal,
  setOpenTestimonialsModal,
  testimonialData,
  setTestimonialData,
  setAddNewTestimonial
}) => {
  
  return (
    <div className='flex flex-col border border-gray-border rounded-lg p-4 md:w-[50%] h-[] md:h-[475px] lg:h-[380px] xl:h-[336px] items-left justify-left gap-4'>
      <div className='flex justify-between items-center'>
        <Rating value={testimonialData.rating} readOnly sx={MUIBlueStarRating}/>
        {editTestimonials && (
          <img
            src={editPencilBlackIcon}
            alt='pencil icon'
            onClick={() => {
              setOpenTestimonialsModal(!openTestimonialsModal)
              setTestimonialData(testimonialData)
              setAddNewTestimonial(false)
            }}
            className='w-[35px] stroke-white cursor-pointer size-[35px]'
            loading='lazy'
          />
        )}
      </div>
      <p className='font-normal text-base break-words max-h-50 overflow-y-auto scrollbar-hide'>
        {String(testimonialData.description)}
      </p>
      <div className='flex justify-left items-center gap-4'>
        <div className='flex flex-col gap-1'>
          <p className='font-bold text-sm text-left'>
            <span>{testimonialData.author}, </span> <span>{testimonialData.company_name}</span>
          </p>
          {testimonialData.company_size.trim() && <p className='font-normal text-xs '>({testimonialData.company_size}) employees</p>}
        </div>
      </div>
    </div>
  )
}

export default TestimonialCard
