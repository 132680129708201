import Meta from '@/components/atoms/widgets/Meta'
import PageTitle from '@/components/atoms/widgets/PageTitle'
import FooterSection from '@/components/organisms/footerSection/FooterSection'
import Navbar from '@/components/organisms/layouts/Navbar'
import { Popover } from '@headlessui/react'
import HomepageSearch from '../Homepage/HomepageSearch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGhost } from '@fortawesome/free-solid-svg-icons'
import { ErrorPageCards } from '@/utils/constants'
import ErrorCard from '@/components/atoms/Error/ErrorCard'
import NavbarSearch from '@/components/molecules/navbar/NavbarSearch'

const NotFound = () => {
  return (
    <>
      <PageTitle title='Recruitment Marketing | Your Talent Acquisition Destination' />
      <Meta />
      <Popover className='flex flex-col z-50'>
        {({ open }) => <Navbar open={open} />}
      </Popover>
      <div className='flex flex-col justify-center items-center bg-[linear-gradient(170.84deg,#3FC8A9_4.13%,#2679A5_110.72%)] sm:py-[7rem] py-[2rem]'>
        <div className='px-4'>
          <div className='flex flex-row font-manrope justify-center items-center gap-8 mt-6'>
            <h1 className='text-white text-[96px] font-bold'>404</h1>
            <FontAwesomeIcon
              icon={faGhost}
              className='text-white w-[56px] h-[75px]'
            />
          </div>
          <div className='flex flex-col text-center gap-4'>
            <p className='text-[24px] font-bold text-white'>
              Oopsies! Looks like we've been ghosted!
            </p>
            <p className='text-[14px] font-normal text-white'>
              You definitely shouldn't be here. We're not sure what went wrong,
              but we've decided to go in a different direction.{' '}
            </p>
            <p className='text-[14px] font-normal text-white'>
              Let's move forward with an experience that's more relevant:
            </p>
          </div>
          <div className='flex flex-col lg:flex-row gap-3 mt-4 w-full justify-center items-center'>
            {ErrorPageCards.map((card, index) => (
              <ErrorCard link={card.link} title={card.title} description={card.description} image={card.image} key={index}/>
            ))}
          </div>
          <div className='mt-6'>
            <NavbarSearch errorPage={true}/>
          </div>
          <p className='text-center text-[14px] font-normal text-white mt-6'>
            Pro Tip: Stay persistent and positive - your success is just around
            the corner! Onward and Upward!
          </p>
        </div>
      </div>
      <FooterSection />
    </>
  )
}

export default NotFound
