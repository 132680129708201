import { useState, useRef } from 'react'
import AvatarEditor from 'react-avatar-editor'

type ImageCropComponentProps = {
  src: string
  setCroppedFile: (file: File) => void
  selectedInput: 'desktop' | 'mobile'
}

const ImageCropComponent = ({ src, setCroppedFile, selectedInput }: ImageCropComponentProps) => {
  const [scale, setScale] = useState(1)
  const [img, setImg] = useState<string | null>(null)
  const editorRef = useRef<AvatarEditor | null>(null)

  const SaveCrop = () => {
    if (editorRef.current) {
      setImg(editorRef.current.getImageScaledToCanvas().toDataURL())
      handleCroppedImage(editorRef.current.getImageScaledToCanvas())
    }
  }

  const handleCroppedImage = (canvas: HTMLCanvasElement) => {
    canvas.toBlob((blob) => {
      if (!blob) {
        return
      }
      const file = new File([blob], 'croppedImage.png', { type: 'image/png' })
      setCroppedFile(file)
    }, 'image/png', 1)
  }

  const handleScaleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newScale = parseFloat(e.target.value)
    setScale(newScale)
  }

  return (
    <div>
      <AvatarEditor
        ref={editorRef}
        image={src}
        height={selectedInput === 'desktop' ? 160 : 204}
        width={selectedInput === 'desktop' ? 1200 : 540}
        border={[0, 40]}
        color={[0, 0, 0, 0.5]}
        scale={scale}
        rotate={0}
        style={{ width: '100%' }}
        className='mr-[20px] bg-contain bg-center'
        onImageChange={SaveCrop}
        onImageReady={SaveCrop}
        borderRadius={0}
      />
       <div className='mt-4'>
        <label htmlFor='scale' className='text-md font-semibold text-gray-500'>
          Zoom:
        </label>
        <input
          id='scale'
          type='range'
          min='1'
          max='3'
          step='0.01'
          value={scale}
          onChange={handleScaleChange}
          className='ml-2'
        />
      </div>
    </div>
  )
}

export default ImageCropComponent
