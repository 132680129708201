import Number1 from 'app/assets/images/1.png'
import Number2 from 'app/assets/images/2.png'
import Number3 from 'app/assets/images/3.png'
import {
  faComments,
  faHouse,
  faMagnifyingGlassArrowRight,
} from '@fortawesome/free-solid-svg-icons'

export const TIMEOUT_INTERVAL = 1000
export const ALLOWED_IMAGE_TYPES = ['image/png', 'image/jpeg', 'image/jpg']
export const MLP_PROMO_LINK = 'https://members.talivity.com/starter'
export const RATING_FILTER_UPPER_LIMIT = 4
export const MOBILE_SCREEN_SIZE = 640
export const SCREENSHOT_DESC_LENGTH = 50
export const DELETE_DESKTOP_HERO_IMAGE = 'delete_hero_image'
export const DELETE_MOBILE_HERO_IMAGE = 'delete_mobile_hero_image'

export const SolutionEvaluatorSteps = [
  {
    logo: Number1,
    title: 'Explore Intelligently: ',
    description: 'Shop by category, stage of the hiring funnel, or let our AI copilot Tally guide your search.',
  },
  {
    logo: Number2,
    title: 'Compare Thoroughly: ',
    description: 'Evaluate solutions, tech stack integrations, and product features side by side.' 
  },
  { 
    logo: Number3,
    title: 'Act Decisively: ',
    description: 'Request more information or schedule demos, all within one streamlined platform.',
  }
]

export const ErrorPageCards = [
  {
    image: faHouse,
    title: 'Visit the homepage',
    description: 'Let’s start fresh and see if we can find a better match.',
    link: '/'
  },
  {
    image: faMagnifyingGlassArrowRight,
    title: 'Search for keywords',
    description: 'Use the search bar to hunt down the perfect fit.',
    link: null
  },
  {
    image: faComments,
    title: 'Contact Us',
    description: (
      <span>
        <span style={{ textDecoration: 'underline' }}>Drop us a line</span> and we’ll get back to you faster than you can say "let’s touch base".
      </span>
    ),
    link: `mailto:support@recruitmentmarketing.com`
  },
]

export const PromoCards = [
  {
    title: 'Connect',
    value: '100k+',
    description: <>talent professionals using our platform</>
  },
  {
    title: 'Choose',
    value: '1,500+',
    description: <>talent solutions, verified and listed on The Marketplace.</>
  },
  {
    title: 'Browse',
    value: '30+',
    description: <>recruitment solution <br /> categories</>
  }
]

export const ParentCategories = [
  {
    title: 'Awareness',
    description: "Attract candidates' attention by improving and promoting  your employer brand.",
    painPoints: [
      "Candidates don’t know about our brand, who we are, or what we stand for.",
      "Employees love working here, but job seekers don’t know."
    ]
  },
  {
    title: 'Attraction',
    description: 'Strategically attract qualified candidates through job advertising and reach your ideal candidate profile.',
    painPoints: [
      "My job postings aren’t getting enough traffic to fill jobs.",
      "People see my job listings but are not converting to apply?"
    ]
  },
  {
    title: 'Application',
    description: 'Candidates start filling out the job application, and submit their resume to the job posting.',
    painPoints: [
      "My application rate is low.",
      "We’re overwhelmed with applications and can’t screen all of them."
    ]
  },
  {
    title: 'Pre-screening',
    description: 'Review and verify candidate applications and qualify skills matched to the open job.  Communicate with candidates about their application status and guide the shortlisted candidates for further steps.',
    painPoints: [
      "Pre-screening candidates takes too much time.",
      "I’m not confident we’re finding the right qualified candidates."
    ]
  },
  {
    title: 'Interview',
    description: 'Conduct all rounds of interviews with a recruiter, a hiring manager, team members, and / or senior management.',
    painPoints: [
      "My interview process is too long, and we lose candidates to the competition.",
      "I don’t have visibility into the candidate experience or know how to improve it."
    ]
  },
  {
    title: 'Hiring',
    description: "Select a candidate, extend an offer, and negotiate on terms as required.",
    painPoints: [
      "My acceptance rate needs to be higher.",
      "My churn rate for new employees needs to be lower."
    ]
  }
]

export const EmailRegex =
  /^\b[A-Za-z0-9._%+-]+@(?!gmail\.com|yahoo\.com|ymail\.com|aol\.com|msn\.com|outlook\.com|protonmail\.com)[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/

export const CurrentTimelineOptions = [
  { label: 'Will implement new software solutions in the next 3 - 6 months', value: 'Will implement new software solutions in the next 3 - 6 months' },
  { label: 'Considering new software in the next year', value: 'Considering new software in the next year' },
  { label: 'Exploring options, no set timeline', value: 'Exploring options, no set timelineina' },
  { label: 'Just looking, no current plans to implement', value: 'Just looking, no current plans to implement' },
]

export const SoftwareAcquisitionRoles = [
  {label: 'I have the authority to decide on and approve software acquisitions', value: 'I have the authority to decide on and approve software acquisitions'},
  {label: 'I contribute to the decision-making process and can recommend software solutions', value: 'I contribute to the decision-making process and can recommend software solutions'},
  {label: 'I gather information and evaluate software options to support decision-making', value: 'I gather information and evaluate software options to support decision-making'},
  {label: 'I am not involved in software acquisition', value: 'I am not involved in software acquisition'},
]

export const leadConfirmationModalTitle = (formType: string, userLoggedIn: boolean) => {
  if (formType === "leadSubmission") {
    return userLoggedIn ? "Contact Request Submitted" : [<p><b>Success! Account Created! ...</b></p>]
  } else {
    return userLoggedIn ? "Newsletter Subscribed!" : [<p><b>Success! Account Created! ...</b></p>]
  }
}

export const leadConfirmationModalDescription = (formType: string, userLoggedIn: boolean, solutionName: string) => {
  if (formType === "leadSubmission") {
    return userLoggedIn
      ? `Your contact request has been submitted to ${solutionName} and one of their representatives will be in touch shortly.`
      : `Thank you for joining the talivity talent acquisition community. Please check your inbox for a welcome email.`
  } else {
    return userLoggedIn
      ? "You are all set to start receiving our weekly newsletter! Thank you for being a part of the Talivity talent acquisition community."
      : "Thank you for joining the Talivity talent acquisition community. Please check your inbox for a welcome email."
  }
}

export const leadPasswordModalTitle = (formType: string) => {
  return formType !== "leadSubmission" ? "Welcome!" : "Contact Request Submitted"
}

export const leadPasswordModalDescription = (formType: string, solutionName: string) => {
  return formType !== "leadSubmission"
    ? [<p>Complete your profile to access hundreds of free articles, eBooks, product demos, and buyers guides.</p>]
    : [
        <p>Your contact request has been submitted to {solutionName} and they will be in touch.</p>,
        <p>Please set a password to gain access to 100s of articles, ebooks, and buyers guides for free.</p>
      ];
}

export const leadGenerationFormDescription = (formType: string, currentUser: boolean, solutionName: string) => {
  if (formType === "leadSubmission" && !currentUser) {
    return `Please provide your contact information so ${solutionName} can respond to your request. After submitting your request a free profile will be created for you.`
  } 
  else if (formType === "leadSubmission" && currentUser) {
    return `Please provide the following details and the ${solutionName} team will be in touch regarding your request.`
  } 
  else if (formType === "newsletter") {
    return `Access 100s of articles and the RecruitmentMarketing.com by Talivity weekly newsletter - your go-to resource for staying ahead in the ever-evolving landscape of talent acquisition. Elevate your expertise with the latest industry news, hiring opportunities, and innovative solutions that simplify your workflow.`
  }
}

export const leadGenerationFormTitle = (formType: string, solutionName: string) => {
  if (formType === "leadSubmission")
    return `Contact ${solutionName}`
  else if(formType === "newsletter")
    return "Sign up for your free account"
}

export const passwordModalCtaText = (formType: string) => {
  if (formType === "leadSubmission") {
    return `Finish`
  }
  else if (formType === "newsletter") {
    return `Submit`
  }
}

export const confirmationModalCtaText = () => {
  return `Close`
}

export const leadGenerationFormCtaText = (formType: string, currentUser: boolean) => {
  if (formType === "leadSubmission" && currentUser) {
    return `Submit`
  }
  else if (formType === "leadSubmission" && !currentUser) {
    return `Submit`
  }
  else if (formType === "newsletter") {
    return `Sign Up`
  }
}

export const marketPlaceCardsData = [
  {
    heading: 'Create Demand',
    description: 'Build brand recognition with engagement opportunities',
  },
  {
    heading: 'Connect',
    description: 'with our community of TA tech buyers',
  },
  {
    heading: 'Convert',
    description: 'Capture contact information from over 100k high intent prospects',
  },
];

export const addSolutionUrl = 'https://members.talivity.com/claimprofile'

export const wordpressBaseURL = 'https://www.talivity.com/'

export const validVideoFileTypes = ['video/mp4', 'video/webm', 'video/mpeg', 'video/m4v', 'video/quicktime']
export const validImageFileTypes = ['image/jpeg', 'image/png', 'image/jpg']
export const validDocumentFileTypes = ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf']

export const maxVideoFileSize = 100
export const maxImageFileSize = 5
export const maxDocumentFileSize = 10

export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
export const CompanySizeOptions = [
  { label: '1 - 200', value: '1 - 200' },
  { label: '201 - 500', value: '201 - 500' },
  { label: '501 - 2500', value: '501 - 2500' },
  { label: '2501 - 10000', value: '2501 - 10,000' },
  { label: '10,001+', value: '10,001+' },
]

export const MAX_TESTIMONIALS_LIMIT = 20