import clsx from 'clsx';
import checkCircle from '../../../../assets/images/BlueCheckMark.svg';
import closeIcon from '../../../../assets/images/x-circle.svg';
import Button from '../../atoms/buttons/Button';

type PricingSectionProps = {
  solution: schema.Solution;
  currentUser: schema.User;
  isClaimed: boolean;
  solutionOwner: schema.User;
  handleContactVendor: () => void;
};

export function PricingSection({
  solution,
  currentUser,
  isClaimed,
  solutionOwner,
  handleContactVendor,
}: PricingSectionProps) {
  const {
    is_solution_free: isSolutionFree,
    pricing_details: pricingDetails,
    starting_cost: startingCost,
    name: solutionName,
    offer_free_trial: offersFreeTrial,
  } = solution;

  const renderPricingSection = () => {
    return currentUser ? isClaimed : true;
  };

  const paidWithStartingCostClasses = () => {
    return startingCost > 0.0 && !isSolutionFree;
  };

  return (
    <div className="mt-5">
      {renderPricingSection() ? (
        <div
          className={`flex flex-col gap-3 rounded-xl p-5 sm:flex-row sm:gap-0 md:p-8 ${
            startingCost && pricingDetails ? 'bg-light-gray' : 'border-[1.5px]'
          }`}
        >
          <div className="flex flex-col gap-3 border-b-[1px] pb-6 text-sm sm:hidden">
            <p className="text-md font-semibold">Pricing Details:</p>
            {pricingDetails ? (
              <p
                className={`break-words ${
                  paidWithStartingCostClasses() ? 'mt-7' : 'mt-3'
                }`}
              >
                {pricingDetails}
              </p>
            ) : (
              (!startingCost || startingCost !== 0) &&
              !pricingDetails && (
                <p className={`${!isSolutionFree ? 'mt-7' : 'mt-3'} text-sm`}>
                  {solutionName} has not provided any pricing details yet.
                </p>
              )
            )}
          </div>
          <div className="flex flex-col gap-6 sm:border-r-[1px] sm:pr-8">
            <p className="text-md font-semibold">Starting From:</p>
            <div className="">
              {startingCost && !isSolutionFree && startingCost > 0.0 ? (
                <div className="flex items-center gap-1">
                  <p className="text-[36px] font-bold">
                    ${Math.floor(startingCost).toLocaleString()}
                  </p>
                  <p className="text-sm">
                    {solution.costing_frequency === 'monthly'
                      ? '/month'
                      : '/year'}
                  </p>
                </div>
              ) : (
                (!startingCost || startingCost !== 0) && (
                  <p className="text-sm">
                    {isSolutionFree
                      ? 'Free entry level accounts available.'
                      : `${solutionName} has not provided any pricing information yet.`}
                  </p>
                )
              )}
            </div>
            {!isSolutionFree && offersFreeTrial !== null && (
              <div className="flex gap-1">
                <img
                  src={offersFreeTrial ? checkCircle : closeIcon}
                  alt="icon"
                  className="h-5 w-5"
                />
                <p className="whitespace-nowrap text-sm">
                  {offersFreeTrial
                    ? 'Offers a free trial'
                    : 'Does not offer a free trial'}
                </p>
              </div>
            )}

            {solution.paid_solution &&
              <Button
                variant="primary"
                onClick={handleContactVendor}
                size='lg'
                className="w-full sm:w-min"
              >
                <p className="whitespace-nowrap">Request a Quote</p>
              </Button>
            }
          </div>
          <div className="hidden gap-3 pl-8 text-sm sm:flex sm:flex-col">
            <p className="text-md font-semibold">Pricing Details:</p>
            {pricingDetails ? (
              <p
                className={`break-words ${
                  paidWithStartingCostClasses() ? 'mt-7' : 'mt-3'
                }`}
              >
                {pricingDetails}
              </p>
            ) : (
              !pricingDetails && (
                <p
                  className={`${
                    paidWithStartingCostClasses() ? 'mt-7' : 'mt-3'
                  } text-sm`}
                >
                  {solutionName} has not provided any pricing details yet.
                </p>
              )
            )}
          </div>
        </div>
      ) : !pricingDetails && solutionOwner?.id === currentUser.id ? (
        <div className="relative flex flex-col rounded-xl border-[1px] bg-light-gray p-5 sm:items-center sm:justify-center md:p-8">
          <p className="text-lg font-bold leading-[30px] text-black md:text-xl md:leading-[35px]">
            No Pricing Yet
          </p>
          <p>Click the pencil icon to add pricing to your profile</p>
        </div>
      ) : (
        <div className="relative flex flex-col gap-2 rounded-xl border-[1px] p-5 md:p-8">
          <p className="text-md font-bold leading-[30px] text-black md:leading-[35px]">
            PRICING DETAILS
          </p>
          <p className="break-words text-sm">{pricingDetails}</p>
          {solution.paid_solution &&
            <Button
              variant="primary"
              onClick={handleContactVendor}
              size='lg'
              className="sm:w-1/2 lg:w-1/4"
            >
              <p className="whitespace-nowrap">Request a Quote</p>
            </Button>
          }
        </div>
      )}
    </div>
  );
}
