import { Dispatch, SetStateAction } from 'react';

import { Link } from '@inertiajs/react';

import minifyString from '../../../utils/minifyString';

import { SolutionTag } from '@/components/molecules/solution/SolutionCard';

export function CollapsedCategories({
  minify,
  wrapperClasses,
  solution,
  categories,
  categorySlugs,
  showCategories,
  setIsExpanded,
}: {
  minify: boolean;
  categories: Array<string>;
  categorySlugs: Array<string>;
  wrapperClasses: string;
  solution: schema.Solution;
  showCategories: number;
  setIsExpanded: Dispatch<SetStateAction<boolean>>;
}) {

  const handleExpand = (event) => {
    event.preventDefault();
    setIsExpanded(true);
  };

  const solutionTagClasses = `mr-2 flex items-center gap-[10px] rounded-full 
                              bg-blue-tint-1 border border-light-gray px-4 
                              py-1 font-Manrope text-[10px] font-semibold leading-[18px] 
                              text-dark-blue`

  return (
    <div className={wrapperClasses}>
      {categories.map((tag, index) => (
        <Link
          href={`/categories/${categorySlugs[index]}`}
          className='snap-center mb-2'
          key={`${tag + '-' + index}`}
        >
          <SolutionTag
            key={`${tag + '-' + index}`}
            classes={`${solutionTagClasses} hover:bg-dark-blue hover:text-light-gray`}
          >
            {minify ? minifyString(tag, 15, 18) : tag}
          </SolutionTag>
        </Link>
      ))}
      {solution?.categories_names?.length > showCategories ? (
        <Link href={'#'} className='snap-center' onClick={handleExpand}>
          <SolutionTag
            key={`${solution?.categories_names?.length - showCategories + '-' + showCategories}`}
            classes={`${solutionTagClasses} hover:border hover:border-dark-blue`}
          >
            {`+${solution?.categories_names?.length - showCategories} more`}
          </SolutionTag>
        </Link>
      ) : null}
    </div>
  );
}
