import React, { useEffect, useState } from "react"
import Modal from "../../atoms/modals/Modal"
import Form, { useToken } from "@/components/atoms/forms/Form"
import useWindowSize from "../../atoms/hooks/useWindowSize"
import { useForm, SubmitHandler } from "react-hook-form"
import clsx from "clsx"
import { createJobsNewsletterSubscription, createWeeklyNewsletterSubscription, postRequest } from "@/utils/axiosRequests"
import { getErrorMessage } from "@/utils/helperFunctions"
import { toast } from "react-toastify"
import ErrorMessage from "@/components/atoms/Toast/ErrorMessage"
import { defaultToastOptions } from "@/utils/commonToast"
import { passwordModalCtaText } from "@/utils/constants"
import { FaEyeSlash } from "react-icons/fa6"

type ConfirmPasswordModalProps = {
  openModal: boolean
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
  setOpenConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>
  data: schema.CustomerLead
  createLead: (user_id: number) => void
  formTitle: string
  formDescription: string
  formType: string
  isSubToWeeklyNewsletter: boolean
  isSubToJobsNewsletter: boolean
}

type FormData = {
  password: string
  confirm_password: string
}

export default function ConfirmPasswordModal({
  openModal,
  setOpenModal,
  data,
  createLead,
  formTitle,
  formDescription,
  setOpenConfirmationModal,
  formType,
  isSubToWeeklyNewsletter,
  isSubToJobsNewsletter
}: ConfirmPasswordModalProps) {

  const { display } = useWindowSize()
  const isMobile = display === "mobile"
  const token = useToken()

  const [isFormValid, setIsFormValid] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormData>({
    defaultValues: {
      password: "",
      confirm_password: "",
    },
  })

  useEffect(() => {
    setIsFormValid(isValid)
  }, [isValid])

  const createUser = async (userPassword = "") => {
    const formData = new FormData()

    formData.append(`user[first_name]`, `${data.first_name}`)
    formData.append(`user[last_name]`, `${data.last_name}`)
    formData.append(`user[company]`, `${data.company}`)
    formData.append(`user[job_title]`, `${data.job_title}`)
    formData.append(`user[email]`, `${data.email}`)

    if (userPassword === "") {
      formData.append("temp_pw_required", true)
    } else {
      formData.append(`user[password]`, `${userPassword}`)
    }

    const response = await postRequest("/users", formData, token)
    return response
  }

  const createSubscription = async (userId) => {
    let response = null
    if(isSubToWeeklyNewsletter)
    { 
      const res = await createWeeklyNewsletterSubscription(userId, token)
      if(res.status !== 200)
        return res
      response = res
    }
    if (isSubToJobsNewsletter){
      const res = await createJobsNewsletterSubscription(userId, token)
      if(res.status !== 200)
        return res 
      response = res 
    }
    return response
  }

  const submit = async (password = "") => {
    setIsLoading(true)
    const userRes = await createUser(password)

    if (userRes.data.message === "success") {
      if (formType === "leadSubmission") {
        const leadResponse = await createLead(userRes.data.user_id)

        if (leadResponse.data.status === "successful") {
          setOpenModal(false)
          setOpenConfirmationModal(true)
          if (isSubToWeeklyNewsletter || isSubToJobsNewsletter)
            await createSubscription(userRes.data.user_id)
        }
      }
      else if (formType === "newsletter") {
        const newsletterRes = await createSubscription(userRes.data.user_id)

        if (newsletterRes.status == 200) {
          setOpenModal(false)
          setOpenConfirmationModal(true)
        }
      }
    }
    else {
      toast(
        <ErrorMessage message={userRes.data.message[0]} />,
        defaultToastOptions("red-200")
      )
      setOpenModal(false)
    }
    setIsLoading(false)
  }

  const onSubmit: SubmitHandler<FormData> = async (passwordData) => {
    submit(passwordData.password)
  }

  return (
    <>
      <Modal
        isOpen={openModal}
        onClose={async () => {
          setOpenModal(false)
          submit("")
        }}
        closeStyle="top-5 right-4"
        className="mx-4 w-full rounded-xl bg-white px-8 py-7 md:mt-0 md:w-[752px]"
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <h1 className="mb-4 sm:text-2xl text-lg font-bold text-center">{formTitle}</h1>
            <p className="mb-8 text-center sm:text-md text-sm">
              {formDescription}
            </p>
            <div className={clsx("mb-4", {flex: !isMobile, "flex-col": isMobile})}>
              <div className={clsx("relative w-full mr-4", { "mb-4": isMobile })}>
                <label htmlFor="password" className="block mb-1">
                  Password*
                </label>
                <input
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  {
                    ...register("password", {
                    required: getErrorMessage("Password", "required"),
                    minLength: {
                      value: 6,
                      message: getErrorMessage("Password", "minLength", 6),
                    },
                    maxLength: {
                      value: 20,
                      message: getErrorMessage("Password", "maxLength", 20),
                    }})
                  }
                  placeholder="Password"
                  onBlur={(e) => {
                    e.target.value = e.target.value.trim()
                  }}
                  className="border w-full border-gray-300 rounded-md p-2"
                  maxLength={20}
                />
                
                <FaEyeSlash className="absolute top-[40px] right-5" onClick={(e) => {
                    e.preventDefault();
                    setShowPassword(!showPassword);
                  }}/>
                {
                  errors.password && (
                    <span className="text-red-500">
                      {errors.password.message}
                    </span>
                  )
                }
              </div>
              <div className={clsx("relative w-full mr-4", { "mb-4": isMobile })}>
                <label htmlFor="confirm_password" className="block mb-1">
                  Confirm Password*
                </label>
                <input
                  type={showConfirmPassword ? 'text' : 'password'}
                  id="confirm_password"
                  {
                    ...register("confirm_password", {
                    required: getErrorMessage(
                      "Password Confirmation",
                      "required"
                    ),
                    validate: (val: string) => {
                      if (watch("password") != val) {
                        return "Your passwords do not match"
                      }
                    }})
                  }
                  placeholder="Confirm Password"
                  maxLength={50}
                  onBlur={(e) => {
                    e.target.value = e.target.value.trim()
                  }}
                  className="border w-full border-gray-300 rounded-md p-2"
                />
                
                  <FaEyeSlash className="absolute top-[40px] right-5" onClick={(e) => {
                    e.preventDefault();
                    setShowConfirmPassword(!showConfirmPassword);
                  }}/>
                {
                  errors.confirm_password && (
                    <span className="text-red-500">
                      {errors.confirm_password.message}
                    </span> )
                }
              </div>
            </div>
          </div>
          <div className="flex flex-col md:justify-end items-end gap-4 md:flex-row sm:mr-4">
            <input
              type="submit"
              value={passwordModalCtaText(formType)}
              className={`!inline-block !rounded-xl !transition !duration-300 !focus:outline-none ${isFormValid && !isLoading ? "!bg-teal !text-dark-blue" : "!bg-light-gray !text-gray-400"} !font-bold !px-8 !py-[14px] !text-md !w-[164px] !cursor-pointer`}
              disabled={isLoading ? true : false}
            />
          </div>
        </Form>
      </Modal>
    </>
  )
}