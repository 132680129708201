import { useEffect, useState } from 'react'
import { Menu } from '@headlessui/react'
import { FaChevronDown } from 'react-icons/fa'
import clsx from 'clsx'
import { FilterStarDisplay } from '@/components/atoms/filters/FilterSidebar'
import { RATING_FILTER_UPPER_LIMIT } from '@/utils/constants'

type RatingFilterProps = {
  initialValue: number
  handleChange: (number) => void
}

export const RatingFilter = ({
  initialValue,
  handleChange,
}: RatingFilterProps) => {
  const [selected, setSelected] = useState(Number(initialValue))

  useEffect(() => {
    setSelected(Number(initialValue))
  }, [initialValue])

  const ratingChange = (value: number) => {
    const newValue = selected === value ? 0 : value
    setSelected(newValue);
    handleChange(newValue === 0 ? null : newValue)
  }

  return (
    <Menu className='relative' as='div'>
      <Menu.Button className='relative block w-full rounded-[4px] bg-dark-gray px-2 py-1 sm:px-0 sm:py-[2px] text-left'>
        {({ open }) => (
          <div className='text-[14px] text-[#747474] flex items-center justify-between max-lg:justify-center gap-2 py-[1px] sm:px-2'>
            <p>Rating</p>
            <FaChevronDown
              className={`text-[#747474] w-[16px] self-center ${open ? 'rotate-180' : ''}`}
            />
          { selected !== 0 && (
            <span className='absolute top-[-3px] -right-1 flex size-3 rounded-full bg-ocean-blue z-10' />
          )}
          </div>
        )}
      </Menu.Button>
      <Menu.Items
        className={clsx(
          'absolute z-10 mt-2 flex w-max origin-top-right flex-col',
          'gap-x-5 gap-y-4 rounded-xl bg-light-gray py-4 shadow-card ring-1 ring-black/5 focus:outline-none',
          'px-4'
        )}
      >
        {Array.from({ length: RATING_FILTER_UPPER_LIMIT }, (_, index) => (
          <Menu.Item key={index}>
            {({ active }) => (
              <div
                className={clsx(
                  'flex items-center w-full gap-3 rounded-md text-xs font-medium hover:bg-light-gray-hover-2 active:bg-light-gray-hover-2',
                  active && 'bg-light-gray-hover-2'
                )}
                onClick={() => ratingChange(RATING_FILTER_UPPER_LIMIT - index)}
              >
                <div className='flex size-4 items-center justify-center rounded-full border border-medium-gray'>
                  <div
                    className={clsx(
                      'size-3 rounded-full',
                      selected === RATING_FILTER_UPPER_LIMIT - index &&
                        'bg-check-blue'
                    )}
                  />
                </div>
                <FilterStarDisplay count={3 - index} />
              </div>
            )}
          </Menu.Item>
        ))}
      </Menu.Items>
    </Menu>
  )
}
