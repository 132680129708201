import React, { useEffect, useRef, useState } from 'react';
import useWindowSize from '@/components/hooks/useWindowSize';
import clsx from 'clsx';
import { Link } from '@inertiajs/react';
import useOutsideClick from '@/hooks/useOutsideClick';

type SearchedSolutionsAndCategoriesProps = {
  wrapperClassName: string;
  searchedSolutions: schema.Solution[];
  searchedCategories: schema.Category[];
  searchTerm: string;
  handleSelectedCategory: any;
  onClose: () => void;
  open: boolean;
};

export default function SearchedSolutionsAndCategories({
  wrapperClassName = '',
  searchedSolutions,
  searchedCategories,
  searchTerm,
  handleSelectedCategory,
  onClose = () => {},
  open = false,
}: SearchedSolutionsAndCategoriesProps) {
  const searchRef = useRef();

  const highlightSearchTerm = (text: string) => {
    const regex = new RegExp(searchTerm, 'gi');
    return text.replace(
      regex,
      (match) => `<span class="font-normal">${match}</span>`
    );
  };

  useOutsideClick(searchRef, open, onClose);

  return (
    <div className={clsx('absolute w-full', wrapperClassName)} ref={searchRef}>
      <div className="w-full rounded-b-xl border-x border-t-0 border-b border-black bg-white pb-1 md:gap-10">
        <div
          className={clsx(
            'relative max-h-[250px] overflow-y-auto md:max-h-[360px]'
          )}
        >
          {searchedSolutions?.length > 0 && (
            <>
              {searchedCategories?.length > 0 ? (
                <h2 className="px-[14px] text-[14px] font-bold text-medium-gray md:pt-4">
                  SOLUTIONS
                </h2>
              ) : (
                <p className="px-[14px] pt-3 text-[14px] font-bold text-medium-gray" />
              )}
              {searchedSolutions.map((solution) => (
                <Link
                  href={`/solutions/${solution.slug}`}
                  key={solution.id}
                  className="whitespace-nowrap rounded-full md:whitespace-pre-wrap md:rounded-xl"
                >
                  <p className="my-1 rounded-xl py-[12px] px-[14px] text-[16px] font-semibold hover:bg-blue-tint-2">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: highlightSearchTerm(solution.name),
                      }}
                    />
                  </p>
                </Link>
              ))}
            </>
          )}

          {searchedCategories.length > 0 && (
            <>
              {searchedSolutions?.length > 0 ? (
                <h2 className="py-1 px-[14px] text-[14px] font-bold text-medium-gray">
                  CATEGORIES
                </h2>
              ) : (
                <p className="px-[14px] text-[16px] font-bold text-medium-gray md:pt-2" />
              )}
              {searchedCategories.map((category) => (
                <div
                  key={category.id}
                  className="whitespace-nowrap rounded-full md:whitespace-pre-wrap md:rounded-xl"
                >
                  <Link href={`/categories/${category.slug}`} key={category.id}>
                    <p className="my-1 rounded-xl py-[12px] px-[14px] text-[16px] font-semibold hover:bg-blue-tint-2">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: highlightSearchTerm(category.name),
                        }}
                      />
                    </p>
                  </Link>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
