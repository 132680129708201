import { toast } from 'react-toastify'
import { defaultToastOptions } from '@/utils/commonToast'
import ErrorMessage from '@/components/atoms/Toast/ErrorMessage'
import SuccessMessage from '@/components/atoms/Toast/SuccessMessage'
import { MAX_FILE_SIZE_MB, MAX_FILE_SIZE_BYTES } from '@/utils/fileMaxSizes'
import { putRequest } from './axiosRequests'
import { ALLOWED_IMAGE_TYPES, wordpressBaseURL } from '@/utils/constants'

export const redirectToUrl = (page_url) => {
  window.open(page_url, '_blank')
}

export const isValidURL = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (_) {
    return false;
  }
}

export const openTallyChatbot = () => {
  const chatBoxContainer = document.getElementById('cgptcb-chat-box-container');
  const chatBoxIframe = document.getElementById('cgptcb-chat-box-iframe') as HTMLIFrameElement;

  if (chatBoxContainer) {
    chatBoxContainer.classList.add('open');
  }

  const chatBoxToggle = document.getElementById('cgptcb-chat-box-toggle');
  if (chatBoxToggle) {
    chatBoxToggle.style.display = 'block';
  }

  if (chatBoxIframe) {
    const currentSrc = chatBoxIframe.getAttribute('src');
    if (!currentSrc) {
      const projectId = '32969';
      const key = 'af2dd9b99cc73fc1e272941a65b636ac';
      chatBoxIframe.src = `https://app.customgpt.ai/projects/${projectId}/ask-me-anything/session_id?rs=livechat&embed=1&shareable_slug=${key}`;
    }
    chatBoxIframe.style.display = 'block';
  }
}

export const handleLogoValidation = (selectedFile) => {
  if (!selectedFile) {
    toast(
      <ErrorMessage message={'No File Selected!'} />,
      defaultToastOptions('red-200')
    )
    return false
  }
  else if (!ALLOWED_IMAGE_TYPES.includes(selectedFile.type)) {
    toast(
      <ErrorMessage message={'Invalid Image Type!'} />,
      defaultToastOptions('red-200')
    )
    return false
  }
  else if (selectedFile.size > MAX_FILE_SIZE_BYTES) {
    toast(
      <ErrorMessage message={`File size is too large. Maximum file size allowed is ${MAX_FILE_SIZE_MB}MB.`} />,
      defaultToastOptions('red-200')
    )
    return false
  }
  else {
    return true
  }
}

export const handleLogoRequest = async (selectedFile, solution, token, setLogoPreviewUrl) => {
  const formData = new FormData()
  formData.append('solution[logo]', selectedFile)

  try {
    const response = await putRequest(`/solutions/${solution.id}`, formData, token)

    if (response.status === 200) {
      toast(
        <SuccessMessage />,
        defaultToastOptions('teal-tint-2')
      )
      setLogoPreviewUrl(URL.createObjectURL(selectedFile))
    }
  } catch (error) {
    toast(
      <ErrorMessage />,
      defaultToastOptions('red-200')
    )
  }
}

export const isObjectEmpty = (obj) => {
  return !Object.values(obj).some(value => value)
}

export const getErrorMessage = (fieldName: string, rule: string, value?: number) => {
  switch (rule) {
    case 'required':
      return `${fieldName} is required`
    case 'minLength':
      return `${fieldName} must be at least ${value} characters`
    case 'maxLength':
      return `${fieldName} must be at most ${value} characters`
    case 'pattern':
      return `Please enter a valid work email address`
    default:
      return `${fieldName} is invalid`
  }
};

export const getQueryParamValue = (paramName) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(paramName);
};

export const getCategoryUrl = (primaryCategory, solution) => {
  if (primaryCategory?.slug) {
    return `/categories/${primaryCategory.slug}`
  }
  if (solution.categories_slugs && solution.categories_slugs.length === 1) {
    return `/categories/${solution.categories_slugs[0]}`
  }
  return '/marketplace'
}

export const getArticleUrl = (article) => {
  const pageURL = article.page_url.trim('/').split('/').filter(Boolean)
  const articleURL = pageURL[pageURL.length - 1]
  return `${wordpressBaseURL}${article.category_slug}/${articleURL}`
}

export const extractDayWithSuffix = (day) => {
  if (day >= 11 && day <= 13) {
    return day + 'th'
  }
  switch (day % 10) {
    case 1: return day + 'st'
    case 2: return day + 'nd'
    case 3: return day + 'rd'
    default: return day + 'th'
  }
}

export const extractDate = (date) => {
  const extractedDate = new Date(date)
  return `${extractedDate.toLocaleString('en-US', { month: 'long' }) + ' ' + extractDayWithSuffix(extractedDate.getDate()) + ', ' + extractedDate.getFullYear()}`
}