/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import Modal from '../../atoms/modals/Modal';
import Button from '../../atoms/buttons/Button';
import axios from 'axios';
import { useToken } from '@/components/atoms/forms/Form';

export type EditDemoUrlModalProps = {
  openEditDemoModal: boolean;
  setOpenEditDemoModal: React.Dispatch<React.SetStateAction<boolean>>;
  solution: schema.Solution;
  setHasDemoChanged?: React.Dispatch<React.SetStateAction<boolean>>;
};

const EditModal = ({
  openEditDemoModal,
  setOpenEditDemoModal,
  solution,
  setHasDemoChanged,
}: EditDemoUrlModalProps) => {
  const [demoUrl, setDemoUrl] = useState(solution.demo_url);
  const token = useToken();
  const handleSaveDemoUrl = async () => {
    solution.demo_url=demoUrl;
    try {
      await axios.put(`/solutions/${solution.id}`, { solution }, {
        headers: {
          'X-CSRF-Token': token,
          'Accept': 'application/json',
        },
      });
      
      setOpenEditDemoModal(false);
      setHasDemoChanged(true);
    } catch (error) {
      console.error('Error updating demo URL:', error);
    }
  };
  return (
    <Modal
      isOpen={openEditDemoModal}
      onClose={() => setOpenEditDemoModal(false)}
      closeStyle="top-10 right-10"
      className="mx-4 w-full rounded-xl bg-white p-10 md:mt-0 md:w-[600px]"
    >
      <div className="flex flex-col">
        <h1 className="mb-8 justify-start  text-2xl font-bold ">Edit Link</h1>
        <p className="mb-2  text-lg">
          Demo URL
        </p>
        <input
          type='text'
          placeholder={solution.demo_url}
          value={demoUrl}
          onChange={(e)=> setDemoUrl(e.target.value)}
          className='mb-8 rounded-xl px-4 py-5 text-lg'
        />
        <div className='flex flex-row gap-2'>
          <Button
           onClick= {()=>setOpenEditDemoModal(false)}
           variant='demo'
           size='lg'
           className='w-full'
           >
            Cancel
          </Button>
          <Button
            onClick= {()=>handleSaveDemoUrl()}
            variant='primary'
            size='lg'
            className='w-full'
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  )
};

export default EditModal;
