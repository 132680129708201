import React, { lazy, useState } from 'react'

import { IoEllipsisVerticalSharp } from 'react-icons/io5'

import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import PressReleaseModal from '@/components/molecules/accountManagement/PressReleaseModal'
import CreateReferralModal from '@/components/molecules/accountManagement/CreateReferralModal'

export default function DropDownMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const [activeModal, setActiveModal] = useState<'pressRelease' | 'referral' | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = (modalType: 'pressRelease' | 'referral') => {
    setActiveModal(modalType)
    handleClose()
  }

  const closeModal = () => {
    setActiveModal(null)
  }

  return (
    <div>
      <IoEllipsisVerticalSharp className='mt-2 cursor-pointer' onClick={handleClick} />
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => handleMenuItemClick('pressRelease')}>Share a press release</MenuItem>
        <MenuItem onClick={() => handleMenuItemClick('referral')}>Create a referral</MenuItem>
      </Menu>
      {activeModal === 'pressRelease' && (
        <PressReleaseModal onClose={closeModal} />
      )}
      {activeModal === 'referral' && (
        <CreateReferralModal onClose={closeModal} />
      )}
    </div>
  )
}
