import useCurrentUser from '@/hooks/useCurrentUser';
import { Link } from '@inertiajs/react';

type StagesIndexProps = {};

export default function StagesIndex({ ...props }: StagesIndexProps) {
  // eslint-disable-next-line no-console
  const currentUser = useCurrentUser();
  return (
    <div style={{ marginTop: 30 }} className="text-blue-500">
      {!Boolean(currentUser) ? (
        <Link href="/login">Log In</Link>
      ) : (
        <div>
          <h3>Logged in as {currentUser?.email}</h3>
        </div>
      )}
    </div>
  );
}
