import { useState } from 'react'
import Select from 'react-select'

type HasPricingDropdownProps = {
  hasPricing: { label: string; value: string };
  setHasPricing: React.Dispatch<React.SetStateAction<{ label: string; value: string }>>;
}

const HasPricingDropdown = ({ hasPricing, setHasPricing }: HasPricingDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: 'none',
      boxShadow: 'none',
      backgroundColor: '#D9D9D9',
      height: '27px',
      minHeight: '27px',
      fontSize: '14px',
      paddingBottom: '22px',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: '0px',
      color: '#747474',
      paddingRight: '6px',
      position: 'relative',
      transform: `translateY(-50%) ${isOpen ? 'rotateX(180deg)' : 'rotateX(0deg)'}`
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    placeholder: (provided) => ({
      ...provided,
      paddingBottom: '22px',
      whiteSpace: 'nowrap',
    }),
    singleValue: (provided) => ({
      ...provided,
      paddingBottom: '22px',
      color: '#747474',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: '#747474',
      padding: '0px',
      paddingBottom: '22px',
    }),
  }

  const PricingOptions = [
    {
      label: 'Yes',
      value: 'true',
    },
    {
      label: 'No',
      value: 'false',
    },
  ]

  return (
    <div className='text-[12px] min-w-[90px] relative'>
      {hasPricing &&
        <span className='absolute top-[-3px] -right-1 flex size-3 rounded-full bg-ocean-blue z-10' />
      }
      <Select
        options={PricingOptions}
        isMulti={false}
        isSearchable={false}
        placeholder={'Has Pricing'}
        styles={customStyles}
        value={hasPricing}
        onChange={setHasPricing}
        isClearable
        onMenuOpen={() => setIsOpen(true)}
        onMenuClose={() => setIsOpen(false)}
      />
    </div>
  )
}

export default HasPricingDropdown
