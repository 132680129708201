import HomepageSearch from '@/Pages/Homepage/HomepageSearch'

const MarketplaceHeroSection = () => {
  return (
    <div className='flex flex-col items-center justify-center pt-[3.7rem] sm:pt-[3rem] xl:pt-[4.3rem]'>
      <div className='px-4 sm:px-6 md:px-0 sm:w-[650px] md:w-[740px] lg:w-[830px] items-center justify-center '>
        <p className='flex flex-col text-center items-center sm:gap-5 mb-[24px] font-extrabold leading-[50px] sm:leading-[1rem] md:leading-[1.6rem] lg:leading-[2.695rem] text-[36px] md:text-[48px] lg:text-[48px]'>
          <span className='leading-[40px] sm:leading-[45px] md:leading-[55px] lg:leading-[55px] text-[#031931]'>
            Discover how thousands of talent leaders make smarter, faster HR Tech decisions
          </span>
        </p>
        <p className='mb-3 text-[16px] leading-[24px] text-black sm:text-[0.937rem] text-center'>
          Our AI-driven platform connects you with verified solutions and trusted expertise. Stop wasting time and money on how to optimize your talent strategy and get started with Talivity.
        </p>
      </div>
      <div className='flex flex-col text-[24px] font-bold mt-[64px] justify-center items-center text-outer-space px-2'>
        <p className='text-center'>Search by category, solution, or topic</p>
        <div className='mt-[16px]'>
          <HomepageSearch />
        </div>
      </div>
    </div>
  )
}

export default MarketplaceHeroSection
