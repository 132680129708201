import React from 'react'
import HorizontalMarginsWrapper from '@components/atoms/wrappers/HorizontalMarginsWrapper'
import { useMobileDetect } from '../../../utils/detectMobile'
import Button from '@/components/atoms/buttons/Button'
import { IoChevronDown } from "react-icons/io5"
import HeroSectionImage from '../../../../assets/images/heroSectionImage.webp'
import Bg from '../../../../assets/images/greyblend.svg'

const MarketplaceHeroSection = ( { targetRef } ) => {
  const isMobile = useMobileDetect()
  const scrollToComponent = () => {
    const navbarHeight = document.querySelector('#navbar').offsetHeight;
    const top = targetRef.current.getBoundingClientRect().top + scrollY - navbarHeight;
    window.scrollTo({ top, behavior: 'smooth' });
  };

  const Details = () => {
    return(
      <div className='flex flex-col gap-4 sm:gap-6'>
        <p className='text-[14px] md:text-start text-black lg:text-[16px]'>
          Transform your talent practice with the technology, insights, and expertise you need to hire more qualified talent, more quickly. Our AI-Powered Marketplace empowers  you to find the best HR technology and services to grow your business.
        </p>
        <Button 
          variant='primary'
          className='flex justify-center gap-1 items-center self-center md:self-start whitespace-nowrap px-3 py-2 text-xs !rounded-md md:text-md mb-5 sm:mb-8 md:mb-10'
          onClick={scrollToComponent}
        >
          <p>View All Categories</p>
          <IoChevronDown className='text-xs md:text-md'/>
        </Button>
      </div>
    )
  }

  return (
    <div className='bg-contain 2xl:bg-cover bg-no-repeat bg-center' 
     style={{
       backgroundImage: `${!isMobile && `url(${Bg})`}`,
       backgroundPosition: 'center 250px' 
    }}>
    <HorizontalMarginsWrapper>
        <div className='flex font-Manrope items-center gap-16' >
          <div className='text-center md:text-start md:w-[55%] lg:w-[50%] pt-[50px] md:pt-[35px] lg:pt-[45px]'>
            <h1 className='font-extrabold w-full text-[20px] md:text-[1.75rem] xl:text-[38px] leading-normal mb-10 sm:mb-4 lg:mb-8 px-2 sm:px-0'>
              The Marketplace: Where HR Tech Finds Its Perfect Match
            </h1>
            { !isMobile && <Details/> }
          </div>
          { !isMobile && <img src={HeroSectionImage} loading='lazy' alt='hero-section-image' className='md:w-[45%] lg:w-[50%]'/> }
        </div>
        { isMobile && 
          <div
            className='text-center md:text-start relative mt-[100px] mx-[4%] h-[300px] flex flex-col justify-end rounded-xl py-3 px-6 bg-fade-gray-gradient'>
            <Details/>
            <img
              src={HeroSectionImage}
              alt='Hero-Image-Mobile'
              className='w-[300px] mt-[2%] absolute top-[-45%] xs:top-[-40%] self-center'
              loading='lazy'
            />
          </div>
        }
      </HorizontalMarginsWrapper>
    </div>
  )
}

export default MarketplaceHeroSection