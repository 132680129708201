import Button from '../../atoms/buttons/Button';

type SolutionShowProps = {
  solution: schema.Solution;
  promotions: schema.Promotion[];
};
export default function PromotionCard({ ...props }: SolutionShowProps) {
  const { solution, promotions } = props;

  const handleClaimOfferClick = (ctaUrl: string) => {
    if (ctaUrl) {
      window.location.href = ctaUrl;
    } else {
      window.history.back();
    }
  };

  return (
    <>
      {promotions.map((promotion, index) => (
        <div
          key={index}
          className="rounded-12 flex h-[270px] w-full max-w-[390px] flex-col justify-between rounded-lg bg-indigo-300 bg-opacity-30 p-[32px] max-md-sm:mb-[20px]"
        >
          <div className="align-content-center flex flex-col flex-wrap items-start gap-[12px] self-stretch">
            <div className="font-manrope text-textColor-dark overflow-hidden text-2xl font-bold leading-[30px] line-clamp-1">
              {promotion.title}
            </div>
            <div className="font-manrope text-textColor-dark overflow-hidden text-base font-[14px] leading-[21px] line-clamp-3">
              {promotion.details}
            </div>
          </div>
          <div />
          {promotion.cta_url && (
            <Button
              variant="secondary"
              type="button"
              size="md"
              className="mt-[8px] flex h-12 w-full items-center justify-center gap-8 rounded-[12px] px-4 py-3"
              onClick={() => handleClaimOfferClick(promotion.cta_url)}
            >
              <p>Claim Offer</p>
            </Button>
          )}
        </div>
      ))}
    </>
  );
}
