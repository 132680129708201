import React from 'react'

import { useTracking } from 'react-tracking'

import convertToSlug from '@/utils/convertToSlug'

type ButtonProps = {
  variant:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'danger'
    | 'pink'
    | 'demo'
    | 'gray'
    | 'disable'
    | 'teal'
    | 'dangerLight'
    | 'white'
    | 'darkBlue'
    | 'gulfBlue'
  size?: 'sm' | 'md' | 'lg';
  children: React.ReactNode;
  className?: string;
  trackBtnClick?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button = ({
  variant,
  size,
  children,
  className = '',
  trackBtnClick = false,
  isLoading = false,
  disabled = false,
  onClick,
  ...rest
}: ButtonProps) => {
  const variantClasses = {
    primary: 'bg-teal hover:bg-teal-hover active:bg-teal-clicked text-black',
    secondary:
      'bg-dark-blue hover:bg-dark-blue-hover active:bg-dark-blue-clicked text-white',
    tertiary:
      'bg-light-gray hover:bg-light-gray-hover active:bg-light-gray-clicked text-black-blue',
    danger:
      'bg-danger-red hover:bg-danger-red-hover active:bg-danger-red-clicked text-white',
    pink: 'bg-pink hover:bg-pink active:bg-pink text-white',
    demo: 'bg-custom-blue hover:bg-blue active: bg-blue text-white',
    gray: 'bg-custom-gray hover: bg-gray active: bg-gray text-dark-blue',
    disable: 'bg-custom-gray-2 text-medium hover:bg-gray active:bg-gray',
    teal: 'bg-[#11D0B0] text-dark-blue font-bold',
    dangerLight: 'bg-[#CD2C2C1A] text-[#CD2C2C]',
    white: 'bg-white text-black hover:bg-gray-100',
    darkBlue: 'bg-[#031931] text-white',
    gulfBlue: 'bg-dark-blue text-white'
  }[variant]

  const sizeClasses =
    size === 'md'
      ? 'px-4 py-[14px] text-md font-semibold'
      : size === 'sm'
      ? 'px-8 py-[14px] text-sm sm:text-md w-[164px]'
      : size === 'lg'
      ? 'px-6 py-[14px] text-lg font-semibold'
      : ''

  const disabledClasses =
    'disabled:bg-light-gray disabled:text-disabled disabled:pointer-events-none'
  const tracking = useTracking()
  const handleClick = () => {
    if (trackBtnClick) {
      tracking.trackEvent({
        event_name: convertToSlug(String(children)),
        page_url: window.location.pathname,
        event_loggable_type: "RmcEvents::RmcEvent",
        additional_attributes: { event_type: 'click_event' }
      })
    }
    if (onClick) {
      onClick()
    }
  }
  return (
    <button
      className={`inline-block rounded-xl transition duration-300 focus:outline-none ${disabledClasses} ${variantClasses} ${sizeClasses} ${className}`}
      {...rest}
      onClick={() => handleClick()}
      disabled={isLoading || disabled}
    >
      {children}
    </button>
  )
}

export default Button
