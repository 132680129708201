import Button from '@/components/atoms/buttons/Button';
import { useToken } from '@/components/atoms/forms/Form';
import Input from '@/components/atoms/inputs/Input';
import Layout from '@/components/organisms/layouts/Layout';
import { router, useForm } from '@inertiajs/react';
import { SharedPageProps } from './SignIn';
import axios from 'axios';

type UpdatePasswordProps = SharedPageProps & {};

export default function UpdatePassword({ flash }: UpdatePasswordProps) {
  const { data, setData, transform, put } = useForm({
    password: '',
    password_confirmation: '',
  });
  const token = useToken();

  const handleChange = (event) => {
    setData(event.target.name, event.target.value);
  };

  const isPasswordValid = () => {
    return (!data.password.trim() || !data.password_confirmation.trim() || data.password.length < 6 || data.password_confirmation.length < 6)
  }

  // @ts-ignore
  // transform((data) => ({
  //   authenticity_token: token,
  //   user: {
  //     ...data,
  //     reset_password_token: window.location.search.split('=')[1],
  //   },
  // }));

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   put('/users/password');
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.put(
        '/users/password',
        {
          user: {
            ...data,
            reset_password_token: window.location.search.split('=')[1],
          },
        },
        {
          headers: {
            'X-CSRF-Token': token,
          },
        }
      );

      if (response.status === 200) {
        window.location.href = '/login'
      }
    } catch (error) {
      // Handle errors
      console.error(error);
    }
  };

  return (
    <Layout>
      <div className="flex w-full flex-1 flex-col md:max-w-[600px]">
        <form className="flex flex-1 flex-col" onSubmit={handleSubmit}>
          <div className="flex h-full flex-1 flex-col justify-between lg:justify-start">
            <div className="mb-4 flex flex-col">
              <div className="mb-8 flex flex-col text-5xl font-bold md:mb-12 md:flex-row md:gap-2 lg:mb-[60px]">
                <p>Set or reset your password</p>
              </div>
              <div className="space-y-4">
                <Input
                  isPassword
                  label="New Password"
                  autoComplete="email"
                  type="password"
                  value={data.password}
                  name="password"
                  onChange={handleChange}
                  id="password"
                  required
                />
                <p className='text-xs'>Password should be at least 6 characters long</p>
                <Input
                  isPassword
                  label="Confirm Password"
                  autoComplete="email"
                  type="password"
                  value={data.password_confirmation}
                  name="password_confirmation"
                  onChange={handleChange}
                  required
                />
              </div>
              {flash?.alert && (
                <p className="mt-4 mb-2 text-[13px] text-danger-red">
                  {flash.alert}
                </p>
              )}
            </div>

            <div className="flex flex-col space-y-4 md:flex-row-reverse md:space-y-0">
              <Button
                variant="primary"
                type="submit"
                size="md"
                className="block h-full w-full md:ml-4"
                disabled={isPasswordValid()}
              >
                Submit
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
}
