import {SolutionStarRating} from '../../../components/molecules/solution/SolutionStarRating'
import minifyString from '@/utils/minifyString'
import { MdChevronRight } from 'react-icons/md'
import solutionLogoPlaceholder from '../../../../assets/images/solution-logo-placeholder.svg'
import HybridLink from '@/components/atoms/widgets/HybridLink'

type FeaturedSolutionTickerCardProps = {
  solution: schema.Solution
}

const FeaturedSolutionTickerCard = ({ solution }: FeaturedSolutionTickerCardProps) => {
  return (
    <div className='my-4 mx-2 h-[140px] flex justify-between w-[200px] md:w-[240px] sm:h-[220px] flex-col items-center rounded-xl bg-white shadow-card'>
      <div className='my-4 mx-2 flex items-center'>
        <div className='w-[40%] md:w-[30%]'>
          <img src={solution.logo_url || solutionLogoPlaceholder} alt={'logo'} loading='lazy' />
        </div>
        <div className='mx-4 flex w-[60%] md:w-[70%] flex-col gap-[3px] md:gap-1.5'>
          <h3 className='text-[12.56px] font-bold lg:text-[15px]'>{minifyString(solution.name, 13, 13, true)}</h3>
          <div className='flex flex-row items-center'>
            <SolutionStarRating showDetails={false} rating={solution.rating} ratingCount={solution.ratings_count} />
            <p className='pl-2 text-[8px] font-semibold text-dark-blue lg:text-[10.8px]'>{solution.rating}</p>
          </div>
          <div className='text-[8px] font-semibold text-gray-500 lg:text-[10.8px]'>
            ({solution.ratings_count || 0} ratings)
          </div>
        </div>
      </div>
      <div className='flex flex-col px-3 justify-between h-auto sm:h-full mx-4 text-start mb-4 w-full'>
        <p className='hidden sm:flex text-xs mb-2 h-[70px]'>
          {minifyString(solution.description, 90, 90, true)}
        </p>
        <HybridLink href={`/solutions/${solution.slug}`}>
          <div className='flex w-full sm:w-auto gap-2 items-center justify-center bg-ocean-blue text-white px-2 py-1 cursor-pointer rounded-lg'>
            <p className='text-xs'>View Profile</p>
            <MdChevronRight />
          </div>
        </HybridLink>
      </div>
    </div>
  )
}

export default FeaturedSolutionTickerCard
