import ga4 from 'react-ga4'
import { event } from '@fullstory/browser'

export const handleSignUp = (eventName, label) => {
  const page = window.location.href.split('?')[0]

  ga4.event(eventName, {
    category: 'User Interaction',
    label: label,
    conversionPage: page,
  })
  event('Sign up button', {
    buttonLabel: eventName,
    conversionPage: page,
  })
}
