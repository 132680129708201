import { useEffect } from 'react';

export default function useOutsideClick(ref, isVisible, cb) {
  useEffect(() => {
    const handleClick = (e) => {
      if (ref.current && !ref.current.contains(e.target) && isVisible) {
        cb();
      }
    };

    document.addEventListener('click', handleClick);

    return () => document.removeEventListener('click', handleClick);
  }, [cb, isVisible, ref]);
}
