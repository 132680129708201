import { Fragment } from 'react'

import { Dialog, Transition } from '@headlessui/react'
import { IoClose } from "react-icons/io5"
type ModalProps = {
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
  className?: string
  closeStyle?: string
  backdropStyle?: string
  showCloseButton?: boolean
}

function Modal({
  isOpen,
  onClose,
  children,
  className,
  closeStyle,
  showCloseButton = true,
  backdropStyle,
}: ModalProps) {
  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        as='div'
        className='fixed inset-0 z-50 overflow-y-auto'
        onClose={onClose}
      >
        <div
          className={`flex h-screen items-center justify-center ${backdropStyle}`}
        >
          <Dialog.Overlay className='fixed inset-0 bg-black opacity-50' />

          <div
            className={`relative rounded-lg md:mx-auto ${className} m-0 p-0`}
          >
            {showCloseButton && (
              <button
                type='button'
                className={`absolute right-8 text-gray-500 outline-none focus:ring-0 hover:text-gray-700 ${closeStyle}`}
                onClick={onClose}
              >
              <IoClose className='text-[25px]'/>
              </button>
            )}
            {children}
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default Modal
