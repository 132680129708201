import React, { useState, useRef, useEffect } from 'react';
import Input from '@/components/inputs/Input';
import Form, { useToken } from '@/components/Form';
import { Dropdown } from '@/components/Dropdown';
import axios from 'axios';
import { solutionOptions } from '@/components/constants/solutionOptions';
import { ToastContainer, toast } from 'react-toastify';
import { useForm, Link, Head, router } from '@inertiajs/react';
import Button from '@/components/Button';
import PromotionFormSection from '../../molecules/promotion/PermotionFormSection';

type SolutionShowProps = {
  solution: schema.Solution;
  current_user?: schema.User;
  company: schema.Company;
  formPath: string;
  logo_url: string;
  promotions: schema.Promotion[];
};

export default function PromotionForm({ ...props }: SolutionShowProps) {
  const { solution, promotions } = props;

  return (
    <div>
      <PromotionFormSection
        solution={solution}
        promotions={promotions}
        // formSections={formSections}
      />
    </div>
  );
}
