import React from 'react';
import { HubspotForm } from 'react-hubspot-forms';

export default function Subscribe() {
  return (
    <div className="top-[-541px] left-[-375px] h-[680px] w-[340px] rounded-lg bg-[#28CFB0] p-6">
      <div className="h-[603px] w-[300px] gap-4">
        <div className="mb-2 h-[auto] w-[300px] gap-8 pb-2">
          <h1 className="font-manrope leading-24 h-[auto] w-[300px] gap-2 text-center text-xl font-semibold tracking-normal text-dark-gray">
            Subscribe to the Recruitment Marketing Newsletter.
          </h1>
          <p className="font-manrope mt-2 h-[auto] w-[300px] text-center text-sm font-normal leading-5 tracking-normal text-dark-gray">
            Stay up-to-date on the latest recruitment marketing insights,
            trends, events and so much more!
          </p>
        </div>
        <div className="h-[44px] w-[300px] gap-3">
          <HubspotForm
            portalId="40821844"
            formId="f8af0037-066c-4ecd-a018-3107c692bd10"
            region="na1"
          />
        </div>
      </div>
    </div>
  );
}
