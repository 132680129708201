import React, { useState } from 'react';
import Modal from '../../atoms/modals/Modal';
import Input from '../../atoms/inputs/Input';
import Fuse from 'fuse.js';
import { FilterStarDisplay } from '../../atoms/filters/FilterSidebar';
import solutionLogoPlaceholder from '../../../../assets/images/solution-logo-placeholder.svg';
import AddIcon from '../../../../assets/images/add-icon.svg';
import axios from 'axios';
import { router } from '@inertiajs/react';

export type AddSolutionsModalProps = {
  allSolutions: schema.Solution[];
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  openModal: boolean;
  solutionsToCompare: schema.Solution[];
  setSolutionsToCompare: React.Dispatch<
    React.SetStateAction<schema.Solution[]>
  >;
  compareSolutionProps: [];
  setCompareSolutionProps: React.Dispatch<React.SetStateAction<any[]>>;
  similarSolutions: schema.Solution[];
};

const AddSolutionsModal = ({
  allSolutions,
  openModal,
  setOpenModal,
  similarSolutions,
  solutionsToCompare,
  setSolutionsToCompare,
  compareSolutionProps,
  setCompareSolutionProps,
}: AddSolutionsModalProps) => {
  const [searchedSolutions, setSearchedSolutions] = useState([]);
  const [similarSols, setSimilarSols] = useState(
    similarSolutions?.flat()?.filter((solution) => {
      return !solutionsToCompare.some(
        (solToComp) => solToComp.id === solution.id
      );
    })
  );
  const [searchTerm, setSearchTerm] = useState('');
  const getSlugsOfSolutions = (solutions) => {
    const ids = solutions.map((s) => {
      return s.slug;
    });
    return ids;
  };

  const updateRoute = (solutions) => {
    if (solutions.length > 0) {
      router.replace(
        `/solutions/compare_solution/${getSlugsOfSolutions(solutions)}`,
        {
          preserveState: true,
          preserveScroll: true,
        }
      );
    } else {
      router.replace(`/`);
    }
  };

  const getCompareSolutionProps = async (solution) => {
    const response = await axios.get(
      `/solutions/${solution.id}/get_additional_props`
    );
    return response.data;
  };

  const addSolutionToCompare = async (solution) => {
    const tempSimilarSolutions = similarSols?.filter(
      (sol) => sol.id !== solution.id
    );
    setSimilarSols(tempSimilarSolutions);
    const tempSearchedSolutions = searchedSolutions?.filter(
      (sol) => sol.id !== solution.id
    );
    setSearchedSolutions(tempSearchedSolutions);
    const solutions = [...solutionsToCompare, solution];
    setSolutionsToCompare((prevSolutions) => {
      const updatedSolutions = [...prevSolutions, solution];
      localStorage.setItem('solutionsToCompare', JSON.stringify(updatedSolutions));
      return updatedSolutions;
    });
    const additionalProps = await getCompareSolutionProps(solution);
    const data = {
      categories: additionalProps?.categories,
      solution: additionalProps?.solution,
      company: additionalProps?.company,
      features: additionalProps?.features,
      similar_solutions: additionalProps?.similar_solutions,
      rmc_awards: additionalProps?.rmc_awards,
      is_claimed: additionalProps?.is_claimed,
      rating: additionalProps?.rating,
      ratings_count: additionalProps?.ratings_count,
      description: additionalProps?.description,
      additional_features: additionalProps?.additional_features,
      integrations: additionalProps?.integrations,
      pricing: { starting_cost: additionalProps?.pricing?.starting_cost, pricing_details: additionalProps?.pricing?.pricing_details },
      customers: additionalProps?.customers,
      master_features: additionalProps?.master_features
    };
    const updatedProps = [...compareSolutionProps, data];
    setCompareSolutionProps(updatedProps);
    if (solutions.length === 4) {
      clear();
      updateRoute(solutions);
    }
  };

  const clear = () => {
    setSearchTerm('');
    setSearchedSolutions([]);
    setOpenModal(false);
  };

  const handleInputChange = (e) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
    if (e.target.value === '') {
      setSearchedSolutions([]);
    } else {
      const options = {
        keys: ['name'],
        includeScore: true,
        threshold: 0.3,
        shouldSort: true,
        findAllMatches: true,
        minMatchCharLength: 1,
        tokenize: true,
        matchAllTokens: true,
      };
      const solutions = new Fuse(allSolutions, options);
      const solutionResults = [];

      const filterStartsWithQuery = (query, results) => {
        return results?.filter((result) => {
          return result.name
            .toLowerCase()
            .split(' ')
            .some((word) => {
              return (
                word.startsWith(query.toLowerCase()) ||
                query.toLowerCase().startsWith(word)
              );
            });
        });
      };
      const newSearchTerm = e.target.value;
      [newSearchTerm].forEach((query) => {
        const fuseResults = solutions
          .search(query)
          .map((result) => result.item);
        const output = filterStartsWithQuery(query, fuseResults);
        if (output.length === 0) {
          const results = solutions.search(query);
          const output = results.map((result) => result.item);
          solutionResults.push(...output);
        } else {
          solutionResults.push(...output);
        }
      });
      setSearchedSolutions(
        solutionResults?.filter(
          (solution) =>
            !solutionsToCompare.some(
              (solutionToComp) => solutionToComp.id === solution.id
            )
        )
      );
    }
  };

  return (
    <Modal
      isOpen={openModal}
      onClose={() => {
        clear();
        updateRoute(solutionsToCompare);
      }}
      closeStyle="top-10 right-10"
      className="mx-4 w-full rounded-xl bg-white p-10 md:mt-0 md:w-[600px]"
    >
      <div className="flex flex-col">
        <h1 className="mb-8 justify-start  text-2xl font-bold ">
          Add Solutions
        </h1>
      </div>
      <div className="relative flex flex-col gap-4">
        <p>Search for solutions</p>
        <Input
          type="text"
          placeholder="Search by solution name"
          value={searchTerm}
          search={true}
          onChange={(e) => handleInputChange(e)}
        />
        {searchedSolutions.length > 0 && (
          <div className="absolute inset-x-0 top-[99px] z-10 flex max-h-[256px] flex-col overflow-scroll rounded-xl border-[1px] border-medium-gray bg-white p-4">
            {searchedSolutions?.map((solution, index) => {
              return (
                <div
                  className="flex cursor-pointer items-center gap-2 px-[14px] py-2 hover:bg-custom-gray-2"
                  onClick={() => {
                    setSearchTerm(solution?.name);
                    addSolutionToCompare(solution);
                  }}
                >
                  <img
                    src={solution?.logo_url || solutionLogoPlaceholder}
                    alt="logo"
                    className="h-[32px] w-[32px]"
                  />
                  <p className="text-md break-all">{solution.name}</p>
                </div>
              );
            })}
          </div>
        )}
        <p>Or add a similar solution:</p>
        {similarSols.length > 0 && (
          <div className="flex max-h-[300px] cursor-pointer flex-col gap-3 overflow-scroll rounded-xl shadow-card">
            {similarSols?.map((sol) => {
              return (
                <div
                  className="flex items-center  justify-between rounded-xl px-4 py-5 shadow-card"
                  onClick={() => addSolutionToCompare(sol)}
                >
                  <div className="flex items-center gap-4">
                    <img
                      src={sol?.logo_url || solutionLogoPlaceholder}
                      alt="sol-logo"
                      className="h-[72px] w-[72px]"
                    />
                    <div className="">
                      <p>{sol.name}</p>
                      <FilterStarDisplay
                        count={Number(sol.rating) - 1}
                        hideUp={true}
                        starBlue={true}
                      />
                    </div>
                  </div>
                  <img src={AddIcon} alt="add-icon" className="h-6 w-6" />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </Modal>
  );
};
export default AddSolutionsModal;
