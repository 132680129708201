import { Bars } from 'react-loader-spinner'

export const Loader = ({ headline }) => {
  return(
    <div className='flex flex-col items-center justify-center my-2'>
      <p className='text-xl font-bold'>
        {headline}
      </p>
      <Bars
        height='50'
        width='50'
        color='#12D9B8'
        ariaLabel='bars-loading'
        visible={true}
      />
    </div>
  )
}