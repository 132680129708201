import { Dispatch, SetStateAction } from 'react';
import { Link } from '@inertiajs/react';
import minifyString from '../../../utils/minifyString';
import { SolutionTag } from '@/components/molecules/solution/SolutionCard';
import Modal from '@/components/atoms/modals/Modal';

export function ExpandedCategories({
  minify,
  wrapperClasses,
  categories,
  categorySlugs,
  setIsExpanded,
  isExpanded,
}: {
  minify: boolean;
  wrapperClasses: string;
  categories: Array<string>;
  categorySlugs: Array<string>;
  solution: schema.Solution;
  setIsExpanded: Dispatch<SetStateAction<boolean>>;
  isExpanded: boolean;
}) {

  const handleCollapse = (event) => {
    event.preventDefault();
    setIsExpanded(false);
  };

  const solutionTagClasses = `mr-2 flex items-center gap-[10px] rounded-full 
                              bg-blue-tint-1 border border-light-gray px-[12px] text-[10px] 
                              py-[4px] font-Manrope text-xs font-semibold leading-[18px] 
                              text-dark-blue`
  return (
    <Modal isOpen={isExpanded} onClose={() => setIsExpanded(false)} className='bg-white px-10 py-10'>
      <h1>All Categories</h1>
      <div className={wrapperClasses}>
        {categories.map((tag, index) => (
          <Link
            href={`/categories/${categorySlugs[index]}`}
            className='snap-center mt-2'
            key={`${tag + '-' + index}`}
          >
            <SolutionTag
              key={`${tag + '-' + index}`}
              classes={`${solutionTagClasses} hover:bg-dark-blue hover:text-light-gray`}
            >
              {minify ? minifyString(tag, 30, 33) : tag}
            </SolutionTag>
          </Link>
        ))}
      </div>
    </Modal>
  );
}
