import { maxDocumentFileSize, maxImageFileSize, maxVideoFileSize, validDocumentFileTypes, validImageFileTypes, validVideoFileTypes } from "./constants"

export const imageValidation = (
  file,
  maxSize = maxImageFileSize,
) => {
  const  validFileTypes =  validImageFileTypes
  const maxFileSizeInBytes = maxSize * 1024 * 1024
  return validateFile(file, 'Image', validFileTypes, maxFileSizeInBytes)
}

export const documentValidation = (
  file,
  maxSize = maxDocumentFileSize
) => {
  const validFileTypes = validDocumentFileTypes
  const maxFileSizeInBytes = maxSize * 1024 * 1024
  return validateFile(file, 'Document', validFileTypes, maxFileSizeInBytes)
}

export const videoValidation = (
  file,
  maxSize = maxVideoFileSize
  ) => {
  const validFileTypes = validVideoFileTypes
  const maxFileSizeInBytes = maxSize * 1024 * 1024
  return validateFile(file, 'Video', validFileTypes, maxFileSizeInBytes)
}

const validateFile = (file, fileType, allowedFileTypes, maxFileSize) => {
  let errors = []
  if(file.size > maxFileSize)
    errors.push(`Invalid ${fileType} size! ${fileType} size should not exceed ` + maxFileSize/(1024*1024) + ` Mb`)
  if(!allowedFileTypes.includes(file.type))
    errors.push(`Invalid ${fileType} Type!`)
  return errors
}