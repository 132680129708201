import { Menu } from '@headlessui/react'
import clsx from 'clsx'
import { FaChevronDown } from "react-icons/fa"
import { sortOptionsAbbreviations } from '../../atoms/constants/solutionOptions'

export type SortMenuProps = {
  options: { label: string; value: string }[]
  selected: string
  onChange: (value: string) => void
}

export function SortMenu({ options, selected, onChange }: SortMenuProps) {
  return (
    <Menu className='relative' as='div'>
      <Menu.Button className='relative block w-full rounded-[4px] bg-dark-gray px-2 py-1 sm:px-0 sm:py-[2px] text-left'>
        {({ open }) => (
          <div className='text-[14px] text-[#747474] flex items-center justify-between sm:justify-center gap-2 py-[1px] sm:min-w-[140px] sm:max-w-[140px]'>
            <div>Sort: {sortOptionsAbbreviations[selected]}</div>
            <FaChevronDown className={`text-[#747474] w-[16px] self-center ${open ? 'rotate-180' : ''}`} />
          </div>
        )}
      </Menu.Button>
      <Menu.Items
        className={clsx(
          'absolute right-0 z-10 mt-2 flex w-44 origin-top-right flex-col',
          'gap-x-5 gap-y-4 rounded-xl bg-light-gray py-4 shadow-card ring-1 ring-black/5 focus:outline-none',
          'px-4'
        )}
      >
        {options.map((option) => {
          const isSelected = option.value === selected
          return (
            <Menu.Item key={option.value}>
              {({ active }) => (
                <div
                  className={clsx(
                    'flex w-full gap-3 rounded-md text-xs font-medium hover:bg-light-gray-hover-2 active:bg-light-gray-hover-2',
                    active && 'bg-light-gray-hover-2'
                  )}
                  onClick={() => onChange(option.value)}
                >
                  <div className='flex size-4 items-center justify-center rounded-full border border-medium-gray'>
                    <div
                      className={clsx(
                        'size-3 rounded-full',
                        isSelected && 'bg-check-blue'
                      )}
                    />
                  </div>
                  {option.label}
                </div>
              )}
            </Menu.Item>
          )
        })}
      </Menu.Items>
    </Menu>
  )
}
