import Modal from '@components/atoms/modals/Modal'

import Button from '@/components/atoms/buttons/Button'
import { useEffect } from 'react'
import window from 'global'
import { confirmationModalCtaText } from '@/utils/constants'

type ConfirmationModalProps = {
  openConfirmationModal: boolean
  setOpenConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>
  title: string
  description: string
  redirectUrl?: string
}

const ConfirmationModal = ({
  openConfirmationModal,
  setOpenConfirmationModal,
  title,
  description,
  redirectUrl = ''
}: ConfirmationModalProps) => {

  const redirectToUrl = () => {
    if(redirectUrl !== ''){
      window.location.href = redirectUrl
    } else {
      window.location.reload()
    }
  }

  useEffect(() => {
    if (openConfirmationModal) {
      const timer = setTimeout(() => {
        setOpenConfirmationModal(false)
        redirectToUrl()
      }, 5000)
      return () => clearTimeout(timer)
    }
  }, [openConfirmationModal])

  return (
    <Modal
      isOpen={openConfirmationModal}
      onClose={() => {
        setOpenConfirmationModal(!openConfirmationModal)
        redirectToUrl()
      }}
      closeStyle='top-5 right-4'
      className='size-auto gap-5 rounded-xl bg-white px-9 pb-6 pt-12 md:w-[800px]'
    >
      <div className='flex flex-col gap-5'>
        <h1 className='text-center text-lg font-bold sm:text-2xl'>{title}</h1>
        <h1 className='flex text-center text-sm sm:text-start md:text-md'>{description}</h1>
        <div className='flex justify-center sm:justify-end'>
          <Button variant='teal' size='md' onClick={() => {
             setOpenConfirmationModal(!openConfirmationModal)
             redirectToUrl()
            }}
          >
            {confirmationModalCtaText()} 
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmationModal
