import React, { useEffect, useRef } from 'react'
import { Popover } from '@headlessui/react' 
import clsx from 'clsx'

import Navbar from './Navbar'
import { useTracking } from 'react-tracking';
import FooterSection from '../footerSection/FooterSection'
// import { ToastContainer } from 'react-toastify'
// import closeIcon from '../../../../assets/images/close-icon-black.svg'

type LayoutProps = 
{
  children: React.ReactNode
  noPadding?: boolean
  hideNav?: boolean
  hideDropdown?: boolean
  setHideDropdown?: React.Dispatch<React.SetStateAction<boolean>>
}

export default function Layout({
  children,
  noPadding = false,
  hideNav = false,
  hideDropdown = false,
  setHideDropdown
}: LayoutProps) {
  const tracking = useTracking()
  const location = useRef(window.location.pathname)
  useEffect(() => {
    tracking.trackEvent({
      event_name: 'page_load',
      page_url: window.location.pathname,
      event_loggable_type: "RmcEvents::RmcEvent",
      additional_attributes: { event_type: 'page_load' }
    })
  }, [location])

  return (
    <>
      {/* <ToastContainer
        closeButton={<img src={closeIcon} className="h-6 w-6" alt="close" />}
      /> */}
      <Popover className={clsx(`${hideNav ? 'hidden' :'flex flex-col'}`)}>
        {({ open }) => <Navbar open={open} hideDropdown={hideDropdown} setHideDropdown={setHideDropdown}/>}
      </Popover>
      <div
        className={clsx(
          'flex flex-1 flex-col mt-[52px] sm:mt-[78px]',
          !noPadding && 'items-center px-4 pt-12 pb-10 sm:py-[60px]'
        )}
      >
        {children}
      </div>
      <FooterSection />
    </>
  )
}
