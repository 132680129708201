import { lazy } from 'react'

import { extractDate, getArticleUrl } from '@/utils/helperFunctions'

import { CustomDots, NextArrow, PrevArrow } from '@/components/atoms/widgets/Carousel'

import { useMobileDetect } from '@/utils/detectMobile'

import he from 'he'
const Slider = lazy(() => import('@ant-design/react-slick'))
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import ArticleCard from '@/components/atoms/Article/ArticleCard'
import HybridLink from '@/components/atoms/widgets/HybridLink'
import default_article from '../../../../assets/images/default_image.png'
import TalivityLogo from '../../../../assets/images/talivity-logo.webp'
import arrow from '../../../../assets/images/arrow-icon.png'

const prevArrowBaseClasses = 'mt-2 z-10 sm:z-0 absolute left-[77%] xl:left-[82%] lg:left-[84%] md:left-[82%] sm:left-[91%] xl:top-[100%] sm:w-[60px] sm:h-[60px] lg:top-[100%] md:top-[99%] top-[101%] '
const nextArrowBaseClasses = 'mt-2 absolute left-[84%] xl:left-[85%] lg:left-[88%] md:left-[87%] xl:top-[100%] sm:left-[96%] sm:w-[60px] sm:h-[60px] lg:top-[100%] md:top-[99%] top-[101%] '
const dotsBaseClasses = 'absolute left-[-35%] xl:left-[-32.7%] lg:left-[-39%] md:left-[-38%] top-[100%] '

type ArticlesSliderProps = {
  articles: schema.Articles[],
  showGradient?: boolean,
  prevArrowClasses?: string,
  nextArrowClasses?: string,
  dotsClasses?: string
}

export const ArticlesSlider = ({ 
  articles,
  showGradient = false,
  prevArrowClasses,
  nextArrowClasses,
  dotsClasses
  }: ArticlesSliderProps ) => {
  const isMobile = useMobileDetect({screenSize: 550})
  const isDesktop = useMobileDetect({screenSize: 1350})
  const isTab = useMobileDetect({screenSize: 1024})
  const isBetweenMobAndTab = useMobileDetect({screenSize: 850})

  const articlesSliderSettings = {
    variableWidth: true,
    autoPlay: false,
    infinite: true,
    dots: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow customClasses={prevArrowBaseClasses + prevArrowClasses} />,
    nextArrow: <NextArrow customClasses={nextArrowBaseClasses + nextArrowClasses} />,
    appendDots: (dots) => <CustomDots dots={dots} customClasses={dotsBaseClasses + dotsClasses} />,
    centerMode: true,
    centerPadding: isMobile ? '0%' : isBetweenMobAndTab ? '17%' : isTab ? '15%' : isDesktop ? '0%' : '8px'
  }

  return (
    <div className='w-[100%] max-w-[1260px] mx-auto article-section-container'>
      { showGradient && 
        <>
          <div className='gradient-left'></div>
          <div className='gradient-right'></div>
        </>
      }
      <Slider {...articlesSliderSettings}>
        { articles.map((article, index) => (
          <HybridLink href={getArticleUrl(article)} key={index}>
            <ArticleCard
              arrow={arrow}
              image={article.image ? article.image : default_article}
              authorImage={TalivityLogo}
              authorName={article.contributor_title}
              title={article.title ? he.decode(article.title) : ''}
              category={article.category_title}
              date={extractDate(article.published_date)}
              duration={article.min_read ? he.decode(article.min_read) : ''}
              key={index}
            />
          </HybridLink>
        ))}
      </Slider>
    </div>
  )
}