import React, { lazy, startTransition, useEffect, useState } from 'react'

import { Link } from '@inertiajs/react'
import clsx from 'clsx'
import { FaChevronDown as Chevron } from 'react-icons/fa6'
import { ThreeDots } from 'react-loader-spinner'

import SolutionCategoryRankCard from '@/components/organisms/AccountManagement/SolutionCategoryRankCard'
import SolutionCompletenessRow from '@/components/organisms/AccountManagement/SolutionCompletenessRow'
import { getCanvasEmbedToken } from '@/utils/axiosRequests'
import './style.css'
const Canvas = lazy(() =>
  import('canvas-embed').then((module) => ({ default: module.Canvas }))
)

type ProfilePageSolutionCardProps = {
  solution: schema.Solution
}

const ProfilePageSolutionCard = ({
  solution,
}: ProfilePageSolutionCardProps) => {
  const [canvasDashboardToken, setCanvasDashboardToken] = useState(null)
  const [toggleCanvasDashboard, setToggleCanvasDashboard] = useState(false)
  const [isCanvasEmbedLoaded, setIsCanvasEmbedLoaded] = useState(false)
  const [canvasDashboardPrompt, setCanvasDashboardPrompt] = useState('Show Dashboard')

  const canvasId = import.meta.env.VITE_CANVAS_DASHBOARD_ID
  const solutionName = solution.name.toLowerCase().split(' ').join('-')

  useEffect(() => {
    const fetchToken = async () => {
      const token = await getCanvasEmbedToken(solutionName)
      setCanvasDashboardToken(token)
    }
    fetchToken()
  }, [])

  useEffect(() => {
    if (toggleCanvasDashboard && !isCanvasEmbedLoaded) {
      setCanvasDashboardPrompt('Loading Dashboard')
    }
    else if (toggleCanvasDashboard && isCanvasEmbedLoaded) {
      setCanvasDashboardPrompt('Hide Dashboard')
    }
    else {
      setCanvasDashboardPrompt('Show Dashboard')
    }
  }, [toggleCanvasDashboard, isCanvasEmbedLoaded])

  const handleToggleCanvasDashboard = () => {
    startTransition(() => {
      setToggleCanvasDashboard((prevState) => !prevState)
      if (!isCanvasEmbedLoaded) {
        const callback = (mutationsList, obs) => {
          for (const mutation of mutationsList) {
            if (mutation.type === 'childList') {
              const canvasEmbed = document.querySelector(
                `#${solutionName} .canvas-embed`
              )
              if (canvasEmbed) {
                setIsCanvasEmbedLoaded(true)
                obs.disconnect()
                break
              }
            }
          }
        }
        const observer = new MutationObserver(callback)
        observer.observe(document.body, { childList: true, subtree: true })
      } else setIsCanvasEmbedLoaded((prevState) => !prevState)
    })
  }

  return (
    <div className='flex flex-col'>
      <div className='border-custom-gray-2 relative flex w-full flex-col items-center justify-between text-clip rounded-xl border bg-light-gray px-4 py-8 hover:outline hover:outline-1 hover:outline-medium-gray sm:p-8'>
        <div className={`flex w-full justify-between ${toggleCanvasDashboard && 'border-b-[1px] pb-5'}`}>
          <div className='flex items-center pr-3'>
            <img
              className='size-[36px] rounded-full sm:size-[48px]'
              src={solution?.logo_url}
              alt='Solution Logo'
              rel='nofollow'
            />
            <Link
              href={`/solutions/${solution.slug}`}
              className={`mb-0 line-clamp-3 flex cursor-pointer items-center gap-2 break-all pl-3 text-left font-Manrope text-sm font-medium uppercase text-dark-blue underline sm:text-lg sm:font-bold`}
              style={{ letterSpacing: '1px' }}
            >
              <p>{solution.name}</p>
              <Chevron className='-rotate-90' />
            </Link>
          </div>
          <div
            className='flex cursor-pointer items-center justify-center gap-3'
            onClick={canvasDashboardPrompt  != 'Loading Dashboard' ? handleToggleCanvasDashboard : ()=>{}}
          >
            <p
              className={clsx(
                canvasDashboardPrompt != 'Loading Dashboard' && 'font-bold'
              )}
            >
              {canvasDashboardPrompt}
            </p>
            {canvasDashboardPrompt != 'Loading Dashboard' ? (
              <Chevron className={clsx(toggleCanvasDashboard && 'rotate-180')} />
            ) : (
              <ThreeDots
                visible={true}
                height='30'
                width='30'
                color='teal'
                radius='9'
                ariaLabel='three-dots-loading'
              />
            )}
          </div>
        </div>
        {toggleCanvasDashboard && (
          <div className='w-full' id={`${solutionName}`}>
            <SolutionCompletenessRow solution={solution}/>
            <SolutionCategoryRankCard solution={solution}/>
            <Canvas canvasId={canvasId} authToken={canvasDashboardToken} />
          </div>
        )}
      </div>
    </div>
  )
}

export default ProfilePageSolutionCard
