import Checkbox from "../../atoms/widgets/Checkbox"

type ModalChecksProps = {
  isSubToWeeklyNewsletter?: boolean
  setIsSubToWeeklyNewsletter?: React.Dispatch<React.SetStateAction<boolean>>
  isSubToJobsNewsletter?: boolean
  setIsSubToJobsNewsletter?: React.Dispatch<React.SetStateAction<boolean>>
  isAgreeToPrivacyPolicy: boolean
  setIsAgreeToPrivacyPolicy: React.Dispatch<React.SetStateAction<boolean>>
  showWeeklyNewsletterCheckbox?: boolean
  showJobsNewsletterCheckbox?: boolean
  showTermsOfServicesCheckbox?: boolean
}

const ModalChecks = ({
  isSubToWeeklyNewsletter,
  setIsSubToWeeklyNewsletter,
  isSubToJobsNewsletter,
  setIsSubToJobsNewsletter,
  isAgreeToPrivacyPolicy,
  setIsAgreeToPrivacyPolicy,
  showWeeklyNewsletterCheckbox=true,
  showJobsNewsletterCheckbox=false,
  showTermsOfServicesCheckbox=true
}: ModalChecksProps) => {

  return (
    <div className="flex flex-col gap-2">
      { showWeeklyNewsletterCheckbox &&
        <Checkbox
          isChecked={isSubToWeeklyNewsletter}
          setIsChecked={setIsSubToWeeklyNewsletter}
          promptMessage='Sign up to receive our weekly industry news newsletter.'
        />
      }
      {
        showJobsNewsletterCheckbox && 
        <Checkbox
          isChecked={isSubToJobsNewsletter}
          setIsChecked={setIsSubToJobsNewsletter}
          promptMessage='Sign up to receive our weekly talent jobs newsletter.'
        />
      }
      {showTermsOfServicesCheckbox && 
        <Checkbox
          isChecked={isAgreeToPrivacyPolicy}
          setIsChecked={setIsAgreeToPrivacyPolicy}
          promptMessage={
            <>
              Accept our{' '}
              <a
                className="cursor-pointer underline"
                href="https://www.recruitmentmarketing.com/privacy-policy/"
                rel="noopener noreferrer"
                target="_blank"
              >
                terms of service*
              </a>
            </>
          }
        />
      }
    </div>
  )
}

export default ModalChecks
