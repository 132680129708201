import AccountCreationSection from "../accountCreationSection/AccountCreationSection"

const AboutUsCenterDiv = () => {
  return (
    <>
      <AccountCreationSection />
    </>
  )
}

export default AboutUsCenterDiv
