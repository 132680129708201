import Layout from '@/components/organisms/layouts/Layout';
import { Link, router } from '@inertiajs/react';
import React, { useState, useEffect } from 'react';
import CloseIcon from '../../../assets/images/Vector.svg';
import Button from '@/components/atoms/buttons/Button';
import ChevronDown from '../../../assets/images/chevron-down.svg';
import { FilterStarDisplay } from '@/components/atoms/filters/FilterSidebar';
import { SolutionTag } from '@/components/molecules/solution/SolutionCard';
import CheckCircle from '../../../assets/images/check-circle.svg';
import AddIcon from '../../../assets/images/add-icon.svg';
import solutionLogoPlaceholder from '../../../assets/images/solution-logo-placeholder.svg';
import AddSolutionsModal from '@/components/molecules/modals/AddSolutionsModal';

import minifyString from "@/utils/minifyString";
import clsx from "clsx";
import LeadGenerationModal from "@/components/molecules/modals/LeadGenerationModal";
import useCurrentUser from "@/hooks/useCurrentUser";

type CompareSolutionProps = {
  allSolutions: [];
  solutionLogos: [];
  solutionsToCompare: schema.Solution[];
  compareSolutionProps: [];
};

export default function CompareSolution({ ...props }: CompareSolutionProps) {
  const { allSolutions, solutionLogos } = props;
  const [solutionsToCompare, setSolutionsToCompare] = useState(props.solutionsToCompare)
  const [compareSolutionProps, setCompareSolutionProps] = useState(
    props.compareSolutionProps
  );
  const [showRating, setShowRating] = useState(true);
  const [showCategories, setShowCategories] = useState(true);
  const [showRmcAwards, setShowRmcAwards] = useState(true);
  const [showAbout, setShowAbout] = useState(true);
  const [showIntegrations, setShowIntegrations] = useState(true);
  const [showMasterFeatures, setShowMasterFeatures] = useState(true);
  const [showPricing, setShowPricing] = useState(true);
  const [showCustomers, setShowCustomers] = useState(true);
  const [openAddSolutionModal, setOpenAddSolutionModal] = useState(false);
  const [openLeadGenModal, setOpenLeadGenModal] = useState(false);
  const [currentSolution, setCurrentSolution] = useState({
    id: null,
    name: null,
  });

  const currentUser = useCurrentUser();

  const handleViewProfile = async (solution) => {
    router.visit(`/solutions/${solution.slug}`);
  };

  const handleVisitWebsite = (solution) => {
    window.open(solution.website, '_blank');
  };

  const removeSolution = (name) => {
    const solutions = solutionsToCompare.filter((solution) => {
      if (solution.name === name) return false;
      return true;
    });
    if (solutions.length > 0) {
      setSolutionsToCompare(solutions);
      localStorage.setItem('solutionsToCompare', JSON.stringify(solutions))
      const updatedCompareSolutionProps = compareSolutionProps.filter(
        (prop) => {
          if (!prop?.solution) return false;
          if (prop?.solution?.name === name) {
            return false;
          }
          return true;
        }
      );
      setCompareSolutionProps(updatedCompareSolutionProps);
      router.replace(
        `/solutions/compare_solution/${getSlugsOfSolutions(solutions)}`,
        {
          preserveState: true,
          preserveScroll: true,
        }
      );
    } else {
      router.replace(`/`);
    }
  };

  const isDataPresent = (type) => {
    return compareSolutionProps.some(
      (compSolProp) => compSolProp?.[type]?.length > 0
    );
  };

  const isPricingExists = () => {
    return compareSolutionProps.some(
      (compSolProp) => compSolProp["pricing"]?.starting_cost !== null
    );
  };

  const isMasterFeaturesExists = () => {
    return compareSolutionProps.some(
      (compSolProp) => Object.keys(compSolProp["master_features"]).length !== 0
    );
  };

  const isAdditionalFeaturesExists = () => {
    return compareSolutionProps.some(
      (compSolProp) => compSolProp["additional_features"]?.length > 0
    );
  }

  const getSlugsOfSolutions = (solutions) => {
    const ids = solutions.map((s) => {
      return s.slug;
    });
    return ids;
  };

  const handleContactButtonClick = (solutionId, solutionName) => {
    setCurrentSolution({ id: solutionId, name: solutionName });
    setOpenLeadGenModal(true);
  };

  const transformString = (inputString) => {
    if (!inputString) {
      return;
    }

    const matches = inputString.match(/\((.*?)\)/);
    let outputString = "";
    if (matches) {
      const textInParentheses = matches[1];
      inputString = inputString.replace(/\s*\([^)]*\)/, "");
      outputString =
        inputString.replace(/\s+/g, "-") +
        (textInParentheses ? `-${textInParentheses}` : "");
      return outputString.toLowerCase();
    } else {
      inputString = inputString.replace(/\s*\([^)]*\)/, "");
      outputString = String(inputString.replace(/\s+/g, "-"));
      return outputString.toLowerCase();
    }
  };

  const openAddSolutionsModal = () => {
    setOpenAddSolutionModal(true)
  }

  return (
    <Layout noPadding={true} type="menu">
      <div className="flex min-h-[252px] items-center justify-between bg-light-gray py-6 px-4 sm:px-10 sm:py-8 lg:px-20">
        <div className="flex flex-col justify-center">
          <p className="text-[34px] font-bold sm:text-[48px]">
            Compare Solutions
          </p>
          <div className="flex flex-col sm:flex-row">
            {solutionsToCompare.map((sol, index) => {
              return (
                <Link href={`/solutions/${sol.slug}`}>
                  <div className="flex text-dark-blue">
                    <p className="underline">{sol.name}</p>
                    {solutionsToCompare.length - 2 === index ? (
                      <p className="mx-1">and</p>
                    ) : null}
                    {solutionsToCompare.length - 1 === index ||
                    solutionsToCompare.length - 2 === index ? null : (
                      <p className="mr-1">{","}</p>
                    )}
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
        {solutionsToCompare?.length < 4 && (
          <div
            className="hidden sm:block"
            onClick={() => openAddSolutionsModal()}
          >
            <Button variant="primary" className="p-3">
              <div className="mx-2 flex gap-2">
                <img src={AddIcon} alt="" />
                <p className="text-md font-semibold">Add Solutions</p>
              </div>
            </Button>
          </div>
        )}
      </div>
      {solutionsToCompare?.length < 4 && (
        <div
          className="w-full px-4 pt-8 sm:hidden"
          onClick={() => openAddSolutionsModal()}
        >
          <Button variant="primary" className="w-full p-3">
            <div className="mx-2 flex justify-center gap-2">
              <img src={AddIcon} alt="" />
              <p className="text-md font-semibold">Add Solutions</p>
            </div>
          </Button>
        </div>
      )}
      <div className="flex flex-col px-4 py-[60px] sm:px-[40px] lg:px-[80px]">
        <div className="">
          <div
            className={`top-0 z-10 bg-white pb-4 lg:sticky sm:top-[78px] ${
              solutionsToCompare.length === 4 || solutionsToCompare.length === 3
                ? `grid grid-cols-2 ${
                    solutionsToCompare.length === 4
                      ? "sm:grid-cols-2"
                      : "sm:grid-cols-3"
                  }`
                : `grid-cols- grid${solutionsToCompare.length}`
            } lg:flex lg:pb-0`}
          >
            {solutionsToCompare.map((solution, index) => {
              return (
                <div
                  className={clsx(
                    "m-0 w-full border-[1px] flex flex-col p-4 justify-between gap-4",
                    {
                      "col-span-2 border-t-[1px] sm:col-span-1":
                        solutionsToCompare.length === 3 && index === 2,
                    }
                  )}
                >
                  <div className="flex flex-col gap-4">
                    <div className="flex cursor-pointer justify-end">
                      <img
                        src={CloseIcon}
                        alt="close-icon"
                        onClick={() => removeSolution(solution.name)}
                      />
                    </div>
                    <div className="flex flex-col items-center justify-center gap-4">
                      <Link
                        href={`/solutions/${transformString(solution.name)}`}
                      >
                        <img
                          src={
                            solutionLogos[index] ||
                            solution.logo_url ||
                            solutionLogoPlaceholder
                          }
                          alt="solution-logo-url"
                          className="h-[72px] w-[72px] rounded-xl"
                          rel="nofollow"
                        />
                      </Link>
                      <Link
                        href={`/solutions/${transformString(solution.name)}`}
                      >
                        <p className="break-all text-md font-bold uppercase text-center break-all">
                          {solution.name}
                        </p>
                      </Link>
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <Button
                      variant="secondary"
                      onClick={() => handleViewProfile(solution)}
                      size="sm"
                      className="!px-2"
                    >
                      View Profile
                    </Button>
                  </div>
                  
                  <div className="flex justify-center">
                    {compareSolutionProps[index]?.is_claimed ? (
                      <Button
                        variant="primary"
                        onClick={() =>
                          handleContactButtonClick(solution.id, solution.name)
                        }
                        size="sm"
                        className="!px-2"
                      >
                        Contact Solution
                      </Button>
                    ) : (
                      <Button
                        variant="primary"
                        onClick={() => handleVisitWebsite(solution)}
                        size="sm"
                        className="!px-2"
                      >
                        Visit Website
                      </Button>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <div
            className="m-0 flex items-center justify-between rounded-t-xl border-[1px] bg-light-gray py-3 px-4 lg:rounded-none  "
            onClick={() => {
              setShowRating(!showRating);
            }}
          >
            <p>Rating</p>
            <img
              src={ChevronDown}
              alt=""
              className={`${showRating ? "rotate-180" : "rotate-0"}`}
            />
          </div>
          <div
            className={`grid ${
              solutionsToCompare.length === 4
                ? "sm:grid-cols-2"
                : `sm:grid-cols-${solutionsToCompare.length}`
            } lg:flex`}
          >
            {showRating &&
              solutionsToCompare.map((solution, index) => {
                return (
                  <div className="flex w-full gap-3 border-[1px] px-4 py-6">
                    <img
                      src={
                        solutionLogos[index] ||
                        solution.logo_url ||
                        solutionLogoPlaceholder
                      }
                      alt="solution-logo"
                      className={`h-[48px] w-[48px] rounded-xl lg:hidden ${
                        solutionsToCompare?.length === 4 ? "" : "sm:hidden"
                      }`}
                    />
                    <div className="flex flex-col">
                      <p
                        className={`break-all text-sm font-bold uppercase lg:hidden ${
                          solutionsToCompare?.length === 4 ? "" : "sm:hidden"
                        }`}
                      >
                        {solution.name}
                      </p>
                      {
                        compareSolutionProps[index]?.ratings_count > 0 ? (
                          <div className="flex items-center justify-start gap-1">
                            <FilterStarDisplay
                              count={Number(compareSolutionProps[index]?.rating) - 1}
                              hideUp={true}
                              starBlue={true}
                            />
                            <p className="text-sm font-bold text-dark-blue">
                              {compareSolutionProps[index]?.rating}
                            </p>
                            <p className="text-[13px]">
                              ({compareSolutionProps[index]?.ratings_count})
                            </p>
                          </div>
                        ) : (
                          <p className="text-gray-400 text-semibold sm:text-md text-sm">Unreviewed</p>
                        )
                      }
                    </div>
                  </div>
                );
              })}
          </div>
          {isDataPresent("categories") && (
            <div
              className="flex items-center justify-between border-[1px] bg-light-gray py-3 px-4  "
              onClick={() => {
                setShowCategories(!showCategories);
              }}
            >
              <p>Categories</p>
              <img
                src={ChevronDown}
                alt=""
                className={`${showCategories ? "rotate-180" : "rotate-0"}`}
              />
            </div>
          )}
          <div
            className={`grid ${
              solutionsToCompare.length === 4
                ? "sm:grid-cols-2"
                : `sm:grid-cols-${solutionsToCompare.length}`
            } lg:flex`}
          >
            {showCategories &&
              isDataPresent("categories") &&
              solutionsToCompare.map((solution, index) => {
                return (
                  <div className="flex w-full flex-col gap-5 border-[1px] px-4 py-6">
                    <div className="flex items-center gap-3">
                      <img
                        src={
                          solutionLogos[index] ||
                          solution.logo_url ||
                          solutionLogoPlaceholder
                        }
                        alt="solution-logo"
                        className={`h-[48px] w-[48px] rounded-xl lg:hidden ${
                          solutionsToCompare?.length === 4 ? "" : "sm:hidden"
                        }`}
                      />
                      <p
                        className={`break-all text-sm font-bold uppercase lg:hidden ${
                          solutionsToCompare?.length === 4 ? "" : "sm:hidden"
                        }`}
                      >
                        {solution.name}
                      </p>
                    </div>
                    <div className="flex flex-wrap justify-start">
                      {compareSolutionProps[index]?.categories?.map(
                        (category) => {
                          return (
                            <Link
                              href={`/categories/${transformString(category)}`}
                              className="mr-1 mb-2"
                            >
                              <SolutionTag
                                key={`${category + "-" + index}`}
                                classes="mr-2 flex items-center gap-[10px] rounded-full bg-blue-tint-2 px-[12px] py-[2px] font-Manrope text-xs font-semibold leading-[18px] text-dark-blue hover:bg-dark-blue hover:text-light-gray"
                              >
                                {minifyString(category, 22, 23)}
                              </SolutionTag>
                            </Link>
                          );
                        }
                      )}
                    </div>
                  </div>
                );
              })}
          </div>

          {/* About Section */}
          <div
            className="flex items-center justify-between border-[1px] bg-light-gray py-3 px-4"
            onClick={() => {
              setShowAbout(!showAbout);
            }}
          >
            <p>About</p>
            <img
              src={ChevronDown}
              alt=""
              className={`${showAbout ? "rotate-180" : "rotate-0"}`}
            />
          </div>
          <div
            className={`grid ${
              solutionsToCompare.length === 4
                ? "sm:grid-cols-2"
                : `sm:grid-cols-${solutionsToCompare.length}`
            } lg:flex`}
          >
            {showAbout &&
              solutionsToCompare.map((solution, index) => {
                return (
                  <div className="flex w-full flex-col border-[1px]  py-6 px-4">
                    <div className="mb-[20px] flex items-center gap-3">
                      <img
                        src={
                          solutionLogos[index] ||
                          solution.logo_url ||
                          solutionLogoPlaceholder
                        }
                        alt="solution-logo"
                        className={`h-[48px] w-[48px] rounded-xl lg:hidden ${
                          solutionsToCompare?.length === 4 ? "" : "sm:hidden"
                        }`}
                      />
                      <p
                        className={`text-sm font-bold uppercase lg:hidden ${
                          solutionsToCompare?.length === 4 ? "" : "sm:hidden"
                        }`}
                      >
                        {solution?.name}
                      </p>
                    </div>
                    {compareSolutionProps[index]?.description ? (
                      <div className="flex h-full flex-col justify-between gap-3">
                        <p className="text-sm font-[500px]">
                          {compareSolutionProps[index]?.description}
                        </p>
                        <Link
                          href={`/solutions/${transformString(solution.name)}`}
                          className="text-xs text-medium-gray hover:text-black hover:underline"
                        >
                          Learn More
                        </Link>
                      </div>
                    ) : (
                      <div className="flex justify-center">
                        <p className="text-gray-400 text-semibold sm:text-md text-sm mt-3">{`${solution.name} has not provided any information about the solution yet.`}</p>
                      </div>
                    )}
                  </div>
                );
              })}
          </div>

          {/* Master Features */}
          {(isMasterFeaturesExists() || isAdditionalFeaturesExists()) && (
            <div
              className="flex items-center justify-between border-[1px] bg-light-gray py-3 px-4"
              onClick={() => {
                setShowMasterFeatures(!showMasterFeatures);
              }}
            >
              <p>Features</p>
              <img
                src={ChevronDown}
                alt=""
                className={`${showMasterFeatures ? "rotate-180" : "rotate-0"}`}
              />
            </div>
          )}
          <div
            className={`grid ${
              solutionsToCompare.length === 4
                ? "sm:grid-cols-1"
                : `sm:grid-cols-${solutionsToCompare.length}`
            } lg:flex`}
          >
            {showMasterFeatures &&
              (isMasterFeaturesExists() || isAdditionalFeaturesExists()) &&
              solutionsToCompare.map((solution, index) => (
                <div
                  key={index}
                  className="flex w-full flex-col border-[1px] px-4 py-6"
                >
                  <div className="flex items-center gap-3">
                    <img
                      src={
                        solutionLogos[index] ||
                        solution.logo_url ||
                        solutionLogoPlaceholder
                      }
                      alt="solution-logo"
                      className={`h-[48px] w-[48px] rounded-xl lg:hidden ${
                        solutionsToCompare?.length === 4 ? "" : "sm:hidden"
                      }`}
                    />
                    <p
                      className={`break-all text-sm font-bold uppercase lg:hidden ${
                        solutionsToCompare?.length === 4 ? "" : "sm:hidden"
                      }`}
                    >
                      {solution.name}
                    </p>
                  </div>
                  <div className="flex flex-col justify-center gap-[6px]">
                    {Object.entries(
                      compareSolutionProps[index]?.master_features || {}
                    ).length > 0 &&
                      Object.entries(
                        compareSolutionProps[index]?.master_features || {}
                      ).map(([category, master_features]) => (
                        <div key={category}>
                          {master_features.length > 0 && (
                            <>
                              <div className="mt-2">
                                <h5 className="font-bold text-sm">
                                  {category} Features
                                </h5>
                              </div>
                              {master_features.map(
                                (master_feature, featureIndex) => (
                                  <div
                                    key={featureIndex}
                                    className="flex items-center justify-start gap-[6px] text-sm mt-2 ml-2"
                                  >
                                    <img
                                      src={CheckCircle}
                                      alt=""
                                      className="h-[14px] w-[14px]"
                                    />
                                    <p>{master_feature}</p>
                                  </div>
                                )
                              )}
                            </>
                          )}
                        </div>
                      ))}

                    {compareSolutionProps[index]?.additional_features?.length >
                      0 && (
                      <div className="mt-4">
                        <h5 className="font-bold text-sm">
                          Additional Features
                        </h5>
                        {compareSolutionProps[index].additional_features.map(
                          (additional_feature) => (
                            <div
                              className="flex items-center justify-start gap-[6px] text-sm mt-2 ml-2"
                            >
                              <img
                                src={CheckCircle}
                                alt=""
                                className="h-[14px] w-[14px]"
                              />
                              <p>{additional_feature}</p>
                            </div>
                          )
                        )}
                      </div>
                    )}

                    {Object.entries(
                      compareSolutionProps[index]?.master_features || {}
                    ).length < 1 &&
                      compareSolutionProps[index]?.additional_features?.length <
                        1 && (
                        <div className="flex justify-center">
                          <p className="text-gray-400 text-semibold sm:text-md text-sm mt-3">{`${solution.name} has not provided any information about features yet.`}</p>
                        </div>
                      )}
                  </div>
                </div>
              ))}
          </div>

          {/* Our Customers Section */}
          {isDataPresent("customers") && (
            <div
              className="flex items-center justify-between border-[1px] bg-light-gray py-3 px-4  "
              onClick={() => {
                setShowCustomers(!showCustomers);
              }}
            >
              <p>Our Customers</p>
              <img
                src={ChevronDown}
                alt=""
                className={`${showCustomers ? "rotate-180" : "rotate-0"}`}
              />
            </div>
          )}
          <div
            className={`grid ${
              solutionsToCompare.length === 4
                ? "sm:grid-cols-2"
                : `sm:grid-cols-${solutionsToCompare.length}`
            } lg:flex`}
          >
            {showCustomers &&
              isDataPresent("customers") &&
              solutionsToCompare.map((solution, index) => (
                <div className="flex w-full flex-col gap-1 border-[1px] py-6 px-4">
                  <div className="flex items-center gap-3">
                    <img
                      src={
                        solutionLogos[index] ||
                        solution.logo_url ||
                        solutionLogoPlaceholder
                      }
                      alt="solution-logo"
                      className={`h-[48px] w-[48px] rounded-xl lg:hidden ${
                        solutionsToCompare?.length === 4 ? "" : "sm:hidden"
                      }`}
                    />
                    <p
                      className={`break-all text-sm font-bold uppercase lg:hidden ${
                        solutionsToCompare?.length === 4 ? "" : "sm:hidden"
                      }`}
                    >
                      {solution.name}
                    </p>
                  </div>
                  {compareSolutionProps[index]?.customers?.length > 0 ? (
                    <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-3 justify-items-center">
                      {compareSolutionProps[index].customers.map(
                        (customer, customerIndex) =>
                          customer.id ? (
                            <div
                              key={customerIndex}
                              className="flex flex-col items-center justify-center"
                            >
                              <img
                                src={customer.logo_url}
                                alt="download-thumbnail"
                                className="rounded-full lg:w-[3.5rem] lg:h-[3.5rem] w-[3.25rem] h-[3.25rem] sm:mt-[3rem] mt-[2rem] object-cover"
                                loading="lazy"
                              />
                              <p className="sm:mt-4 mt-2 xl:text-[11px] lg:text-[9px] md:text-[9px] text-[9px]">
                                {customer.title}
                              </p>
                            </div>
                          ) : null
                      )}
                    </div>
                  ) : (
                    <div className="flex justify-center mt-3">
                      <p className="text-gray-400 text-semibold sm:text-md text-sm mt-3">{`${solution.name} has not provided any information about their customers yet.`}</p>
                    </div>
                  )}
                </div>
              ))}
          </div>

          {/* Integrations Section */}
          {isDataPresent("integrations") && (
            <div
              className="flex items-center justify-between border-[1px] bg-light-gray py-3 px-4"
              onClick={() => {
                setShowIntegrations(!showIntegrations);
              }}
            >
              <p>Integrations</p>
              <img
                src={ChevronDown}
                alt="chevron-icon"
                className={`${showIntegrations ? "rotate-180" : "rotate-0"}`}
              />
            </div>
          )}
          <div
            className={`grid ${
              solutionsToCompare.length === 4
                ? "sm:grid-cols-1"
                : `sm:grid-cols-${solutionsToCompare.length}`
            } lg:flex`}
          >
            {showIntegrations &&
              isDataPresent("integrations") &&
              solutionsToCompare.map((solution, index) => {
                return (
                  <div className="flex w-full flex-col border-[1px] px-4 py-6">
                    <div className="flex items-center gap-3">
                      <img
                        src={
                          solutionLogos[index] ||
                          solution.logo_url ||
                          solutionLogoPlaceholder
                        }
                        alt="solution-logo"
                        className={`h-[48px] w-[48px] rounded-xl lg:hidden ${
                          solutionsToCompare?.length === 4 ? "" : "sm:hidden"
                        }`}
                      />
                      <p
                        className={`break-all text-sm font-bold uppercase lg:hidden ${
                          solutionsToCompare?.length === 4 ? "" : "sm:hidden"
                        }`}
                      >
                        {solution.name}
                      </p>
                    </div>
                    <div className="flex flex-col justify-center gap-[6px]">
                      {compareSolutionProps[index]?.integrations?.length > 0 ? (
                        compareSolutionProps[index]?.integrations?.map(
                          (integration) => {
                            return (
                              <div className="flex items-center justify-start gap-[6px] text-sm">
                                <img
                                  src={CheckCircle}
                                  alt=""
                                  className="h-[14px] w-[14px]"
                                />
                                <p>{integration}</p>
                              </div>
                            );
                          }
                        )
                      ) : (
                        <div className="flex justify-center mt-3">
                          <p className="text-gray-400 text-semibold sm:text-md text-sm mt-3">{`${solution.name} has not provided any information about integrations yet.`}</p>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>

          {/* Pricing Section */}
          {isPricingExists() && (
            <div
              className="flex items-center justify-between border-[1px] bg-light-gray py-3 px-4  "
              onClick={() => {
                setShowPricing(!showPricing);
              }}
            >
              <p>Pricing</p>
              <img
                src={ChevronDown}
                alt=""
                className={`${showPricing ? "rotate-180" : "rotate-0"}`}
              />
            </div>
          )}
          <div
            className={`grid ${
              solutionsToCompare.length === 4
                ? "sm:grid-cols-2"
                : `sm:grid-cols-${solutionsToCompare.length}`
            } lg:flex`}
          >
            {showPricing &&
              isPricingExists() &&
              solutionsToCompare.map((solution, index) => {
                return (
                  <div className="flex w-full flex-col   gap-4 border-[1px] py-6 px-4">
                    <div className="flex items-center gap-3">
                      <img
                        src={
                          solutionLogos[index] ||
                          solution.logo_url ||
                          solutionLogoPlaceholder
                        }
                        alt="solution-logo"
                        className={`h-[48px] w-[48px] rounded-xl lg:hidden ${
                          solutionsToCompare?.length === 4 ? "" : "sm:hidden"
                        }`}
                      />
                      <p
                        className={`break-all text-sm font-bold uppercase lg:hidden ${
                          solutionsToCompare?.length === 4 ? "" : "sm:hidden"
                        }`}
                      >
                        {solution.name}
                      </p>
                    </div>
                    {compareSolutionProps[index]?.pricing?.starting_cost ? (
                      <>
                        <div className="flex gap-1">
                          <h4 className="font-bold">
                            Starting From $
                            {compareSolutionProps[index]?.pricing
                              ?.starting_cost %
                              1 !== 0
                              ? compareSolutionProps[
                                  index
                                ]?.pricing?.starting_cost.toString()
                              : Number(
                                  compareSolutionProps[index]?.pricing
                                    ?.starting_cost
                                ).toFixed(0)}
                            <span className="font-normal">
                              {compareSolutionProps[index]?.pricing
                                ?.costing_frequency === "monthly"
                                ? "/month"
                                : "/year"}
                            </span>
                          </h4>
                        </div>
                        {compareSolutionProps[index]?.pricing
                          ?.pricing_details && (
                          <p className="text-sm">
                            {
                              compareSolutionProps[index]?.pricing
                                ?.pricing_details
                            }
                          </p>
                        )}
                      </>
                    ) : (
                      <div className="flex justify-center mt-3">
                        <p className="text-gray-400 text-semibold sm:text-md text-sm mt-3">{`${solution.name} has not provided any pricing information yet.`}</p>
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <AddSolutionsModal
        openModal={openAddSolutionModal}
        compareSolutionProps={compareSolutionProps}
        setCompareSolutionProps={setCompareSolutionProps}
        setSolutionsToCompare={setSolutionsToCompare}
        solutionsToCompare={solutionsToCompare}
        setOpenModal={setOpenAddSolutionModal}
        allSolutions={allSolutions}
        similarSolutions={compareSolutionProps.map((prop) => {
          return prop?.similar_solutions;
        })}
      />
      <LeadGenerationModal
        openModal={openLeadGenModal}
        setOpenModal={setOpenLeadGenModal}
        solutionId={currentSolution.id}
        sourceBtn={"contact_us"}
        formType={"leadSubmission"}
        solutionName={currentSolution.name}
        currentUser={currentUser}
      />
    </Layout>
  );
}
