import Marquee from 'react-fast-marquee'

import TickerCard from '@/components/molecules/Marketplace/TickerCard'
import FeaturedSolutionTickerCard from '@/components/molecules/solution/FeaturedSolutionTickerCard'

type SolutionTickerProps = {
  solutions: schema.Solution[],
  categoryPageCarousel?: boolean
}

const SolutionTicker = ({ solutions, categoryPageCarousel = false }: SolutionTickerProps) => {
  return (
    <div className=''>
      <Marquee pauseOnHover={true} autoFill={true}>
        {solutions.map((solution, index) => (
          categoryPageCarousel ? 
          <FeaturedSolutionTickerCard key={index} solution={solution}/> : 
          <TickerCard key={index} solution={solution} /> 
        ))}
      </Marquee>
    </div>
  )
}

export default SolutionTicker
