type SolutionShowProps = {
  heading: string
  paragraph: string
}
export default function EmptySection({
  heading,
  paragraph,
}: SolutionShowProps) {
  return (
    <div className='box-content flex h-full max-h-14 flex-col items-center justify-center rounded-lg border border-gray-300 bg-light-gray p-[32px] md:p-[32px] lg:p-[32px] xl:p-[32px] max-md-sm:py-[32px] max-md-sm:pr-[12px] max-md-sm:pl-[24px]'>
      <div className='md:flex md:flex-col md:items-center md:justify-center md:text-center'>
        <div className='font-manrope text-lg font-bold leading-normal text-gray-900'>
          {heading}
        </div>
        <div className='text-custom-dark font-manrope text-base font-medium leading-relaxed'>
          {paragraph}
        </div>
      </div>
    </div>
  )
}
