import Button from '@/components/atoms/buttons/Button'
import { MLP_PROMO_LINK } from '@/utils/constants'

const UpgradeSolutionCtaSection = ({ header, subText }) => {
  return (
    <>
      <div>
        <h1 className='mt-10 text-lg font-bold sm:text-xl'>
          {header}
        </h1>
      </div>
      <p className='mb-5 mt-2 text-md text-[#2E343A]'>{subText}</p>
      <Button
        variant='teal'
        onClick={() => window.location.href = MLP_PROMO_LINK}
        className='min-w-[165px] py-[14px] font-semibold'
>
        Upgrade Account
      </Button>
    </>
  )
}

export default UpgradeSolutionCtaSection
