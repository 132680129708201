import React, { Dispatch, SetStateAction } from "react";

import { CollapsedCategories } from "./CollapsedCategories";
import { ExpandedCategories } from "./ExpandedCategories";

type SolutionCategoriesProps = {
  solution: schema.Solution;
  isExpanded?: boolean;
  isMinify: boolean;
  categoriesToShow: number;
  solutionTags: Array<string>;
  categorySlugs: Array<string>;
  setIsExpanded: Dispatch<SetStateAction<boolean>>;
};

const SolutionCategories = ({
  isExpanded,
  solution,
  isMinify,
  categoriesToShow,
  solutionTags,
  categorySlugs,
  setIsExpanded,
}: SolutionCategoriesProps) => {
  return (
    <>
      {!isExpanded ? (
        <CollapsedCategories
          wrapperClasses='flex flex-wrap'
          solution={solution}
          categories={solutionTags?.slice(0, categoriesToShow)}
          categorySlugs={categorySlugs?.slice(0, categoriesToShow)}
          minify={isMinify}
          showCategories={categoriesToShow}
          setIsExpanded={setIsExpanded}
        />
      ) : (
        <ExpandedCategories
          wrapperClasses='flex flex-wrap bg-white w-[400px] rounded-xl h-[150px] overflow-scroll'
          solution={solution}
          categories={solutionTags}
          categorySlugs={categorySlugs}
          minify={isMinify}
          setIsExpanded={setIsExpanded}
          isExpanded={isExpanded}
        />
      )}
    </>
  );
};

export default SolutionCategories;
