import React, { useEffect } from 'react'

type FormProps = {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  children: React.ReactNode;
};

export const useToken = () => {
  const [token, setToken] = React.useState<string | null>(null)

  useEffect(() => {
    const token = document
      .querySelector('meta[name="csrf-token"]')
      ?.getAttribute('content')
    setToken(token)
  }, [])

  return token
}

export default function Form({ onSubmit, children, ...props }: FormProps) {
  const token = useToken()

  return (
    <form onSubmit={onSubmit} className='flex flex-col flex-1'>
      <input type='hidden' name='authenticity_token' value={token} />
      {children}
    </form>
  )
}
