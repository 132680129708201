import axios from 'axios';

const subscribeToNewsLetter = async (email, token) => {
  try {
    const subscriberData = {
      subscriber: {
        email: email,
      },
    };

    const response = await axios.post(
      '/newsletter_subscriptions',
      subscriberData,
      {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': token,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export default subscribeToNewsLetter;
