import React from 'react';
import { Link } from '@inertiajs/react';
import { SolutionCard, CompactSolutionCard } from '@/components/molecules/solution/SolutionCard';
import useWindowSize from '@/components/atoms/hooks/useWindowSize';
import rightArrow from '../../../assets/images/right-arrow.svg';
import clsx from 'clsx';

const IconWrapper = ({ icon, className }) => {
  if (!icon) return null;

  return React.cloneElement(icon, { className });
};

export default function SolutionsByCategory({
  stageIconsMap,
  orderedStagesArray,
  stageRefs,
  selectedCategory,
  handleSolutionSearch,
  setSelectedCategory,
}) {
  const { display } = useWindowSize();

  const handleSelectedCategory = (stage: string, category: string) => {
    setSelectedCategory((prevState) => ({
      ...prevState,
      [stage]: category,
    }));
    handleSolutionSearch(category.id, stage);
  };

  return (
    <div className="scroll-mt-[270px] px-4 pt-8 md:p-10 xl:px-20 xl:py-[60px]">
      {orderedStagesArray.map((stage, index) => (
        <div ref={stageRefs[index]} key={stage.name}>
          <div className={clsx('flex flex-col items-center md:items-start')}>
            <div className="md:mb-8 md:flex md:w-full md:items-center md:justify-between md:border-b md:border-medium-gray md:pb-3">
              <div className="flex flex-col items-center md:flex-row md:gap-3">
                <IconWrapper
                  icon={stageIconsMap[stage.name]}
                  className="mb-2 h-8 w-8 fill-current text-lighter-medium-gray md:mb-0"
                />
                <p className="mb-4 text-2xl font-bold leading-[30px] text-black md:mb-0">
                  {stage.name}
                </p>
              </div>
            </div>

            <div className="w-full md:mb-20 md:flex md:gap-10">
              <div
                className="max-md:scrollbar-hide -mx-4 mb-6 flex w-[calc(100%+32px)] gap-2 overflow-x-auto pl-4 sm:max-h-[754px] md:mx-0 md:mb-0 md:w-[282px] md:flex-col md:pl-0 md:pr-[22px] md:scrollbar md:scrollbar-track-light-gray md:scrollbar-thumb-medium-gray
													md:scrollbar-thumb-rounded-[18px] md:scrollbar-w-[6px]"
              >
                {stage.categories.map((category) => (
                  <p
                    key={category.name}
                    className={`whitespace-nowrap rounded-full md:whitespace-pre-wrap md:rounded-xl ${
                      selectedCategory[stage?.name]?.name === category?.name
                        ? 'md:text-black-blue border border-blue-tint-2 bg-blue-tint-2 md:border-0'
                        : 'border border-blue-tint-2 bg-white hover:bg-light-gray md:border-0'
                    } text-black-blue cursor-pointer px-[10px] py-[6px] text-xs font-semibold md:py-3 md:px-5 md:text-md md:font-[500] md:text-black`}
                    onClick={() => handleSelectedCategory(stage.name, category)}
                  >
                    {category.name}
                  </p>
                ))}
              </div>

              <div className="flex flex-1 flex-col">
                <Link
                  href={`/categories/${selectedCategory[stage?.name]?.id}`}
                  className="mx-[6px] mb-[14px] hidden snap-start md:block"
                >
                  <p className="hidden cursor-pointer items-center gap-[6px] rounded-xl bg-light-gray py-[10px] px-[16px] text-sm leading-[21px] hover:bg-light-gray-hover md:flex md:justify-center">
                    <span className="text-center">
                      See all {selectedCategory[stage?.name]?.name} Solutions
                    </span>
                    <img
                      src={rightArrow}
                      className="h-5 w-5"
                      alt="right arrow"
                    />
                  </p>
                </Link>
                <div
                  className={clsx(
                    'row-auto mb-[17.5px] grid w-full grid-cols-2 gap-4 sm:grid-rows-5 md:mb-0 md:grid-cols-1 xl:grid-cols-2 xl:grid-rows-3'
                  )}
                >
                  {selectedCategory[stage.name]?.solutions
                    ?.slice(0, display === 'tablet' ? 5 : undefined)
                    .map((solution) =>
                      display === 'mobile' ? (
                        <CompactSolutionCard
                          key={solution.id}
                          solution={solution}
                          hideEditButton={true}
                          className="h-full w-full"
                          entryPoint="Marketplace Page"
                        />
                      ) : (
                        <SolutionCard
                          solution={solution}
                          key={solution.id}
                          hideEditButton={true}
                          className="h-full"
                          hideTags={true}
                          entryPoint="Marketplace Page"
                        />
                      )
                    )}
                </div>
              </div>
            </div>
            <Link
              href={`/categories/${selectedCategory[stage?.name]?.id}`}
              className="w-full text-center"
            >
              <p className="mb-[61px] flex cursor-pointer items-center justify-center gap-[6px] rounded-xl bg-light-gray py-[10px] px-[16px] text-sm leading-[21px] hover:bg-light-gray-hover md:hidden">
                See all {selectedCategory[stage?.name]?.name} Solutions
                <img src={rightArrow} className="h-6 w-6" alt="right arrow" />
              </p>
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
}
