import React, { useState } from 'react';
import Input from '@/components/atoms/inputs/Input';
import Form, { useToken } from '@/components/atoms/forms/Form';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useForm, router } from '@inertiajs/react';
import Button from '@/components/atoms/buttons/Button';

type PromotionFormSectionProps = {
  onDelete: (index: number) => void; // Function that takes an index and returns void
  index: number;
  promotions: schema.Promotion[];
  formSections: any[];
};

export default function PromotionFormSection({
  onDelete,
  index,
  ...props
}: PromotionFormSectionProps) {
  const { solution, promotions } = props;
  const flattenPromotions = promotions.flatMap((subArray) => subArray);
  const [formCount, setFormCount] = useState(flattenPromotions.length + 1 || 2);

  const { data, setData } = useForm({
    // Your initial form data here
    title: '',
    details: '',
    cta_url: '',
    id: '',
  });

  const [fields, setFields] = useState<
    {
      title: string;
      details: string;
      cta_url: string;
    }[]
  >(
    flattenPromotions.map((promotion) => ({
      title: promotion.title || '',
      details: promotion.details || '',
      cta_url: promotion.cta_url || '',
      id: promotion.id || '',
    }))
  );

  const handleInputChange = (
    index: number,
    fieldName: string,
    value: string
  ) => {
    const values = [...fields];
    values[index] = { ...values[index], [fieldName]: value };
    setFields(values);
  };

  const addField = () => {
    if (formCount <= 3)
      setFields([...fields, { title: '', details: '', cta_url: '' }]);
    setFormCount(formCount + 1);
  };

  const removeField = async (index: number, field: any) => {
    if (formCount > 1) {
      const values = [...fields];
      values.splice(index, 1);
      setFields(values);
      setFormCount(formCount - 1);
    }

    const matchingPromotions = flattenPromotions.filter((promotion) => {
      return (
        promotion.title === field.title &&
        promotion.details === field.details &&
        promotion.cta_url === field.cta_url
      );
    });

    if (matchingPromotions.length > 0) {
      try {
        const response = await axios.delete(
          `/solutions/${solution.id}/promotions/${matchingPromotions[0].id}`,
          {
            headers: {
              'X-CSRF-Token': token,
              Accept: 'application/json',
            },
          }
        );

        if (response.status === 204) {
          // Toast message for success
          toast(
            <div>
              <span className="font-bold">Success! </span>Promotion deleted
              successfully
            </div>,
            {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              className:
                'bg-teal-tint-2 text-md text-black p-4 md:p-6 md:rounded-xl md:w-[400px] md:right-[80px]',
              bodyClassName: 'p-0 min-w-[324px]',
            }
          );
          // Perform any additional actions if required after successful deletion
        }
      } catch (error) {
        // Toast message for error
        toast(
          <div>
            <span className="font-bold">Error! </span>
            {error.message} {/* Display the error message */}
          </div>,
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            className:
              'bg-teal-tint-2 text-md text-black p-4 md:p-6 md:rounded-xl md:w-[400px] md:right-[80px]',
            bodyClassName: 'p-0 min-w-[324px]',
          }
        );
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    const filteredFields = fields.filter((field) => {
      return !flattenPromotions.some(
        (promotion) =>
          promotion.title === field.title &&
          promotion.details === field.details &&
          promotion.cta_url === field.cta_url
      );
    });

    const promotions = {
      solution: {
        promotions_attributes: filteredFields,
        // Other solution attributes if any
      },
    };

    const titles = fields.map((field) => field.title);
    const uniqueTitles = [...new Set(titles)];

    if (titles.length !== uniqueTitles.length) {
      // Display toast message for non-unique titles
      toast.error(
        <div>
          <span className="font-bold">Error! </span>Title should be unique
        </div>,
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          className:
            'bg-teal-tint-2 text-md text-black p-4 md:p-6 md:rounded-xl md:w-[400px] md:right-[80px]',
          bodyClassName: 'p-0 min-w-[324px]',
        }
      );
      return; // Stop execution if titles are not unique
    }

    try {
      const response = await axios.put(
        `/solutions/${solution.id}`,
        promotions, // Use the promotions object here in the request body
        {
          headers: {
            'X-CSRF-Token': token,
            Accept: 'application/json',
          },
        }
      );

      if (response.status === 200) {
        setData(response.data);

        // Toast message for success
        toast(
          <div>
            <span className="font-bold">Success! </span>Your changes have been
            saved
          </div>,
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            className:
              'bg-teal-tint-2 text-md text-black p-4 md:p-6 md:rounded-xl md:w-[400px] md:right-[80px]',
            bodyClassName: 'p-0 min-w-[324px]',
          }
        );
        // Redirect after 2 seconds
        setTimeout(() => {
          router.visit(`/solutions/${solution.slug}`);
        }, 2000);
      }
    } catch (error) {
      // Toast message for error
      toast(
        <div>
          <span className="font-bold">Error! </span>
          {error.message} {/* Display the error message */}
        </div>,
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          className:
            'bg-teal-tint-2 text-md text-black p-4 md:p-6 md:rounded-xl md:w-[400px] md:right-[80px]',
          bodyClassName: 'p-0 min-w-[324px]',
        }
      );
    }
  };

  const token = useToken();
  const handleBack = () => {
    // Wait for 1 seconds (1000 milliseconds) before redirecting
    setTimeout(() => {
      router.visit(`/solutions/${solution.slug}`); // Redirect to solution page after waiting
    }, 1000);
  };

  return (
    <>
      <div className="mb-8 flex items-baseline">
        <div className="ml-auto flex gap-2">
          <Button
            type="submit"
            size="md"
            variant={
              fields.some((field) => field.title === '') ? 'disable' : 'teal'
            }
            className="flex h-12 w-20 items-center justify-center rounded-[12px] px-4 py-3"
            onClick={handleSubmit}
          >
            Save
          </Button>
          <Button
            onClick={() => handleBack()}
            type="button"
            size="md"
            variant="gray"
            className="flex h-12 w-20 items-center justify-center rounded-[12px] px-4 py-3"
          >
            Cancel
          </Button>
        </div>
      </div>
      <div className="flex gap-4 max-md-sm:block max-md-sm:flex-col">
        <form className="flex flex-1 max-md-sm:flex-col">
          {fields.map((field, index) => (
            <div className="w-full px-4">
              <div className="w-auto max-md:w-[100%]" key={index}>
                <Input
                  type="text"
                  label="Promotion Title"
                  labelHtmlFor="solution_name"
                  id="solution_name"
                  name="solution_name"
                  value={field.title}
                  onChange={(e) => {
                    if (e.target.value.length <= 50) {
                      handleInputChange(index, 'title', e.target.value);
                    }
                  }}
                  className="mb-[12px]"
                  // errors={errors.title}
                />
                {field.title === '' && (
                  <div className="font-manrope float-right text-xs font-medium text-danger-red">
                    Title is a Required Field.
                  </div>
                )}

                {field.title.length > 0 && (
                  <div className="flex">
                    <div
                      className={`font-manrope text-xs font-medium ${
                        field.title.length > 49
                          ? 'text-danger-red'
                          : 'text-gray-600'
                      }`}
                    >
                      Max 50 characters
                    </div>
                    <div
                      className={`font-manrope ml-auto text-xs font-medium ${
                        field.title.length > 49
                          ? 'text-danger-red'
                          : 'text-gray-600'
                      }`}
                    >
                      {field.title.length}
                    </div>
                  </div>
                )}
              </div>

              <div className="w-auto gap-5 max-md:w-[100%]">
                <div className="mb-[12px]">
                  <p>Promotion Details</p>
                </div>

                <div className="mb-[12px]">
                  <textarea
                    className="h-[383px] w-full rounded-xl border border-dark-gray px-5 py-4 text-black focus:border-dark-gray focus:outline-none focus:ring-0 md:h-[221px] lg:h-[194px] max-md-sm:h-[130px]"
                    htmlFor="Promotion Details"
                    value={field.details}
                    onChange={(e) => {
                      if (e.target.value.length <= 150) {
                        handleInputChange(index, 'details', e.target.value);
                      }
                    }}
                  />
                </div>

                {field.details.length >= 0 && (
                  <div className="flex">
                    <div
                      className={`font-manrope text-xs font-medium ${
                        field.details.length > 149
                          ? 'text-danger-red'
                          : 'text-gray-600'
                      }`}
                    >
                      Max 150 characters
                    </div>
                    <div
                      className={`font-manrope ml-auto text-xs font-medium ${
                        field.details.length > 149
                          ? 'text-danger-red'
                          : 'text-gray-600'
                      }`}
                    >
                      {field.details.length}
                    </div>
                  </div>
                )}
              </div>

              <div className="w-auto max-md:w-[100%]" key={index}>
                <Input
                  type="text"
                  label="Promotion Url"
                  labelHtmlFor="solution_name"
                  id="solution_name"
                  name="solution_name"
                  value={field.cta_url}
                  onChange={(e) =>
                    handleInputChange(index, 'cta_url', e.target.value)
                  }
                  className="mb-[12px]"
                  // errors={errors.name}
                />
              </div>

              <div className="hidden w-auto max-md:w-[100%]" key={index}>
                <Input
                  type="text"
                  label="promotion ID"
                  labelHtmlFor="Promotion ID"
                  id="solution_name"
                  name="solution_name"
                  value={field.id}
                  onChange={(e) =>
                    handleInputChange(index, 'id', e.target.value)
                  }
                  className="mb-[12px]"
                  // errors={errors.name}
                />
              </div>
              <Button
                size="md"
                variant="dangerLight"
                className="flex h-[44px] max-w-max items-center justify-center py-[14px] px-[16px]"
                onClick={async (e) => {
                  await removeField(index, field);
                }}
              >
                <p className="font-manrope text-base font-semibold text-red-600">
                  Delete Item
                </p>
              </Button>
            </div>
          ))}
        </form>
        {formCount <= 3 && (
          <div
            className="flex-colpr-2 flex h-[204px] w-full max-w-[363px] flex-1 cursor-pointer flex-col items-center justify-center gap-[10px] rounded-lg border-2 border-dashed border-gray-400 p-[10px] max-md:w-[100%] max-md-sm:mt-[20px]"
            onClick={addField}
          >
            {/* Your SVG */}
            <svg
              width="45"
              height="45"
              viewBox="0 0 45 45"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.666016"
                y="0.5"
                width="44"
                height="44"
                rx="22"
                fill="#ECEDF1"
              />
              <path
                d="M22.666 15V30M30.166 22.5L15.166 22.5"
                stroke="#0F172A"
                stroke-width="1.5"
                stroke-linecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div>Add New</div>
          </div>
        )}
      </div>
    </>
  );
}
