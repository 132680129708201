import { lazy } from 'react';
const EmptySection = lazy(() => import('../../atoms/widgets/EmptySection'));

type OurCustomersProps = {
  solution?: schema.Solution;
  customers: schema.Customer[];
  handleSubmit?: (e: React.FormEvent) => Promise<void>;
  solutionOwner?: schema.User;
  current_user?: schema.User;
  isRmcAdmin: boolean;
};

const OurCustomers = ({
  customers,
  solutionOwner,
  current_user,
  isRmcAdmin
}: OurCustomersProps) => {
  const shouldShowEmptyCustomersSection =  
    current_user &&
    (current_user?.id === solutionOwner?.id || isRmcAdmin) &&
    customers?.every((obj) => obj.id === null)
  return (
    <div>
      {shouldShowEmptyCustomersSection ? (
        <div>
          <div className='sm:mt-3 mt-4'>
            <EmptySection
              heading='No Customers Yet'
              paragraph='Click the pencil icon to add a customer to your profile.'
            />
          </div>
        </div>
      ) : (
        <div className='grid lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 justify-items-center'>
          {customers.map(
            (customer, index) =>
            customer.id && (
              <div className='flex flex-col items-center justify-center'>
                <img
                  src={customer.logo_url}
                  alt='download-thumbnail'
                  className='rounded-full lg:w-[7.5rem] lg:h-[7.5rem] w-[6.25rem] h-[6.25rem] sm:mt-[3rem] mt-[2rem] object-cover'
                  loading='lazy'
                />
                <p className='sm:mt-4 mt-2'>{customer.title}</p>
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default OurCustomers;