import React from 'react'
import { Helmet } from 'react-helmet'

type PageTitleProps = {
  title: string
}

const PageTitle = ( { title } : PageTitleProps ) => {
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  )
}

export default PageTitle