import React, { useState } from 'react';
import Modal from '../../atoms/modals/Modal';
import Button from '../../atoms/buttons/Button';
import axios from 'axios';
import { useToken } from '@/components/atoms/forms/Form';

export type EditContactModalProps = {
  openContactModal: boolean;
  setOpenContactModal: React.Dispatch<React.SetStateAction<boolean>>;
  solution: schema.Solution;
  setHasContactChanged?: React.Dispatch<React.SetStateAction<boolean>>;
};

const EditContactModal = ({
  openContactModal,
  setOpenContactModal,
  solution,
  setHasContactChanged,
}: EditContactModalProps) => {
  const [contact_email, setContactEmail] = useState(solution.contact_us_email);
  const token = useToken();
  const handleSaveContact = async () => {
    try {
      solution.contact_us_email = contact_email;
      const response = await axios.put(
        `/solutions/${solution.id}`,
        {
          solution,
        },
        {
          headers: {
            'X-CSRF-Token': token,
            Accept: 'application/json',
          },
        }
      );
      setOpenContactModal(false);
      setHasContactChanged(true);
    } catch (error) {}
  };
  return (
    <Modal
      isOpen={openContactModal}
      onClose={() => setOpenContactModal(false)}
      closeStyle="top-10 right-10"
      className="mx-4 w-full rounded-xl bg-white p-10 md:mt-0 md:w-[600px]"
    >
      <div className="flex flex-col">
        <h1 className="mb-8 justify-start  text-2xl font-bold ">Edit Link</h1>
        <p className="mb-2  text-lg">Contact Email</p>
        <input
          type="email"
          value={contact_email}
          onChange={(e) => setContactEmail(e.target.value)}
          className="mb-8 rounded-xl px-4 py-5 text-lg"
        />
        <div className="flex flex-row gap-2">
          <Button
            onClick={() => setOpenContactModal(false)}
            variant="demo"
            size="lg"
            className="w-full"
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleSaveContact()}
            variant="primary"
            size="lg"
            className="w-full"
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default EditContactModal;
