import toolTipArrow from '../../../../assets/images/tooltip-arrow-1.svg'

export function Tooltip({
  children,
  customClassName = '',
}: {
  children: React.ReactNode;
  customClassName?: string;
}) {
  const defaultClasses = `absolute -left-7 top-[32px] z-10 flex w-[248px]  flex-col justify-between rounded-xl bg-dark p-6 text-sm text-white `
  const toolTipClasses = `${defaultClasses} ${customClassName}`
  return (
    <div className={toolTipClasses}>
      <img src={toolTipArrow} className='absolute top-[-10px]' alt='tooltip' />
      <div>{children}</div>
    </div>
  )
}
