import { useState, useEffect } from 'react'

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
    display: '',
  })

  useEffect(() => {
    if (typeof window === 'undefined') return

    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        display:
          window.innerWidth < 640
            ? 'mobile'
            : window.innerWidth < 1280
            ? 'tablet'
            : 'desktop',
      })
    }

    window.addEventListener('resize', handleResize)

    handleResize() // Update the state when the component mounts

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}

export default useWindowSize
