import { Link } from 'react-scroll'
import convertToSlug from '@/utils/convertToSlug'

type StickyNavbarProps = {
  solution: schema.Solution
  documents: schema.Document[]
  screenshots: schema.Screenshot[]
  videos: schema.Video[]
  isSolutionOwner: boolean
  isRmcAdmin: boolean
  integrations: String[]
  customers: schema.Customer[]
  propositionsLength: number
  featuresLength: number
  promotionsLength: number
  navbarHeight: number
  isClaimed: boolean
}

const StickyNavbar = ({ solution, documents, screenshots, videos, isRmcAdmin, isSolutionOwner, integrations, customers, propositionsLength, featuresLength, promotionsLength, navbarHeight, isClaimed }: StickyNavbarProps ) => {
  
  const generateNavlinks = (solution) => {
    const navlinks = []

    if (isSolutionOwner || isRmcAdmin || solution.description)
    {
      navlinks.push('About')
    }

    if((promotionsLength > 0 || isSolutionOwner || isRmcAdmin)  && !solution.hide_promotion_section)
    {
      navlinks.push('Promotion')
    }

    const showOfficialMedia = (isSolutionOwner || isRmcAdmin || isClaimed &&
      (!documents?.every(obj => obj.id === null) || !screenshots?.every(obj => obj.id === null) || !videos?.every(obj => obj.id === null)))

    if (!solution.hide_pricing_section) {
      navlinks.push('Pricing')
    }

    if (solution.paid_solution && (propositionsLength > 0 || isSolutionOwner || isRmcAdmin)) 
    { navlinks.push('Product Information') }
    

    (isSolutionOwner || (integrations?.length > 0) || isRmcAdmin) && navlinks.push('Integrations')


    if (isSolutionOwner || isRmcAdmin || featuresLength > 0)
    {
      navlinks.push('Features')
    }

    if (!customers?.every((obj) => obj.id === null) || isSolutionOwner || isRmcAdmin) {
      navlinks.push('Our Customers')
    }

    if ((solution.paid_solution && solution.testimonials?.length != 0) || isSolutionOwner || isRmcAdmin) {
      navlinks.push('Testimonials')
    }

    if(showOfficialMedia) {
      navlinks.push('Official Media')
    }
    return navlinks
  }

  return (
    <div className='flex flex-col bg-white overflow-scroll scrollbar-hide'>
      <div className='flex flex-row gap-6 md:text-[14px] text-[12px] px-2 whitespace-nowrap'>
        {
          generateNavlinks(solution).map((name, index) => (
            <Link
              key={index}
              activeClass='active'
              to={convertToSlug(name)}
              spy={true}
              smooth={true}
              offset={-navbarHeight}
              duration={500}
              className={'pb-1 cursor-pointer'}
            >
              {name}
            </Link>
          ))
        }
      </div>
      <div className='h-[4px] w-full bg-[#D9D9D9]' />
    </div>
  )
}

export default StickyNavbar
