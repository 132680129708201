import { ReactNode } from 'react'

const MAX_WIDTH = '1254px'

interface WrapperProps {
  children: ReactNode;
  classes?: string;
}

const HorizontalMarginsWrapper: React.FC<WrapperProps> = ({ children, classes = '' }) => (
  <div>
    <div className='2xl:px-[7.8rem] xl:px-10 md:px-2 min-w-[329px] mx-auto sm:w-auto sm:mx-0'>
      <div className={`sm:px-8 md:px-[4rem] xl:px-[9rem] lg:px-40 max-w-[${MAX_WIDTH}] xl:mx-auto ${classes}`}>
        {children}
      </div>
    </div>
  </div>
)

export default HorizontalMarginsWrapper
