import React, { useState, useEffect, useRef } from 'react';
import Input from '@components/atoms/inputs/Input';
import Layout from '@components/organisms/layouts/Layout';
import clsx from 'clsx';
import Modal from '@components/atoms/modals/Modal';
import { SolutionCard } from '@/components/molecules/solution/SolutionCard';
import marketplaceHero from '../../../assets/images/hero-marketplace.jpg';
import { ReactComponent as FilterIcon } from '../../../assets/images/filter-icon.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/close-icon-black.svg';
import LargeSearch from '../../../assets/images/lg-search.svg';
import { mixpanel } from '@/utils/mixpanel';
import {
  sortOptions,
  sortOptionsMap,
  sortOptionsReverseMap,
} from '@components/atoms/constants/solutionOptions';
import Meta from '@/components/atoms/widgets/Meta';
import Button from '@/components/atoms/buttons/Button';
import { SolutionCTA } from '@/components/molecules/solution/SolutionCTA';
import { SolutionsSection } from '@/components/organisms/solution/SolutionsSection';
import FilterSidebar, { FilterSubhead } from '@/components/atoms/filters/FilterSidebar';
import { SortMenu } from '@/components/molecules/filterAndSort/SortMenu';
import { ActiveFilters } from '@/components/molecules/filterAndSort/ActiveFilters';
import SearchedSolutionsAndCategories from './SearchedSolutionsAndCategories';
import Chevron from 'app/assets/images/chevron-left.svg';
import ReactPaginate from 'react-paginate';
import HandleSearch from '@/utils/HandleSearch';
import CompareSolutionTab from '@/components/molecules/compareSolution/CompareSolutionTab';
import useLocalStorage from '@/components/atoms/hooks/useLocalStorage';
import axios from 'axios';

export type GlobalSearchProps = {
  allSolutions: schema.Solution[];
  allCategories: schema.Category[];
  pagination: schema.Pagination;
};

const GlobalSearch = ({
  allSolutions,
  allCategories,
  pagination,
}: GlobalSearchProps) => {
  const [filter, setFilter] = useLocalStorage('solutions-filters', {
    claimed: false,
    price: [],
    sort: 'is_featured',
    rating_gteq: null,
    category_ids: [],
  });
  const [solutionsToShow, setSolutionsToShow] = useState([]);
  const [featuredSolutions, setFeaturedSolutions] = useState([]);
  const [currentPagination, setcurrentPagination] = useState(pagination);
  const [searchTerm, setSearchTerm] = useState('');
  const [solutionsToCompare, setSolutionsToCompare] = useState([]);

  const initialSelectedCategories = Array.isArray(filter.category_ids)
    ? filter.category_ids
        ?.map((categoryId) =>
          allCategories.find((category) => category.id === Number(categoryId))
        )
        .filter(Boolean)
    : [];
  const [selectedCategories, setSelectedCategories] = useState(
    initialSelectedCategories
  );

  const [filterCount, setFilterCount] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  const [solutionSearchResults, setSolutionSearchResults] = useState([]);
  const [categorySearchResults, setCategorySearchResults] = useState([]);
  const [searchResultsOpen, setSearchResultsOpen] = useState(false);
  const [resultsAvailable, setResultsAvailable] = useState(false);

  const [page, setPage] = useState(currentPagination?.current_page || 1);
  const previousPage = useRef(1);

  const solutionsContainer = useRef(null);

  const buildSearchData = () => {
    const data = {};
    const q = {};

    filter.category_ids.length > 0 &&
      Object.assign(data, { category_ids: filter.category_ids });

    filter?.claimed &&
      Object.assign(q, {
        claims_status_matches_any: filter?.claimed ? 'approved' : '',
      });

    filter?.price && Object.assign(q, { price_rating_in: filter?.price || [] });
    Object.assign(data, { q: q });

    if (filter?.sort) {
      const orderBy =
        (Array.isArray(filter.sort) ? filter.sort[0] : filter.sort) ||
        'name asc';
      Object.assign(data, { order_by: orderBy });
    }

    if (filter?.rating_gteq) {
      if (!data.q) {
        data.q = {};
      }
      data.q.rating_gteq = filter?.rating_gteq;
    }

    return data;
  };

  const handleReload = async ({ preserveScroll = true }) => {
    const data = buildSearchData();

    page !== 1 && Object.assign(data, { page: page });

    const response = await axios.get('/global_search', {
      params: data,
    });
    setSolutionsToShow(response.data.solutions);
    setFeaturedSolutions(response.data.featuredSolutions);
    setcurrentPagination(response.data.pagination);
    setPage(response.data.pagination.current_page);

    if (!preserveScroll) {
      solutionsContainer?.current?.scrollIntoView();
    }
  };

  useEffect(() => {
    if (previousPage.current !== page) {
      handleReload({ preserveScroll: false });
    } else {
      page === 1 ? handleReload({}) : setPage(1);
    }

    previousPage.current = page;

    setFilterCount(
      (filter.claimed ? 1 : 0) +
        (filter.price?.length || 0) +
        (filter.rating_gteq ? 1 : 0) +
        (filter.category_ids.length > 0 ? 1 : 0)
    );
  }, [filter, page]);

  useEffect(() => {
    setResultsAvailable(
      solutionSearchResults.length > 0 || categorySearchResults.length > 0
    );
  }, [solutionSearchResults, categorySearchResults]);

  const handleSelectedCategory = (category) => {
    if (filter.category_ids.includes(category.id)) {
      const new_category_ids = filter.category_ids.filter(
        (category_id) => category_id !== category.id
      );
      setFilter({ ...filter, category_ids: new_category_ids });
      setSelectedCategories(
        selectedCategories.filter((item) => item.id !== category.id)
      );
    } else {
      const new_category_ids = [...filter.category_ids, category.id];
      setFilter({ ...filter, category_ids: new_category_ids });
      setSelectedCategories([...selectedCategories, category]);
    }
    setSearchTerm('');
  };

  const handleMixpanelSearchEvent = () => {
    mixpanel.track('Search', {
      'Search Term': searchTerm,
      // Stage: selectedStage?.name,
      Category: selectedCategories?.map((category) => category?.name),
      Claimed: filter?.claimed,
      Price: filter?.price,
      'Sort By': sortOptionsReverseMap[filter?.sort],
      'Entry Point': 'Global Search',
    });
  };

  function clearFilters() {
    setSelectedCategories([]);
    setSearchTerm('');
    setFilter({
      claimed: false,
      price: [],
      sort: 'featured',
      rating_gteq: null,
      category_ids: [],
    });
  }

  return (
    <Layout noPadding={true} type="menu">
      <Meta
        title="Talivity Marketplace - Connect with Top Recruitment
               Marketing SaaS and Service Providers"
        description="Discover top recruitment marketing SaaS and service providers on the Talivity Marketplace. Streamline your hiring process and find the best solutions for your business needs."
      />
      <div
        className="flex h-[240px] w-full flex-col items-center bg-cover bg-center px-4 pt-[50px]
                   text-center sm:h-[280px] sm:pt-[58px] xl:h-[360px] xl:pt-[98px]"
        style={{
          backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${marketplaceHero})`,
        }}
      >
        <p className="mb-5 w-[361px] text-xl font-bold leading-[28px] text-white md:w-[768px] md:text-6xl sm:w-[672px] sm:text-3xl sm:leading-[50px]">
          The #1 destination for finding recruitment marketing technology and services
        </p>
        <p className="w-[326px] text-xs leading-[20px] text-white sm:w-[600px] sm:text-lg sm:leading-[24px]">
          The Marketplace has over 1,500 talent solution providers, making it easy to find and research new hiring solutions
        </p>
      </div>
      <SolutionCTA />
      <SolutionsSection solutions={featuredSolutions} />
      {/* Solutions list container */}
      <div
        className="flex justify-center py-6 px-4 md:px-10 lg:px-20"
        ref={solutionsContainer}
      >
        <div className="w-full max-w-[1120px]">
          <div className="mb-2 flex items-center justify-between pb-3 md:mb-8 md:border-b">
            <p className="flex items-center gap-3 self-center text-2xl font-bold md:text-2xl">
              <span>Explore Solutions</span>
              <span className="mt-1 text-md font-medium">
                (
                {solutionSearchResults.length || currentPagination?.total_count}
                )
              </span>
            </p>

            <div className="hidden items-center gap-4 md:relative md:flex">
              <ActiveFilters
                setFilter={setFilter}
                selectedCategories={selectedCategories}
                rating_gteq={filter?.rating_gteq}
                handleSelectedCategory={handleSelectedCategory}
              />
              <SortMenu
                options={Object.entries(sortOptionsMap).map(
                  ([label, value]) => ({
                    label,
                    value,
                  })
                )}
                onChange={(value) => {
                  setFilter({
                    ...filter,
                    sort: value,
                  });
                }}
                selected={filter.sort as string}
              />
            </div>
          </div>
          <div className="mb-6 ">
            <div className="relative flex gap-1 md:hidden">
              <Input
                type="text"
                placeholder="Search by name or category"
                search={true}
                onChange={(e) => {
                  HandleSearch({
                    event: e,
                    allSolutions: allSolutions,
                    allCategories: allCategories,
                    searchTerm: searchTerm,
                    setSearchTerm: setSearchTerm,
                    setSolutionSearchResults: setSolutionSearchResults,
                    setCategorySearchResults: setCategorySearchResults,
                  });
                  setSearchResultsOpen(
                    e.target.value.length > 1 ? true : false
                  );
                }}
                value={searchTerm}
                className="pr-10"
                containerClass="relative"
              >
                {searchResultsOpen && resultsAvailable && (
                  <SearchedSolutionsAndCategories
                    wrapperClassName={clsx(
                      'relative left-0 z-50 max-w-[262.5px]',
                      solutionSearchResults.length +
                        categorySearchResults.length >
                        5 && 'max-h-[250px]'
                    )}
                    searchedSolutions={solutionSearchResults}
                    searchedCategories={categorySearchResults}
                    searchTerm={searchTerm}
                    handleSelectedCategory={handleSelectedCategory}
                    onClose={() => setSearchResultsOpen(false)}
                    open={searchResultsOpen}
                  />
                )}
              </Input>
              <div className="flex gap-4 md:hidden">
                <div className="relative">
                  <button
                    className={clsx(
                      'flex h-[60px] w-[60px] items-center justify-center gap-2 rounded-xl border md:hidden',
                      filterCount || filter.sort
                        ? 'border-blue-tint-2 bg-blue-tint-2'
                        : 'border-medium-gray'
                    )}
                    onClick={() => {
                      setOpenModal(true);
                    }}
                  >
                    <FilterIcon />
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-4 mb-2 block md:hidden">
              <ActiveFilters
                setFilter={setFilter}
                selectedCategories={selectedCategories}
                handleSelectedCategory={handleSelectedCategory}
                rating_gteq={filter.rating_gteq}
              />
            </div>
          </div>
          <div className="flex gap-8">
            {/* make first column fixed 240px width in CSS grid */}
            <div className="hidden shrink-0 grow-0 basis-[240px] md:flex md:basis-[300px] md:flex-col">
              <div className="relative pb-6 ">
                <Input
                  type="text"
                  placeholder="Search by name or category"
                  search={true}
                  onChange={(e) => {
                    HandleSearch({
                      event: e,
                      allSolutions: allSolutions,
                      allCategories: allCategories,
                      searchTerm: searchTerm,
                      setSearchTerm: setSearchTerm,
                      setSolutionSearchResults: setSolutionSearchResults,
                      setCategorySearchResults: setCategorySearchResults,
                    });
                    setSearchResultsOpen(
                      e.target.value.length > 1 ? true : false
                    );
                  }}
                  value={searchTerm}
                  className="mb-[-7px] pr-10"
                />
                {searchResultsOpen && resultsAvailable && (
                  <SearchedSolutionsAndCategories
                    wrapperClassName={clsx(
                      'z-50 w-full',
                      solutionSearchResults.length +
                        categorySearchResults.length >
                        5 && 'max-h-[360px]'
                    )}
                    searchedSolutions={solutionSearchResults}
                    searchedCategories={categorySearchResults}
                    searchTerm={searchTerm}
                    handleSelectedCategory={handleSelectedCategory}
                    onClose={() => setSearchResultsOpen(false)}
                    open={searchResultsOpen && resultsAvailable}
                  />
                )}
                {searchTerm !== '' && (
                  <CloseIcon
                    className="absolute right-8 top-[19px] h-5 w-5 cursor-pointer sm:right-4"
                    onClick={() => setSearchTerm('')}
                  />
                )}
              </div>

              <FilterSidebar
                onClose={() => setOpenModal(false)}
                filter={filter}
                setFilter={setFilter}
                categories={allCategories}
                handleSelectedCategory={handleSelectedCategory}
                selectedCategories={selectedCategories}
              />
            </div>
            <div className="w-full shrink grow md:w-auto">
              {solutionsToShow?.length === 0 && (
                <div className="mt-[68px] flex flex-col items-center text-center">
                  <img
                    src={LargeSearch}
                    alt="search icon"
                    className="w-[64px]"
                  />
                  <p className="mb-4 text-3xl font-bold">No solutions found</p>
                  <p>Are you a vendor and not seeing your solution listed?</p>
                  <Button
                    type="button"
                    size="md"
                    variant="primary"
                    className="mt-4 w-[222px]"
                    onClick={() =>
                      window.open(
                        'https://docs.google.com/forms/d/e/1FAIpQLSc9Y6XiO4wCIAsq3lfqgpIsDRLCXSCPuxM21km4X5EPZ4DVDw/viewform?usp=sf_link',
                        '_blank'
                      )
                    }
                  >
                    Tell us about it here!
                  </Button>
                  <p className="mt-10 text-sm">
                    For other questions, contact{' '}
                    <a
                      className="nav-underline font-semibold"
                      href="mailto:info@talivity.com"
                    >
                      info@talivity.com
                    </a>
                    .
                  </p>
                </div>
              )}
              <div className="grid grid-cols-1 gap-10">
                {solutionsToShow?.length > 0 && (
                  <div
                    className="grid grid-cols-1 gap-6"
                    onClick={() => handleMixpanelSearchEvent()}
                  >
                    {solutionsToShow.map((solution) => (
                      <SolutionCard
                        solution={solution}
                        hideEditButton={true}
                        className={solutionsToCompare ? '' : 'h-full'}
                        key={solution.name}
                        entryPoint="Global Search Page"
                        solutionsToCompare={solutionsToCompare}
                        setSolutionsToCompare={setSolutionsToCompare}
                      />
                    ))}
                  </div>
                )}
                {solutionsToShow?.length > 0 && (
                  <div className="flex justify-center">
                    <ReactPaginate
                      pageCount={currentPagination?.total_pages}
                      pageRangeDisplayed={3}
                      marginPagesDisplayed={1}
                      previousLabel={
                        <img
                          src={Chevron}
                          alt="previous page arrow"
                          className=""
                        />
                      }
                      nextLabel={
                        <img
                          src={Chevron}
                          alt="next page arrow"
                          className="rotate-180"
                        />
                      }
                      breakLabel="..."
                      onPageChange={(event) => {
                        setPage(event.selected + 1);
                      }}
                      forcePage={page - 1}
                      containerClassName="flex justify-between items-center self-stretch"
                      pageClassName="flex py-3 px-5 justify-center items-center gap-2 bg-white rounded-xl"
                      activeClassName="!bg-dark-blue !text-white"
                      previousClassName="mr-6"
                      nextClassName="ml-6"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {solutionsToCompare?.length > 0 && (
        <div className="sticky bottom-0 z-[10] bg-[#ECEDF1] shadow-sticky-bar">
          <CompareSolutionTab
            solutionsToCompare={solutionsToCompare}
            setSolutionsToCompare={setSolutionsToCompare}
          />
        </div>
      )}
      <Modal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        closeStyle="top-8 right-4"
        backdropStyle="pt-[136px] sm:pt-0"
        className="!fixed bottom-0 w-screen"
      >
        <div className="max-h-screen overflow-y-auto rounded-t-3xl bg-white px-4 py-8">
          <h3 className="mb-7 text-2xl font-bold leading-tight">
            Filter and Sort
          </h3>
          <div>
            <FilterSubhead>Sort By:</FilterSubhead>
            <div className="mb-10 mt-4 flex flex-col gap-6">
              {sortOptions.map((sortOption) => (
                <div
                  className="sort-container flex gap-[10px]"
                  key={sortOption}
                >
                  <input
                    type="radio"
                    className={clsx(
                      'filter-radio relative h-[24px] w-[24px] cursor-pointer rounded-full border !border-medium-gray p-[4px] text-white  focus:ring-0 focus:ring-white',
                      filter.sort !== sortOptionsMap[sortOption] && ''
                    )}
                    onChange={(e) => {
                      setFilter({
                        ...filter,
                        sort: sortOptionsMap[sortOption],
                      });
                    }}
                    checked={filter.sort === sortOptionsMap[sortOption]}
                  />
                  <p className="ml-2 text-lg leading-[27px]">{sortOption}</p>
                </div>
              ))}
            </div>
          </div>
          <FilterSidebar
            onClose={() => setOpenModal(false)}
            filter={filter}
            setFilter={setFilter}
            categories={allCategories}
            handleSelectedCategory={handleSelectedCategory}
            selectedCategories={selectedCategories}
            isModal={openModal}
          />
          <div className="mt-4 flex flex-col gap-4" id="filterSortActions">
            <Button
              size="md"
              variant="primary"
              onClick={() => setOpenModal(false)}
            >
              Apply
            </Button>
            <Button
              size="md"
              variant="tertiary"
              onClick={() => {
                clearFilters();
                setOpenModal(false);
              }}
            >
              Clear Filters
            </Button>
          </div>
        </div>
      </Modal>
    </Layout>
  );
};

export default GlobalSearch;
