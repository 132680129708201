import axios from "axios"

export const putRequest = (url, formData, token) => {
  const response = axios.put(url , formData, {
    headers: {
      "X-CSRF-Token": token,
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  })
  return response
}

export const postRequestWithoutFormData = (url, Data, token) => {
  const response = axios.post(url, Data, {
    headers: {
      "X-CSRF-Token": token,
      Accept: "application/json"
    },
  })
  return response
}

export const putRequestWithoutFormData = (url, data, token) => {
  const response = axios.put(url , data, {
    headers: {
      "X-CSRF-Token": token,
      Accept: "application/json",
    },
  })
  return response
}

export const deleteRequest = (url, token) => {
  const response = axios.delete(url, {
    headers: {
      "X-CSRF-Token": token,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  return response;
};

export const getCanvasEmbedToken = async (solutionName) => {
  try {
    const response = await axios.get('/canvas_embeds/generate_embed_token', {
      params: {
        'scopes[Solutions]': solutionName,
      },
    })
    return response.data.token
  } catch (error) {
    console.error('Error fetching the embed token:', error)
    return null
  }
}

export const getRequest = async (url) => {
  try{
    return axios.get(url)
  }
  catch (error){
    console.error(error)
    return null
  }
}

export const getAllParentChildCategories = (token) => {
  const response = axios.get("/all_funnel_categories", {
    headers: {
      "X-CSRF-Token": token,
      Accept: "application/json"
    },
  });
  return response;
};

export const postRequest = (url, formData, token) => {
  const response = axios.post(url , formData, {
    headers: {
      "X-CSRF-Token": token,
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  })
  return response
}

export const createWeeklyNewsletterSubscription = (userId, token) => {
  const url = '/newsletter_subscriptions'
  const response = axios.post(url, { subscriber: { user_id: userId } }, {
    headers: {
      "X-CSRF-Token": token,
      Accept: "application/json",
    },
  })
  return response
}

export const createJobsNewsletterSubscription = (userId, token) => {
  const url = '/newsletter_subscriptions/job'
  const response = axios.post(url, { subscriber: { user_id: userId } }, {
    headers: {
      "X-CSRF-Token": token,
      Accept: "application/json",
    },
  })
  return response
}

export const checkForEmailExistence = (userEmail, token) => {
  const url = '/users/check_for_email_existence'
  const response = axios.post(url, { user: { email: userEmail } }, {
    headers: {
      "X-CSRF-Token": token,
      Accept: "application/json",
    },
  })
  return response
}

export const userRegistration = async (data, token) => {
  return await axios.post("/users",
  {
    user: {
      email: data.email,
      first_name: data.firstName,
      last_name: data.lastName,
      company: data.company,
      role_id: data.role,
      job_title: data.title,
      password: data.password,
      password_confirmation: data.passwordConfirmation
    }
  },
  {
    headers: {
      "X-CSRF-Token": token,
    },
  });
}

export const customerUserLogin = async (data, token) => {
  return await axios.post(
    "/customer/login",
    {
      user: {
        email: data.email,
        password: data.password,
      },
    },
    {
      headers: {
        "X-CSRF-Token": token,
      },
    }
  );
}