import { MultiSelect } from 'react-multi-select-component'

import './styles.css'

type FeaturesDropdownProps = {
features: string[]
  selectedFeatures: string[]
  setSelectedFeatures: React.Dispatch<React.SetStateAction<string[]>>
}

const FeaturesDropdown = ({ features, selectedFeatures, setSelectedFeatures }: FeaturesDropdownProps) => {

  const featureOptions = features.map((feature) => ({
    label: feature,
    value: feature,
  }))

  return (
    <div className='relative'>
      {selectedFeatures.length > 0 &&
        <span className='absolute top-[-3px] -right-1 flex size-3 rounded-full bg-ocean-blue z-10' />
      }
      <MultiSelect
        options={featureOptions}
        value={selectedFeatures}
        onChange={setSelectedFeatures}
        labelledBy="Features"
        valueRenderer={() => "Features"}
        overrideStrings={{
          selectSomeItems: "Features"
        }}
        className='text-[12px]'
        ClearSelectedIcon={null}
        hasSelectAll={false}
      />
    </div>
  )
}

export default FeaturesDropdown
