import { useEffect } from 'react';
import { getQueryParamValue } from './helperFunctions';
import window from 'global'

const usePageRedirection = () => {
  useEffect(() => {
    const pageId = getQueryParamValue('p');
    const isPreview = getQueryParamValue('preview') === 'true';

    if (pageId && isPreview) {
      const redirectUrl = `/index.php?p=${pageId}&preview=true`;
      window.location.replace(redirectUrl);
    }
  }, []);
};

export default usePageRedirection;